<template>
  <div
    tabindex="-1"
    :aria-hidden="!props.isVisible"
    class="z-[111] overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 flex justify-center items-center w-full md:inset-0 h-full max-h-full bg-black/40"
    :class="{
      'hidden pointer-events-none': !props.isVisible,
    }"
  >
    <div
      ref="modal"
      class="relative w-full max-h-full bg-imperium-bg-sub-base rounded-2xl"
      :style="{ 'max-width': hardWidth ? `${hardWidth}px` : '' }"
      :class="{
        'max-w-[500px]': props.size === SIZES.MEDIUM && !hardWidth,
        'max-w-[416px]': props.size === SIZES.SMALL && !hardWidth,
      }"
    >
      <div
        v-if="props.isTitleVisible"
        class="flex relative items-center justify-between rounded-t-2xl border-t border-r border-l border-imperium-border-base"
        :class="{
          'pb-3': props.size === SIZES.SMALL,
          'pb-4': props.size === SIZES.MEDIUM,
          'border-b border-b-imperium-border-weak': title,

          'pt-4 px-4': props.type === MODAL_TYPES.MODAL,
          'pt-6 px-6': props.type === MODAL_TYPES.POPUP,
        }"
      >
        <h3
          v-if="title"
          class="font-semibold text-imperium-fg-strong"
          :class="{
            'text-xl': props.size !== SIZES.SMALL,
            'text-lg': props.size == SIZES.SMALL,
          }"
        >
          <slot name="title" />
        </h3>

        <Button
          v-if="props.isClosable"
          type="button"
          :size="SIZES.XSMALL"
          :visual-type="BUTTON_TYPE.GHOST"
          class="top-3 right-3"
          position="absolute"
          data-testid="modal-close"
          @click="onClose"
        >
          <template #leftIcon>
            <CloseIcon class="w-6 h-6 text-gray-400" />
          </template>
        </Button>
      </div>

      <div
        :class="{
          'px-2 sm:px-4 pb-4': props.type === MODAL_TYPES.MODAL,
          'px-6 pb-6': props.type === MODAL_TYPES.POPUP,
          'pt-2 sm:pt-2 rounded-t-2xl': !isTitleVisible,
        }"
        class="bg-imperium-bg-sub-base border-b border-r border-l border-imperium-border-base rounded-b-2xl"
      >
        <slot />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import CloseIcon from '@/assets/icons/close.svg?component';
import Button from '@/components/Button.vue';

import { BUTTON_TYPE, MODAL_TYPES, SIZES } from '@/types';
import { computed, useSlots, onMounted, onBeforeUnmount, ref, watch } from 'vue';

import { onClickOutside } from '@vueuse/core';

const props = withDefaults(
  defineProps<{
    isVisible?: boolean;
    size?: SIZES;
    type?: MODAL_TYPES;
    isClickOutsideCloseModal?: boolean;
    isTitleVisible: boolean;
    isClosable: boolean;
    hardWidth: number;
  }>(),
  {
    isVisible: false,
    size: SIZES.MEDIUM,
    type: MODAL_TYPES.MODAL,
    isClickOutsideCloseModal: true,
    isTitleVisible: true,
    isClosable: true,
    hardWidth: null,
  },
);

const emits = defineEmits<{
  (event: 'close'): void;
}>();

const slots = useSlots();

const modal = ref<HTMLElement | null>(null);

watch([props.isClickOutsideCloseModal], (value) => {
  if (value) {
    onClickOutside(modal, () => onClose());
  } else {
    onClickOutside(modal, () => {});
  }
});

if (props.isClickOutsideCloseModal) {
  onClickOutside(modal, () => onClose());
}

const title = computed(() => !!slots.title);

const onClose = () => {
  emits('close');
};

const handleEsc = (event) => {
  if (event.key === 'Escape') {
    onClose();
  }
};

onMounted(() => {
  window.addEventListener('keydown', handleEsc);
});

onBeforeUnmount(() => {
  window.removeEventListener('keydown', handleEsc);
});
</script>
