import { extractData, http } from '@/http';
import type {
  UsersFetchRequest,
  UsersFetchResponse,
  UserInvitationRequest,
  UserEditPayload,
  UserFetchResponse,
  UserEditResponse,
  UserInvitationResponse,
  UserReset2FAResponse,
} from './types';

export const users = (params: UsersFetchRequest): Promise<UsersFetchResponse> =>
  http.get<UsersFetchResponse>('/users', { params, useAuth: true }).then(extractData);

export const userEdit = (id: number, patchBody: UserEditPayload): Promise<UserEditResponse> =>
  http.patch<UserEditResponse>(`/users/${id}`, patchBody, { useAuth: true }).then(extractData);

export const userFetch = (id: number): Promise<UserFetchResponse> =>
  http.get<UserFetchResponse>(`/users/${id}`, { useAuth: true }).then(extractData);

export const userInvitation = (postBody: UserInvitationRequest): Promise<UserInvitationResponse> =>
  http.post<UserInvitationResponse>('/auth/invite', postBody, { useAuth: true }).then(extractData);

export const userReset2FA = (id: number): Promise<UserReset2FAResponse> =>
  http.post<UserReset2FAResponse>(`/users/${id}/reset-2fa`, {}, { useAuth: true }).then(extractData);

export const userPostLanguage = (userId: number, payload) => {
  http.post(`/users/${userId}/languages`, payload, { useAuth: true }).then(extractData);
};
export const userDeleteLanguage = (userId: number, languageId: number) =>
  http.delete(`/users/${userId}/languages/${languageId}`, { useAuth: true }).then(extractData);
