<template>
  <div class="flex justify-start items-center gap-2 pt-4">
    <div class="self-start mt-1">
      <template v-if="emptyResult">
        <InfoCircleIcon class="text-imperium-fg-base w-5 h-5" />
      </template>
      <template v-if="!emptyResult">
        <CheckMarkIcon class="rounded-full text-white bg-imperium-ds-green-base w-4 h-4" />
      </template>
    </div>
    <div class="text-imperium-fg-strong">
      <div class="font-semibold">
        <template v-if="emptyResult"> No price index links added: </template>
        <template v-if="!emptyResult"> Links successfully added for the following tickers: </template>
      </div>
      <div>
        <template v-if="emptyResult"> No symbols matched existing price index pages. </template>
        <template v-if="!emptyResult">
          <span
            v-for="(value, coin) in result"
            :key="coin"
            :class="$style.coin"
          >
            {{ coin }}: x{{ value }}
          </span>
        </template>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { computed } from 'vue';
import { type CryptoChangedStatistic } from '@/features/EditorAddCryptoLinks/composables/auto-link-cryptos';
import CheckMarkIcon from '@/assets/icons/check-mark.svg?component';
import InfoCircleIcon from '@/assets/icons/info-circle-solid.svg?component';
import { isEmpty } from 'lodash';

const props = defineProps<{
  result: CryptoChangedStatistic;
}>();

const emptyResult = computed(() => {
  return isEmpty(props.result);
});
</script>
<style lang="scss" module>
.coin ~ .coin::before {
  content: ', ';
}
</style>
