import type {
  UserEditPayload,
  UserEditResponse,
  UserFetchResponse,
  UserInvitationPayload,
  UserInvitationResponse,
  UsersFetchPayload,
  UsersFetchResponse,
} from '../types';
import { userInvitation, userEdit, userFetch, users, userPostLanguage, userDeleteLanguage } from '@/features/Users/api';

interface PostLanguageInterface {
  id: number;
}
export const UsersService = {
  users: (payload: UsersFetchPayload): Promise<UsersFetchResponse> => {
    const resultPayload = {
      page: payload.page,
      itemsPerPage: payload.itemsPerPage,
    };

    if (payload.searchQuery) {
      resultPayload[`filters[search]`] = payload.searchQuery;
    }

    for (const key in payload.filters) {
      if (payload.filters[key].length) {
        resultPayload[`filters[${key}]`] = payload.filters[key].join(',');
      }
    }

    for (const key in payload.sort) {
      if (payload.sort[key]) {
        resultPayload[`order[${key}]`] = payload.sort[key];
      }
    }

    return users(resultPayload);
  },
  userInvitation: (payload: UserInvitationPayload): Promise<UserInvitationResponse> => userInvitation(payload),
  edit: (id: number, payload: UserEditPayload): Promise<UserEditResponse> => userEdit(id, payload),
  fetch: (id: number): Promise<UserFetchResponse> => userFetch(id),
  postUserLanguages: (userId: number, payload: PostLanguageInterface) => userPostLanguage(userId, payload),
  deleteUserLanguages: (userId: number, languageId: number) => userDeleteLanguage(userId, languageId),
};
