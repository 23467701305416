import { extractData, http } from '@/http';
import type {
  ArticleCategoriesFetchRequest,
  ArticleCategoriesFetchResponse,
  ArticleTagsFetchRequest,
  ArticleTagsFetchResponse,
  ArticleBadgesFetchRequest,
  ArticleBadgesFetchResponse,
  ArticleAuthorsFetchRequest,
  ArticleAuthorsFetchResponse,
  Author,
} from '../types';
import { type DocumentPatchPayload } from '@/types';
import { type DocumentPatchResponse, type DocumentFetchResponse } from '../types';

export const fetchCategories = (params: ArticleCategoriesFetchRequest): Promise<ArticleCategoriesFetchResponse> => {
  return http.get<ArticleCategoriesFetchResponse>('/categories', { params, useAuth: true }).then(extractData);
};

export const fetchTags = (params: ArticleTagsFetchRequest): Promise<ArticleTagsFetchResponse> => {
  return http.get<ArticleTagsFetchResponse>('/tags', { params, useAuth: true }).then(extractData);
};

export const fetchBadges = (params: ArticleBadgesFetchRequest): Promise<ArticleBadgesFetchResponse> => {
  return http.get<ArticleBadgesFetchResponse>('/badges', { params, useAuth: true }).then(extractData);
};

export const patchDocument = (
  id: number,
  language: number,
  params: DocumentPatchPayload,
): Promise<DocumentPatchResponse> =>
  http
    .patch<DocumentPatchResponse>(`/documents/${id}/language/${language}`, params, {
      useAuth: true,
    })
    .then(extractData);

export const fetchDocument = (id: number, language: number): Promise<DocumentFetchResponse> =>
  http
    .get<DocumentFetchResponse>(`/documents/${id}/language/${language}`, {
      useAuth: true,
    })
    .then(extractData);

export const fetchAuthors = (params: ArticleAuthorsFetchRequest): Promise<ArticleAuthorsFetchResponse> => {
  return http.get<ArticleAuthorsFetchResponse>('/authors', { params, useAuth: true }).then(extractData);
};

export const fetchAuthorById = (id: number, language: number): Promise<Author> => {
  return http.get<Author>(`/authors/${id}/language/${language}`).then(extractData);
};

export const updateCoverById = (id: number, languageId: number): Promise<void> => {
  return http.get(`/documents/${id}/language/${languageId}/update-cover`, { useAuth: true }).then(extractData);
};
