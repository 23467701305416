import { defineStore } from 'pinia';
import { computed, ref } from 'vue';
import { UserPermissions } from '@/types';
import type { FetchUserResponse } from '@/features/Auth/types';

export interface Permission {
  edit: boolean;
  id: number;
  name: string;
  slug: string;
}

export const useUserPermissions = defineStore(
  'userPermissions',
  () => {
    const state = ref<Permission[]>([]);
    const userId = ref<number>(0);

    const setUserPermissions = (permissions: Permission[]): void => {
      state.value = permissions;
    };

    const clearUserPermissions = () => {
      state.value = [];
    };

    const setUserId = (id: number): void => {
      userId.value = id;
    };

    const getUserId = (): number => {
      return userId.value;
    };

    const isStateExists = (): boolean => {
      return !!(state.value && state.value.length);
    };
    const isViewAccessGranted = (place: string): boolean => {
      if (!isStateExists()) return false;

      return !!state.value.find((val: Permission) => val.slug === place);
    };

    const isEditAccessGranted = (place: string): boolean => {
      if (!isStateExists()) return false;

      return !!state.value.find((val: Permission) => val.slug === place && val.edit);
    };

    const getUserConditions = (place: string): Array<string> => {
      if (!isStateExists()) return [];

      return state.value.find((val: Permission) => val.slug === place)?.conditions ?? [];
    };

    // add all permissions conditions
    const mediaPageView = computed<boolean>(() => isViewAccessGranted(UserPermissions.MEDIA_PAGE));
    const mediaPageEdit = computed(() => isEditAccessGranted(UserPermissions.MEDIA_PAGE));
    const mediaPageConditions = computed<Array<string>>(() => getUserConditions(UserPermissions.MEDIA_PAGE));

    const categoryPageView = computed<boolean>(() => isViewAccessGranted(UserPermissions.CATEGORY_PAGE));
    const categoryPageEdit = computed(() => isEditAccessGranted(UserPermissions.CATEGORY_PAGE));
    const categoryPageConditions = computed<Array<string>>(() => getUserConditions(UserPermissions.CATEGORY_PAGE));

    const uploadMediaView = computed<boolean>(() => isViewAccessGranted(UserPermissions.MEDIA_UPLOAD_IMAGE));
    const uploadMediaEdit = computed<boolean>(() => isEditAccessGranted(UserPermissions.MEDIA_UPLOAD_IMAGE));
    const uploadMediaConditions = computed<Array<string>>(() => getUserConditions(UserPermissions.MEDIA_UPLOAD_IMAGE));

    const editMediaView = computed<boolean>(() => isViewAccessGranted(UserPermissions.MEDIA_EDIT_IMAGE));
    const editMedia = computed<boolean>(() => isEditAccessGranted(UserPermissions.MEDIA_EDIT_IMAGE));
    const editMediaConditions = computed<Array<string>>(() => getUserConditions(UserPermissions.MEDIA_EDIT_IMAGE));

    const deleteMediaView = computed<boolean>(() => isViewAccessGranted(UserPermissions.MEDIA_DELETE_IMAGE));
    const deleteMediaEdit = computed<boolean>(() => isEditAccessGranted(UserPermissions.MEDIA_DELETE_IMAGE));
    const deleteMediaConditions = computed<Array<string>>(() => getUserConditions(UserPermissions.MEDIA_DELETE_IMAGE));

    const converterPageView = computed<boolean>(() => isViewAccessGranted(UserPermissions.CONVERTER_PAGE));
    const converterPageEdit = computed<boolean>(() => isEditAccessGranted(UserPermissions.CONVERTER_PAGE));
    const usersPageView = computed<boolean>(() => isViewAccessGranted(UserPermissions.USERS_PAGE));
    const usersPageEdit = computed<boolean>(() => isEditAccessGranted(UserPermissions.USERS_PAGE));

    const inviteUserView = computed<boolean>(() => isViewAccessGranted(UserPermissions.USERS_PAGE_INVITE_USER));
    const inviteUserEdit = computed<boolean>(() => isEditAccessGranted(UserPermissions.USERS_PAGE_INVITE_USER));
    const inviteUserConditions = computed<Array<string>>(() =>
      getUserConditions(UserPermissions.USERS_PAGE_INVITE_USER),
    );

    const inviteSuperAdminView = computed<boolean>(() =>
      isViewAccessGranted(UserPermissions.USERS_PAGE_ADD_SUPERADMIN_ROLE),
    );
    const inviteSuperAdminEdit = computed<boolean>(() =>
      isEditAccessGranted(UserPermissions.USERS_PAGE_ADD_SUPERADMIN_ROLE),
    );
    const inviteSuperAdminConditions = computed<Array<string>>(() =>
      getUserConditions(UserPermissions.USERS_PAGE_ADD_SUPERADMIN_ROLE),
    );

    const changeUserStatusView = computed<boolean>(() =>
      isViewAccessGranted(UserPermissions.USERS_PAGE_EDIT_USER_STATUS),
    );
    const changeUserStatusEdit = computed<boolean>(() =>
      isEditAccessGranted(UserPermissions.USERS_PAGE_EDIT_USER_STATUS),
    );
    const changeUserStatusConditions = computed<Array<string>>(() =>
      getUserConditions(UserPermissions.USERS_PAGE_EDIT_USER_STATUS),
    );

    const resetUser2FA = computed<boolean>(() => isEditAccessGranted(UserPermissions.USERS_PAGE_2FA_RESET));

    const createDocumentView = computed<boolean>(() => isViewAccessGranted(UserPermissions.DOCUMENT_CREATE));
    const createDocumentEdit = computed<boolean>(() => isEditAccessGranted(UserPermissions.DOCUMENT_CREATE));
    const createDocumentConditions = computed<Array<string>>(() => getUserConditions(UserPermissions.DOCUMENT_CREATE));

    const documentStatusToWorkView = computed<boolean>(() =>
      isViewAccessGranted(UserPermissions.DOCUMENT_CHANGE_STATUS_TO_WORK),
    );
    const documentStatusToWorkEdit = computed<boolean>(() =>
      isEditAccessGranted(UserPermissions.DOCUMENT_CHANGE_STATUS_TO_WORK),
    );
    const documentStatusToWorkConditions = computed<Array<string>>(() =>
      getUserConditions(UserPermissions.DOCUMENT_CHANGE_STATUS_TO_WORK),
    );

    const documentStatusToFinishView = computed<boolean>(() =>
      isViewAccessGranted(UserPermissions.DOCUMENT_CHANGE_STATUS_FINISH_TEXT),
    );
    const documentStatusToFinishEdit = computed<boolean>(() =>
      isEditAccessGranted(UserPermissions.DOCUMENT_CHANGE_STATUS_FINISH_TEXT),
    );
    const documentStatusToFinishTextCondition = computed<Array<string>>(() =>
      getUserConditions(UserPermissions.DOCUMENT_CHANGE_STATUS_FINISH_TEXT),
    );

    const documentStatusToFinishArtworkView = computed<boolean>(() =>
      isViewAccessGranted(UserPermissions.DOCUMENT_CHANGE_STATUS_FINISH_ARTWORK),
    );
    const documentStatusToFinishArtworkEdit = computed<boolean>(() =>
      isEditAccessGranted(UserPermissions.DOCUMENT_CHANGE_STATUS_FINISH_ARTWORK),
    );
    const documentStatusToFinishArtworkCondition = computed<Array<string>>(() =>
      getUserConditions(UserPermissions.DOCUMENT_CHANGE_STATUS_FINISH_TEXT),
    );

    const documentStatusToBackToWorkView = computed<boolean>(() =>
      isViewAccessGranted(UserPermissions.DOCUMENT_CHANGE_STATUS_BACK_TO_WORK),
    );
    const documentStatusToBackToWorkEdit = computed<boolean>(() =>
      isEditAccessGranted(UserPermissions.DOCUMENT_CHANGE_STATUS_BACK_TO_WORK),
    );
    const documentStatusToBackToWorkConditions = computed<Array<string>>(() =>
      getUserConditions(UserPermissions.DOCUMENT_CHANGE_STATUS_BACK_TO_WORK),
    );

    const documentStatusToRejectTextView = computed<boolean>(() =>
      isViewAccessGranted(UserPermissions.DOCUMENT_CHANGE_STATUS_REJECT_TEXT),
    );
    const documentStatusToRejectTextEdit = computed<boolean>(() =>
      isEditAccessGranted(UserPermissions.DOCUMENT_CHANGE_STATUS_REJECT_TEXT),
    );
    const documentStatusToRejectTextConditions = computed<Array<string>>(() =>
      getUserConditions(UserPermissions.DOCUMENT_CHANGE_STATUS_REJECT_TEXT),
    );

    const documentStatusToRejectArtView = computed<boolean>(() =>
      isViewAccessGranted(UserPermissions.DOCUMENT_CHANGE_STATUS_REJECT_ARTWORK),
    );
    const documentStatusToRejectArtEdit = computed<boolean>(() =>
      isEditAccessGranted(UserPermissions.DOCUMENT_CHANGE_STATUS_REJECT_ARTWORK),
    );
    const documentStatusToRejectArtConditions = computed<Array<string>>(() =>
      getUserConditions(UserPermissions.DOCUMENT_CHANGE_STATUS_REJECT_ARTWORK),
    );

    const documentStatusToFinishReviewView = computed<boolean>(() =>
      isViewAccessGranted(UserPermissions.DOCUMENT_CHANGE_STATUS_FINISH_REVIEW),
    );
    const documentStatusToFinishReviewEdit = computed<boolean>(() =>
      isEditAccessGranted(UserPermissions.DOCUMENT_CHANGE_STATUS_FINISH_REVIEW),
    );
    const documentStatusToFinishReviewConditions = computed<Array<string>>(() =>
      getUserConditions(UserPermissions.DOCUMENT_CHANGE_STATUS_FINISH_REVIEW),
    );

    const documentStatusToPublishView = computed<boolean>(() =>
      isViewAccessGranted(UserPermissions.DOCUMENT_CHANGE_STATUS_PUBLISH),
    );
    const documentStatusToPublishEdit = computed<boolean>(() =>
      isEditAccessGranted(UserPermissions.DOCUMENT_CHANGE_STATUS_PUBLISH),
    );
    const documentStatusToPublishCondition = computed<Array<string>>(() =>
      getUserConditions(UserPermissions.DOCUMENT_CHANGE_STATUS_PUBLISH),
    );

    const documentStatusToUpdateView = computed<boolean>(() =>
      isViewAccessGranted(UserPermissions.DOCUMENT_CHANGE_STATUS_UPDATE),
    );
    const documentStatusToUpdateEdit = computed<boolean>(() =>
      isEditAccessGranted(UserPermissions.DOCUMENT_CHANGE_STATUS_UPDATE),
    );
    const documentStatusToUpdateConditions = computed<Array<string>>(() =>
      getUserConditions(UserPermissions.DOCUMENT_CHANGE_STATUS_UPDATE),
    );

    const documentMetadataTextView = computed<boolean>(() =>
      isViewAccessGranted(UserPermissions.DOCUMENT_METADATA_TEXT),
    );
    const documentMetadataTextEdit = computed<boolean>(() =>
      isEditAccessGranted(UserPermissions.DOCUMENT_METADATA_TEXT),
    );
    const documentMetadataTextCondition = computed<Array<string>>(() =>
      getUserConditions(UserPermissions.DOCUMENT_METADATA_TEXT),
    );

    const documentMetadataSlugView = computed<boolean>(() =>
      isViewAccessGranted(UserPermissions.DOCUMENT_METADATA_SLUG),
    );
    const documentMetadataSlugEdit = computed<boolean>(() =>
      isEditAccessGranted(UserPermissions.DOCUMENT_METADATA_SLUG),
    );
    const documentMetadataSlugConditions = computed<Array<string>>(() =>
      getUserConditions(UserPermissions.DOCUMENT_METADATA_SLUG),
    );

    const documentMetadataTitleView = computed<boolean>(() =>
      isViewAccessGranted(UserPermissions.DOCUMENT_METADATA_TITLE),
    );
    const documentMetadataTitleEdit = computed<boolean>(() =>
      isEditAccessGranted(UserPermissions.DOCUMENT_METADATA_TITLE),
    );
    const documentMetadataTitleConditions = computed<Array<string>>(() =>
      getUserConditions(UserPermissions.DOCUMENT_METADATA_TITLE),
    );

    const documentMetadataCoverView = computed<boolean>(() =>
      isViewAccessGranted(UserPermissions.DOCUMENT_METADATA_COVER),
    );
    const documentMetadataCoverEdit = computed<boolean>(() =>
      isEditAccessGranted(UserPermissions.DOCUMENT_METADATA_COVER),
    );
    const documentMetadataCoverConditions = computed<Array<string>>(() =>
      getUserConditions(UserPermissions.DOCUMENT_METADATA_COVER),
    );

    const documentMetadataOnlyCoverUpdateView = computed<boolean>(() =>
      isViewAccessGranted(UserPermissions.DOCUMENT_METADATA_COVER_IMAGE_UPDATE),
    );

    const documentMetadataOnlyCoverUpdateEdit = computed<boolean>(() =>
      isEditAccessGranted(UserPermissions.DOCUMENT_METADATA_COVER_IMAGE_UPDATE),
    );

    const documentMetadataBreakingNewsView = computed<boolean>(() =>
      isViewAccessGranted(UserPermissions.DOCUMENT_METADATA_BREAKING_NEWS),
    );
    const documentMetadataBreakingNewsEdit = computed<boolean>(() =>
      isEditAccessGranted(UserPermissions.DOCUMENT_METADATA_BREAKING_NEWS),
    );
    const documentMetadataBreakingNewsConditions = computed<Array<string>>(() =>
      getUserConditions(UserPermissions.DOCUMENT_METADATA_BREAKING_NEWS),
    );

    const documentMetadataOriginalArtView = computed<boolean>(() =>
      isViewAccessGranted(UserPermissions.DOCUMENT_METADATA_NEEDS_ORIGINAL_ARTWORK),
    );
    const documentMetadataOriginalArtEdit = computed<boolean>(() =>
      isEditAccessGranted(UserPermissions.DOCUMENT_METADATA_NEEDS_ORIGINAL_ARTWORK),
    );
    const documentMetadataOriginalArtConditions = computed<Array<string>>(() =>
      getUserConditions(UserPermissions.DOCUMENT_METADATA_NEEDS_ORIGINAL_ARTWORK),
    );

    const documentMetadataEditorSelectView = computed<boolean>(() =>
      isViewAccessGranted(UserPermissions.DOCUMENT_METADATA_EDITOR_SELECT),
    );
    const documentMetadataEditorSelectEdit = computed<boolean>(() =>
      isEditAccessGranted(UserPermissions.DOCUMENT_METADATA_EDITOR_SELECT),
    );
    const documentMetadataEditorSelectConditions = computed<Array<string>>(() =>
      getUserConditions(UserPermissions.DOCUMENT_METADATA_EDITOR_SELECT),
    );

    const documentMetadataWriterSelectView = computed<boolean>(() =>
      isViewAccessGranted(UserPermissions.DOCUMENT_METADATA_WRITER_SELECT),
    );
    const documentMetadataWriterSelectEdit = computed<boolean>(() =>
      isEditAccessGranted(UserPermissions.DOCUMENT_METADATA_WRITER_SELECT),
    );
    const documentMetadataWriterSelectConditions = computed<Array<string>>(() =>
      getUserConditions(UserPermissions.DOCUMENT_METADATA_WRITER_SELECT),
    );

    const documentMetadataCopyEditorSelectView = computed<boolean>(() =>
      isViewAccessGranted(UserPermissions.DOCUMENT_METADATA_COPY_EDITOR),
    );
    const documentMetadataCopyEditorSelectEdit = computed<boolean>(() =>
      isEditAccessGranted(UserPermissions.DOCUMENT_METADATA_COPY_EDITOR),
    );
    const documentMetadataCopyEditorSelectConditions = computed<Array<string>>(() =>
      getUserConditions(UserPermissions.DOCUMENT_METADATA_COPY_EDITOR),
    );

    const documentMetadataYoutubeCoverView = computed<boolean>(() =>
      isViewAccessGranted(UserPermissions.DOCUMENT_METADATA_YOUTUBE_COVER),
    );
    const documentMetadataYoutubeCoverEdit = computed<boolean>(() =>
      isEditAccessGranted(UserPermissions.DOCUMENT_METADATA_YOUTUBE_COVER),
    );
    const documentMetadataYoutubeCoverConditions = computed<Array<string>>(() =>
      getUserConditions(UserPermissions.DOCUMENT_METADATA_YOUTUBE_COVER),
    );

    const documentMetadataCoverDeadlineView = computed<boolean>(() =>
      isViewAccessGranted(UserPermissions.DOCUMENT_METADATA_DEADLINE_FOR_COVER_IMAGE),
    );
    const documentMetadataCoverDeadlineEdit = computed<boolean>(() =>
      isEditAccessGranted(UserPermissions.DOCUMENT_METADATA_DEADLINE_FOR_COVER_IMAGE),
    );
    const documentMetadataCoverDeadlineCondition = computed<Array<string>>(() =>
      getUserConditions(UserPermissions.DOCUMENT_METADATA_DEADLINE_FOR_COVER_IMAGE),
    );

    const documentMetadataEmbargoView = computed<boolean>(() =>
      isViewAccessGranted(UserPermissions.DOCUMENT_METADATA_EMBARGO),
    );
    const documentMetadataEmbargoEdit = computed<boolean>(() =>
      isEditAccessGranted(UserPermissions.DOCUMENT_METADATA_EMBARGO),
    );
    const documentMetadataEmbargoCondition = computed<Array<string>>(() =>
      getUserConditions(UserPermissions.DOCUMENT_METADATA_EMBARGO),
    );

    const documentMetadataScheduledView = computed<boolean>(() =>
      isViewAccessGranted(UserPermissions.DOCUMENT_METADATA_SCHEDULE_PUBLICATION),
    );
    const documentMetadataScheduledEdit = computed<boolean>(() =>
      isEditAccessGranted(UserPermissions.DOCUMENT_METADATA_SCHEDULE_PUBLICATION),
    );
    const documentMetadataScheduledConditions = computed<Array<string>>(() =>
      getUserConditions(UserPermissions.DOCUMENT_METADATA_SCHEDULE_PUBLICATION),
    );

    const documentMetadataCategoryView = computed<boolean>(() =>
      isViewAccessGranted(UserPermissions.DOCUMENT_METADATA_CATEGORY),
    );
    const documentMetadataCategoryEdit = computed<boolean>(() =>
      isEditAccessGranted(UserPermissions.DOCUMENT_METADATA_CATEGORY),
    );
    const documentMetadataCategoryConditions = computed<Array<string>>(() =>
      getUserConditions(UserPermissions.DOCUMENT_METADATA_CATEGORY),
    );

    const documentMetadataLabelView = computed<boolean>(() =>
      isViewAccessGranted(UserPermissions.DOCUMENT_METADATA_LABEL),
    );
    const documentMetadataLabelEdit = computed<boolean>(() =>
      isEditAccessGranted(UserPermissions.DOCUMENT_METADATA_LABEL),
    );
    const documentMetadataLabelConditions = computed<Array<string>>(() =>
      getUserConditions(UserPermissions.DOCUMENT_METADATA_LABEL),
    );

    const documentMetadataSuperTagsView = computed<boolean>(() =>
      isViewAccessGranted(UserPermissions.DOCUMENT_METADATA_SUPER_TAGS),
    );
    const documentMetadataSuperTagsEdit = computed<boolean>(() =>
      isEditAccessGranted(UserPermissions.DOCUMENT_METADATA_SUPER_TAGS),
    );
    const documentMetadataSuperTagsConditions = computed<Array<string>>(() =>
      getUserConditions(UserPermissions.DOCUMENT_METADATA_SUPER_TAGS),
    );

    const documentMetadataTagsView = computed<boolean>(() =>
      isViewAccessGranted(UserPermissions.DOCUMENT_METADATA_TAGS),
    );
    const documentMetadataTagsEdit = computed<boolean>(() =>
      isEditAccessGranted(UserPermissions.DOCUMENT_METADATA_TAGS),
    );
    const documentMetadataTagsConditions = computed<Array<string>>(() =>
      getUserConditions(UserPermissions.DOCUMENT_METADATA_TAGS),
    );

    const documentMetadataXLeadTextView = computed<boolean>(() =>
      isViewAccessGranted(UserPermissions.DOCUMENT_METADATA_X_LEADTEXT),
    );
    const documentMetadataXLeadTextEdit = computed<boolean>(() =>
      isEditAccessGranted(UserPermissions.DOCUMENT_METADATA_X_LEADTEXT),
    );
    const documentMetadataXLeadTextConditions = computed<Array<string>>(() =>
      getUserConditions(UserPermissions.DOCUMENT_METADATA_X_LEADTEXT),
    );

    const documentMetadataExcludeFromXRSSView = computed<boolean>(() =>
      isViewAccessGranted(UserPermissions.DOCUMENT_METADATA_EXCLUDE_FROM_X_RSS),
    );
    const documentMetadataExcludeFromXRSSEdit = computed<boolean>(() =>
      isEditAccessGranted(UserPermissions.DOCUMENT_METADATA_EXCLUDE_FROM_X_RSS),
    );
    const documentMetadataExcludeFromXRSSConditions = computed<Array<string>>(() =>
      getUserConditions(UserPermissions.DOCUMENT_METADATA_EXCLUDE_FROM_X_RSS),
    );

    const documentMetadataExcludeFromTgChannelsView = computed<boolean>(() =>
      isViewAccessGranted(UserPermissions.DOCUMENT_METADATA_EXCLUDE_FROM_TELEGRAM_CHANNELS),
    );
    const documentMetadataExcludeFromTgChannelsEdit = computed<boolean>(() =>
      isEditAccessGranted(UserPermissions.DOCUMENT_METADATA_EXCLUDE_FROM_TELEGRAM_CHANNELS),
    );
    const documentMetadataExcludeFromTgChannelsConditions = computed<Array<string>>(() =>
      getUserConditions(UserPermissions.DOCUMENT_METADATA_EXCLUDE_FROM_TELEGRAM_CHANNELS),
    );

    const documentMetadataSeoDescView = computed<boolean>(() =>
      isViewAccessGranted(UserPermissions.DOCUMENT_METADATA_SEO_META_DESCRIPTIONS),
    );
    const documentMetadataSeoDescEdit = computed<boolean>(() =>
      isEditAccessGranted(UserPermissions.DOCUMENT_METADATA_SEO_META_DESCRIPTIONS),
    );
    const documentMetadataSeoDescConditions = computed<Array<string>>(() =>
      getUserConditions(UserPermissions.DOCUMENT_METADATA_SEO_META_DESCRIPTIONS),
    );

    const documentMetadataHideFromRssView = computed<boolean>(() =>
      isViewAccessGranted(UserPermissions.DOCUMENT_METADATA_HIDE_NO_INDEX),
    );
    const documentMetadataHideFromRssEdit = computed<boolean>(() =>
      isEditAccessGranted(UserPermissions.DOCUMENT_METADATA_HIDE_NO_INDEX),
    );

    const documentMetadataHideFromMainPageView = computed<boolean>(() =>
      isViewAccessGranted(UserPermissions.DOCUMENT_METADATA_HIDE_FROM_MAIN_PAGE),
    );
    const documentMetadataHideFromMainPageEdit = computed<boolean>(() =>
      isEditAccessGranted(UserPermissions.DOCUMENT_METADATA_HIDE_FROM_MAIN_PAGE),
    );
    const documentMetadataHideFromMainPageCondition = computed<Array<string>>(() =>
      getUserConditions(UserPermissions.DOCUMENT_METADATA_HIDE_FROM_MAIN_PAGE),
    );

    const documentMetadataShowInMarketsView = computed<boolean>(() =>
      isViewAccessGranted(UserPermissions.DOCUMENT_METADATA_SHOW_IN_MARKETS),
    );
    const documentMetadataShowInMarketsEdit = computed<boolean>(() =>
      isEditAccessGranted(UserPermissions.DOCUMENT_METADATA_SHOW_IN_MARKETS),
    );
    const documentMetadataShowInMarketsCondition = computed<Array<string>>(() =>
      getUserConditions(UserPermissions.DOCUMENT_METADATA_SHOW_IN_MARKETS),
    );

    const documentMetadataPromoPostView = computed<boolean>(() =>
      isViewAccessGranted(UserPermissions.DOCUMENT_METADATA_PROMO_POST),
    );
    const documentMetadataPromoPostEdit = computed<boolean>(() =>
      isEditAccessGranted(UserPermissions.DOCUMENT_METADATA_PROMO_POST),
    );
    const documentMetadataPromoPostCondition = computed<Array<string>>(() =>
      getUserConditions(UserPermissions.DOCUMENT_METADATA_SHOW_IN_MARKETS),
    );

    const documentMetadataNoIndexCondition = computed<Array<string>>(() =>
      getUserConditions(UserPermissions.DOCUMENT_METADATA_HIDE_NO_INDEX),
    );

    const documentMetadataRepublishArticleView = computed<boolean>(() =>
      isViewAccessGranted(UserPermissions.DOCUMENT_METADATA_REPUBLISHED_ARTICLE),
    );
    const documentMetadataRepublishArticleEdit = computed<boolean>(() =>
      isEditAccessGranted(UserPermissions.DOCUMENT_METADATA_REPUBLISHED_ARTICLE),
    );
    const documentMetadataRepublishArticleConditions = computed<Array<string>>(() =>
      getUserConditions(UserPermissions.DOCUMENT_METADATA_REPUBLISHED_ARTICLE),
    );

    const documentMetadataOriginContentView = computed<boolean>(() =>
      isViewAccessGranted(UserPermissions.DOCUMENT_METADATA_ORIGINAL_CONTENT),
    );
    const documentMetadataOriginContentEdit = computed<boolean>(() =>
      isEditAccessGranted(UserPermissions.DOCUMENT_METADATA_ORIGINAL_CONTENT),
    );
    const documentMetadataOriginContentConditions = computed<Array<string>>(() =>
      getUserConditions(UserPermissions.DOCUMENT_METADATA_ORIGINAL_CONTENT),
    );

    const userLanguageView = computed<boolean>(() =>
      isViewAccessGranted(UserPermissions.USERS_PAGE_EDIT_USER_LANGUAGES),
    );
    const userLanguageEdit = computed<boolean>(() =>
      isEditAccessGranted(UserPermissions.USERS_PAGE_EDIT_USER_LANGUAGES),
    );

    return {
      setUserPermissions,
      clearUserPermissions,
      setUserId,
      getUserId,
      state,
      mediaPageView,
      mediaPageEdit,
      categoryPageView,
      categoryPageEdit,
      converterPageView,
      converterPageEdit,
      uploadMediaView,
      uploadMediaEdit,
      editMediaView,
      editMedia,
      deleteMediaView,
      deleteMediaEdit,
      usersPageView,
      usersPageEdit,
      inviteUserView,
      inviteUserEdit,
      inviteSuperAdminView,
      inviteSuperAdminEdit,
      changeUserStatusView,
      changeUserStatusEdit,
      resetUser2FA,
      userLanguageEdit,
      userLanguageView,
      createDocumentView,
      createDocumentEdit,
      documentStatusToWorkView,
      documentStatusToWorkEdit,
      documentStatusToFinishView,
      documentStatusToFinishEdit,
      documentStatusToFinishTextCondition,
      documentStatusToFinishArtworkView,
      documentStatusToFinishArtworkEdit,
      documentStatusToFinishArtworkCondition,
      documentStatusToBackToWorkView,
      documentStatusToBackToWorkEdit,
      documentStatusToRejectTextView,
      documentStatusToRejectTextEdit,
      documentStatusToRejectArtView,
      documentStatusToRejectArtEdit,
      documentStatusToFinishReviewView,
      documentStatusToFinishReviewEdit,
      documentStatusToPublishView,
      documentStatusToPublishEdit,
      documentStatusToPublishCondition,
      documentStatusToUpdateView,
      documentStatusToUpdateEdit,
      documentMetadataTextView,
      documentMetadataTextEdit,
      documentMetadataTextCondition,
      documentMetadataSlugView,
      documentMetadataSlugEdit,
      documentMetadataTitleView,
      documentMetadataTitleEdit,
      documentMetadataCoverView,
      documentMetadataCoverEdit,
      documentMetadataOnlyCoverUpdateView,
      documentMetadataOnlyCoverUpdateEdit,
      documentMetadataBreakingNewsView,
      documentMetadataBreakingNewsEdit,
      documentMetadataOriginalArtView,
      documentMetadataOriginalArtEdit,
      documentMetadataEditorSelectView,
      documentMetadataEditorSelectEdit,
      documentMetadataWriterSelectView,
      documentMetadataWriterSelectEdit,
      documentMetadataCopyEditorSelectView,
      documentMetadataCopyEditorSelectEdit,
      documentMetadataYoutubeCoverView,
      documentMetadataYoutubeCoverEdit,
      documentMetadataCoverDeadlineView,
      documentMetadataCoverDeadlineEdit,
      documentMetadataCoverDeadlineCondition,
      documentMetadataEmbargoView,
      documentMetadataEmbargoEdit,
      documentMetadataEmbargoCondition,
      documentMetadataScheduledView,
      documentMetadataScheduledEdit,
      documentMetadataCategoryView,
      documentMetadataCategoryEdit,
      documentMetadataLabelView,
      documentMetadataLabelEdit,
      documentMetadataSuperTagsView,
      documentMetadataSuperTagsEdit,
      documentMetadataTagsView,
      documentMetadataTagsEdit,
      documentMetadataXLeadTextView,
      documentMetadataXLeadTextEdit,
      documentMetadataExcludeFromXRSSView,
      documentMetadataExcludeFromXRSSEdit,
      documentMetadataExcludeFromTgChannelsView,
      documentMetadataExcludeFromTgChannelsEdit,
      documentMetadataSeoDescView,
      documentMetadataSeoDescEdit,
      documentMetadataHideFromRssView,
      documentMetadataHideFromRssEdit,
      documentMetadataHideFromMainPageView,
      documentMetadataHideFromMainPageEdit,
      documentMetadataHideFromMainPageCondition,
      documentMetadataShowInMarketsView,
      documentMetadataShowInMarketsEdit,
      documentMetadataShowInMarketsCondition,
      documentMetadataPromoPostView,
      documentMetadataPromoPostEdit,
      documentMetadataPromoPostCondition,
      documentMetadataNoIndexCondition,
      documentMetadataRepublishArticleView,
      documentMetadataRepublishArticleEdit,
      documentMetadataOriginContentView,
      documentMetadataOriginContentEdit,
      mediaPageConditions,
      categoryPageConditions,
      uploadMediaConditions,
      editMediaConditions,
      deleteMediaConditions,
      inviteUserConditions,
      inviteSuperAdminConditions,
      changeUserStatusConditions,
      createDocumentConditions,
      documentStatusToWorkConditions,
      documentStatusToBackToWorkConditions,
      documentStatusToRejectTextConditions,
      documentStatusToRejectArtConditions,
      documentStatusToFinishReviewConditions,
      documentStatusToUpdateConditions,
      documentMetadataSlugConditions,
      documentMetadataTitleConditions,
      documentMetadataCoverConditions,
      documentMetadataBreakingNewsConditions,
      documentMetadataOriginalArtConditions,
      documentMetadataEditorSelectConditions,
      documentMetadataWriterSelectConditions,
      documentMetadataCopyEditorSelectConditions,
      documentMetadataYoutubeCoverConditions,
      documentMetadataScheduledConditions,
      documentMetadataCategoryConditions,
      documentMetadataLabelConditions,
      documentMetadataSuperTagsConditions,
      documentMetadataTagsConditions,
      documentMetadataXLeadTextConditions,
      documentMetadataExcludeFromXRSSConditions,
      documentMetadataExcludeFromTgChannelsConditions,
      documentMetadataSeoDescConditions,
      documentMetadataRepublishArticleConditions,
      documentMetadataOriginContentConditions,
    };
  },
  { persist: true },
);

export const useUserStore = defineStore(
  'useUserStore',
  () => {
    const state = ref<FetchUserResponse>(null);

    const setUserState = (payload: FetchUserResponse): void => {
      state.value = payload;
    };

    const clearUserState = (): void => {
      state.value = null;
    };

    return {
      state,
      setUserState,
      clearUserState,
    };
  },
  { persist: true },
);
