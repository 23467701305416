import { WebsocketProvider } from 'y-websocket';
import { colladitorWs } from '@/config';
import { getTraceAndBaggage } from '@/utils/sentry';
import { onMounted, ref } from 'vue';
import * as Sentry from '@sentry/vue';
import { useArticleUpdatedAt } from '@/stores/article.store';
import type { Doc } from 'yjs';
import { useActiveArticleStore } from '@/stores/users.store';

type Params = {
  documentId: string;
  languageId: string;
  userId: string;
  userName: string;
  ydoc: Doc;
};

export const useCRDTConnection = ({ documentId, ydoc, userId, userName, languageId }: Params) => {
  const ws = ref();
  const provider = ref();
  const articleUpdatedAt = useArticleUpdatedAt();
  const isFirstConnection = ref<boolean>(true);
  const useUsersAvatarStore = useActiveArticleStore();
  const isDocumentSynced = ref<boolean>(false);

  onMounted(() => {
    const sentryData = getTraceAndBaggage();

    provider.value = new WebsocketProvider(colladitorWs, documentId, ydoc, {
      params: {
        userId,
        languageId,

        // Sentry
        sentryTrace: sentryData?.sentryTraceHeader || '',
        sentryBaggage: sentryData?.sentryBaggageHeader || '',
      },
    });

    ws.value = provider.value.ws;

    ws.value.addEventListener('message', (event: MessageEvent) => {
      try {
        const currentTime = new Intl.DateTimeFormat('en', {
          dateStyle: 'long',
          timeStyle: 'short',
        }).format(new Date());
        articleUpdatedAt.setArticleUpdatedAt(currentTime);

        if (!event?.data) return;
        if (typeof event.data !== 'string') return;

        if (event.data.type !== 'error' && event.data instanceof ArrayBuffer && !articleUpdatedAt.connection) {
          articleUpdatedAt.setConnection(true);
        }

        const message = JSON.parse(event.data);

        if (message.type !== 'error') return;

        articleUpdatedAt.setConnection(false);
        Sentry.captureMessage(message.message);
      } catch (e) {
        Sentry.captureException(e);
      }
    });

    // Set cursor with current user name
    provider.value.on('status', (event: { status: string }) => {
      if (event.status === 'disconnected') {
        articleUpdatedAt.setConnection(false);
      }

      if (event.status === 'connected') {
        isFirstConnection.value = false;

        articleUpdatedAt.setConnection(true);
        const awareness = provider.value.awareness;
        awareness.setLocalStateField('user', { name: userName, id: userId });
      }
    });

    const getUsers = () => {
      const states: Map<string, Record<string, unknown>> = provider.value.awareness.getStates();
      return Array.from(states.values());
    };

    provider.value.awareness.on('change', () => {
      const users = getUsers();
      const mappedUsers: ProsemirrorUser[] = users.map((user) => {
        return user['user'] as ProsemirrorUser;
      });
      useUsersAvatarStore.setUsersStore(mappedUsers);
    });

    provider.value.on('sync', (isSynced: boolean) => {
      if (isSynced && !isDocumentSynced.value) {
        isDocumentSynced.value = true;
        const users = getUsers();

        const mappedUsers: ProsemirrorUser[] = users.map((user) => {
          return user['user'] as ProsemirrorUser;
        });

        useUsersAvatarStore.setUsersStore(mappedUsers);
      }
    });
  });

  return {
    ws,
    provider,
    isFirstConnection,
  };
};
