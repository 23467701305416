import { computed } from 'vue';
import { useFetchUsers } from '@/features/Users/queries';
import { type UsersFetchPayload } from '@/features/Users/types';
import { mapUsersToSelectItemByRole } from '@/features/Users/utils';
import { ROLES } from '@/types';
import type { SelectItem } from '@/components/FormSelect.vue';
import { useRolesStore } from '@/features/Roles/stores/roles.store';

/**
 * @deprecated
 * We can use useUsersByRoles
 */
export const useWriters = () => {
  const rolesStore = useRolesStore();

  const roles = computed(() => rolesStore.state);

  const getWriterRoleId =
    computed(() => roles?.value?.items?.find((role) => role.slug === ROLES.ROLE_WRITER).id) ?? null;

  const fetchUserParams = computed<UsersFetchPayload>(() => ({
    page: 1,
    itemsPerPage: 1000,
    filters: {
      roles: [getWriterRoleId],
    },
  }));

  const { data: users } = useFetchUsers(fetchUserParams);

  const writers = computed<SelectItem[]>(() => {
    if (!users?.value?.items?.length) {
      return [];
    }

    return mapUsersToSelectItemByRole(users.value.items, ROLES.ROLE_WRITER);
  });

  return {
    writers,
  };
};
