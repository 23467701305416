<template>
  <component
    :is="component"
    :class="
      buttonClass({
        color: props.color,
        visualType: props.visualType,
        wFull: props.isFullWidth,
        isActive: props.isActive,
        size: props.size,
        onSurface: isOnSurface,
        rounded: props.rounded,
        position: props.position,
      })
    "
    :disabled="props.isLoading || props.isDisabled"
    :type="props.type"
  >
    <div
      class="flex items-center justify-center"
      :class="{
        'opacity-50': isLoading,
        'w-max': !props.isFullWidth,
      }"
    >
      <template v-if="isLeftIcon">
        <slot name="leftIcon" />
      </template>
      <div
        v-if="props.leadingLabel"
        class="ml-1 opacity-60"
      >
        {{ props.leadingLabel }}
      </div>
      <div
        v-if="!isLabel && isRightIcon && isLeftIcon"
        class="w-2"
      />
      <div
        v-if="isLabel"
        :class="{ 'px-1': props.visualType !== BUTTON_TYPE.PLAIN }"
      >
        <slot />
      </div>
      <template v-if="isRightIcon">
        <slot name="rightIcon" />
      </template>
    </div>
    <span
      v-if="isLoading"
      class="absolute inset-0 flex items-center justify-center z-[1000]"
    >
      <Spinner />
      <span class="sr-only"> Loading... </span>
    </span>
  </component>
</template>

<script setup lang="ts">
import { computed, useSlots } from 'vue';
import Spinner from '@/components/Spinner.vue';
import { BUTTON_COLOR, BUTTON_ON_SURFACE, BUTTON_ROUNDED, BUTTON_TYPE, SIZES } from '@/types';
import { tv } from 'tailwind-variants';

const props = withDefaults(
  defineProps<{
    size?: SIZES;
    rounded?: BUTTON_ROUNDED;
    isOnSurface?: BUTTON_ON_SURFACE;
    color?: BUTTON_COLOR;
    visualType?: BUTTON_TYPE;

    leadingLabel?: string;
    isDisabled?: boolean;
    isLoading?: boolean;
    isFullWidth?: boolean;
    isActive?: boolean;
    isLink?: boolean;
    type?: string;
    position?: string;
  }>(),
  {
    size: SIZES.MEDIUM,
    rounded: BUTTON_ROUNDED.LARGE,
    isOnSurface: BUTTON_ON_SURFACE.DEFAULT,
    color: BUTTON_COLOR.DEFAULT,
    visualType: BUTTON_TYPE.PRIMARY,

    leadingLabel: '',
    isDisabled: false,
    isLoading: false,
    isFullWidth: false,
    isActive: false,
    isLink: false,
    type: 'button',
    position: 'relative',
  },
);

const slots = useSlots();
const isLabel = computed(() => !!slots.default);
const isLeftIcon = computed(() => !!slots.leftIcon);
const isRightIcon = computed(() => !!slots.rightIcon);

const buttonClass = tv({
  base: 'font-semibold text-center',
  variants: {
    size: {
      [SIZES.LARGE]: 'button-lg',
      [SIZES.MEDIUM]: 'button-md',
      [SIZES.SMALL]: 'button-sm',
      [SIZES.XSMALL]: 'button-xs',
    },
    rounded: {
      [BUTTON_ROUNDED.LARGE]: 'button-rounded-lg',
      [BUTTON_ROUNDED.LEFT_LARGE]: 'button-rounded-l-lg',
      [BUTTON_ROUNDED.RIGHT_LARGE]: 'button-rounded-r-lg',
      [BUTTON_ROUNDED.BASE]: 'button-rounded-base',
      [BUTTON_ROUNDED.NONE]: 'button-rounded-none',
      [BUTTON_ROUNDED.FULL]: 'button-rounded-full',
    },
    position: {
      relative: 'relative',
      static: 'static',
      absolute: 'absolute',
    },
    visualType: {
      [BUTTON_TYPE.INVISIBLE]: 'p-0',
    },
    wFull: {
      true: 'w-full',
    },
    isActive: {
      true: 'bg-imperium-bg-3',
    },
  },
  compoundVariants: [
    // Primary
    {
      color: BUTTON_COLOR.DEFAULT,
      visualType: BUTTON_TYPE.PRIMARY,
      onSurface: BUTTON_ON_SURFACE.DEFAULT,
      class: 'button-primary-primary-default',
    },
    {
      color: BUTTON_COLOR.PRIMARY,
      visualType: BUTTON_TYPE.PRIMARY,
      onSurface: BUTTON_ON_SURFACE.PRIMARY,
      class: 'button-primary-default-surface',
    },
    {
      color: BUTTON_COLOR.RED,
      visualType: BUTTON_TYPE.PRIMARY,
      onSurface: BUTTON_ON_SURFACE.DEFAULT,
      class: 'button-primary-red-default',
    },
    {
      color: BUTTON_COLOR.GREEN,
      visualType: BUTTON_TYPE.PRIMARY,
      onSurface: BUTTON_ON_SURFACE.DEFAULT,
      class: 'button-primary-green-default',
    },
    // Secondary
    {
      color: BUTTON_COLOR.DEFAULT,
      visualType: BUTTON_TYPE.SECONDARY,
      onSurface: BUTTON_ON_SURFACE.DEFAULT,
      class: 'button-secondary-primary-default',
    },
    {
      color: BUTTON_COLOR.PRIMARY,
      visualType: BUTTON_TYPE.SECONDARY,
      onSurface: BUTTON_ON_SURFACE.PRIMARY,
      class: 'button-secondary-default-surface',
    },
    {
      color: BUTTON_COLOR.RED,
      visualType: BUTTON_TYPE.SECONDARY,
      onSurface: BUTTON_ON_SURFACE.DEFAULT,
      class: 'button-secondary-red-default',
    },
    {
      color: BUTTON_COLOR.GREEN,
      visualType: BUTTON_TYPE.SECONDARY,
      onSurface: BUTTON_ON_SURFACE.DEFAULT,
      class: 'button-secondary-green-default',
    },
    {
      color: BUTTON_COLOR.BASE,
      visualType: BUTTON_TYPE.BASE,
      onSurface: BUTTON_ON_SURFACE.DEFAULT,
      class: 'button-secondary-gold-default',
    },
    // Tertiary
    {
      color: BUTTON_COLOR.DEFAULT,
      visualType: BUTTON_TYPE.TERTIARY,
      onSurface: BUTTON_ON_SURFACE.DEFAULT,
      class: 'button-tertiary-default-surface',
    },
    {
      color: BUTTON_COLOR.PRIMARY,
      visualType: BUTTON_TYPE.TERTIARY,
      onSurface: BUTTON_ON_SURFACE.PRIMARY,
      class: 'button-tertiary-primary-default',
    },
    {
      color: BUTTON_COLOR.RED,
      visualType: BUTTON_TYPE.TERTIARY,
      onSurface: BUTTON_ON_SURFACE.DEFAULT,
      class: 'button-tertiary-red-default',
    },
    {
      color: BUTTON_COLOR.GREEN,
      visualType: BUTTON_TYPE.TERTIARY,
      onSurface: BUTTON_ON_SURFACE.DEFAULT,
      class: 'button-tertiary-green-default',
    },
    // GHOST
    {
      color: BUTTON_COLOR.DEFAULT,
      visualType: BUTTON_TYPE.GHOST,
      onSurface: BUTTON_ON_SURFACE.DEFAULT,
      class: 'button-ghost-default-surface',
    },
    {
      color: BUTTON_COLOR.PRIMARY,
      visualType: BUTTON_TYPE.GHOST,
      onSurface: BUTTON_ON_SURFACE.PRIMARY,
      class: 'button-ghost-primary-default',
    },
    {
      color: BUTTON_COLOR.RED,
      visualType: BUTTON_TYPE.GHOST,
      onSurface: BUTTON_ON_SURFACE.DEFAULT,
      class: 'button-ghost-red-default',
    },
    {
      color: BUTTON_COLOR.GREEN,
      visualType: BUTTON_TYPE.GHOST,
      onSurface: BUTTON_ON_SURFACE.DEFAULT,
      class: 'button-ghost-green-default',
    },
    // PLAIN
    {
      color: BUTTON_COLOR.DEFAULT,
      visualType: BUTTON_TYPE.PLAIN,
      onSurface: BUTTON_ON_SURFACE.DEFAULT,
      class: 'button-plain-default-surface',
    },
    {
      color: BUTTON_COLOR.PRIMARY,
      visualType: BUTTON_TYPE.PLAIN,
      onSurface: BUTTON_ON_SURFACE.PRIMARY,
      class: 'button-plain-primary-default',
    },
    {
      color: BUTTON_COLOR.RED,
      visualType: BUTTON_TYPE.PLAIN,
      onSurface: BUTTON_ON_SURFACE.DEFAULT,
      class: 'button-plain-red-default',
    },
    {
      color: BUTTON_COLOR.GREEN,
      visualType: BUTTON_TYPE.PLAIN,
      onSurface: BUTTON_ON_SURFACE.DEFAULT,
      class: 'button-plain-green-default',
    },
    {
      size: SIZES.LARGE,
      visualType: BUTTON_TYPE.PLAIN,
      class: 'px-0',
    },
    {
      size: SIZES.MEDIUM,
      visualType: BUTTON_TYPE.PLAIN,
      class: 'px-0',
    },
    {
      size: SIZES.SMALL,
      visualType: BUTTON_TYPE.PLAIN,
      class: 'px-0',
    },
    {
      size: SIZES.XSMALL,
      visualType: BUTTON_TYPE.PLAIN,
      class: 'px-0',
    },
  ],
});

const component = computed(() => (props.isLink ? 'RouterLink' : 'button'));
</script>
