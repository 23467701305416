import { defineStore } from 'pinia';
import { ref } from 'vue';

export const useWebsiteDomainStore = defineStore('useWebsiteDomainStore', () => {
  const state = ref<string | null>(null);

  const setDomain = (domain: string) => {
    state.value = domain;
  };

  const clearDomain = () => {
    state.value = null;
  };

  return {
    state,
    setDomain,
    clearDomain,
  };
});
