<template>
  <AuthPageWrapper data-testid="auth-login">
    <template #title> Welcome to Editorial </template>

    <form
      class="flex flex-col grow"
      @submit="onSubmit"
    >
      <FormInput
        v-model="email"
        class="mb-4"
        name="email"
        type="email"
        :is-errored="isEmailErrored"
        :attrs="emailAttrs"
      >
        <template #label> Email address </template>

        <template #prefix>
          <EmailIcon class="absolute z-10 top-2/4 -mt-3 text-imperium-fg-muted" />
        </template>

        <template #error>
          {{ errors.email }}
        </template>
      </FormInput>

      <FormPasswordInput
        :model-value="password"
        :is-errored="isPasswordErrored"
        :attrs="passwordAttrs"
        name="password"
        @update:model-value="onPasswordUpdate"
      >
        <template #label> Password </template>

        <template #icon>
          <LockIcon class="absolute z-10 top-2/4 -mt-3 text-imperium-fg-muted" />
        </template>

        <template #error>
          {{ errors.password }}
        </template>
      </FormPasswordInput>

      <div class="mt-3 mb-8 flex flex-col gap-4 xs:gap-0 xs:flex-row justify-between items-start xs:items-center">
        <Button
          class="ml-auto"
          :visual-type="BUTTON_TYPE.PLAIN"
          data-testid="button-forgot"
          :is-link="true"
          to="/auth/forgot-password"
        >
          Forgot password
        </Button>
      </div>

      <div class="mt-auto">
        <Button
          type="submit"
          data-testid="button-login"
          :is-loading="isLoading"
          is-full-width
        >
          Log In
        </Button>
      </div>
    </form>
  </AuthPageWrapper>
</template>

<script setup lang="ts">
import zod from 'zod';
import { computed, onMounted, onBeforeMount, watchEffect, watch, ref } from 'vue';
import { useRouter } from 'vue-router';
import { toTypedSchema } from '@vee-validate/zod';

import Button from '@/components/Button.vue';
import FormInput from '@/components/FormInput.vue';
import FormPasswordInput from '@/components/FormPasswordInput.vue';
import EmailIcon from '@/assets/icons/letter.svg?component';
import LockIcon from '@/assets/icons/lock.svg?component';

import { useSimpleAction, useToast } from '@/composables';

import AuthPageWrapper from '../components/AuthPageWrapper.vue';
import { AuthService, CookieStoreService } from '../service';
import { useFormData } from '@/composables/useFormData';
import { type AuthLoginPayload, AUTH_ERROR_CODES } from '../types';
import { BUTTON_TYPE } from '@/types';
import type { GenericError } from '@/types/exceptions';
import { ERROR_CODE_TO_MESSAGE } from '../constants';
import { useErrorHandling } from '@/composables/useErrorHandling';
import { useTwoFactorAuthStore } from '@/features/Auth/stores/twoFactorAuthStore';

const router = useRouter();
const twoFAStore = useTwoFactorAuthStore();
const toast = useToast();

const isOffline = ref<boolean>(false);

const { defineField, errors, meta, handleSubmit, values, setFieldError } = useFormData({
  data: {
    email: '',
    password: '',
  },
  validator: toTypedSchema(
    zod.object({
      email: zod.string().email('Enter a valid email address'),
      password: zod
        .string({
          required_error: 'Enter your password',
        })
        .min(1, { message: 'Enter your password' }),
    }),
  ),
});

const [email, emailAttrs] = defineField('email');
const [password, passwordAttrs] = defineField('password');

const onPasswordUpdate = (value: string) => {
  password.value = value;
};

const isEmailErrored = computed(() => meta.value.touched && !!errors.value.email);
const isPasswordErrored = computed(() => !!errors.value.password);

const { handleError } = useErrorHandling({
  list: AUTH_ERROR_CODES,
  messages: ERROR_CODE_TO_MESSAGE,
  callback: async (message, code) => {
    if (code === AUTH_ERROR_CODES.ACCESS_DENIED) {
      router.replace({
        name: 'UserTemporarilyBlocked',
      });
      return;
    }

    if (code === AUTH_ERROR_CODES.JWT_REFRESH_FAILED) {
      setFieldError('email', 'Something went wrong, please try again');
      return;
    }

    if (code === AUTH_ERROR_CODES['2FA_REQUIRED']) {
      const twoFAData = await AuthService.twoFACheck();

      if (!twoFAData?.qrCode || !twoFAData?.backupCodes) {
        router.replace({ path: '/auth/2fa-code' });
      } else {
        twoFAStore.setState({
          codes: twoFAData.backupCodes,
          qr: twoFAData.qrCode,
        });

        router.replace({ path: '/auth/2fa' });
      }
    }

    setFieldError('email', message);
  },
});

const onSubmit = handleSubmit((values: AuthLoginPayload) => action(values));
const { isLoading, action } = useSimpleAction(async (payload: AuthLoginPayload) => {
  try {
    // remove PHPSESSID before login - tell to backend
    await AuthService.login(payload);

    router.replace({ path: '/' });
  } catch (e: unknown) {
    handleError(e as GenericError);
  }
});

onMounted(async () => {
  await AuthService.logout(false);
});

onBeforeMount(() => {
  // Hack for comfortable moving to JWT - remove 01.12.2024
  const accessTokenName = 'accessToken';
  const refreshTokenName = 'refreshToken';
  const oldAccessTokenName = '__jwt';
  const oldRefreshTokenName = '__refresh';
  const oldAccessTokenExpName = '__jwt-exp';
  const oldRefreshTokenExpName = '__refresh_token-exp';

  if (CookieStoreService.get(accessTokenName)) {
    CookieStoreService.remove(accessTokenName);
  }
  if (CookieStoreService.get(refreshTokenName)) {
    CookieStoreService.remove(refreshTokenName);
  }
  if (CookieStoreService.get(oldAccessTokenName)) {
    CookieStoreService.remove(oldAccessTokenName);
  }
  if (CookieStoreService.get(oldRefreshTokenName)) {
    CookieStoreService.remove(oldRefreshTokenName);
  }
  if (CookieStoreService.get(oldAccessTokenExpName)) {
    CookieStoreService.remove(oldAccessTokenExpName);
  }
  if (CookieStoreService.get(oldRefreshTokenExpName)) {
    CookieStoreService.remove(oldRefreshTokenExpName);
  }
});

watchEffect(() => {
  window.addEventListener('offline', () => {
    isOffline.value = true;
  });
});

watch(
  () => isOffline.value,
  (newVal) => {
    if (newVal) {
      toast.errorTemporary({
        id: 'ERROR_LOST_CONNECTION',
        title: 'Internet connection lost',
        message: 'Try to login later',
      });
    }
  },
);
</script>
