import axios, { type AxiosResponse, HttpStatusCode } from 'axios';
import * as Sentry from '@sentry/vue';
import { tickerApiGatewayUrl } from '@/config';

export interface CryptoCurrency {
  symbol: string;
  slug: string;
  name: string;
  type: string;
}

interface CryptoResponse {
  currencies: CryptoCurrency[];
}

export const fetchCryptoSymbols = async (): Promise<CryptoCurrency[]> => {
  try {
    const params = {
      currencyType: 'crypto',
    };
    const url = `${tickerApiGatewayUrl}/converter/currencies`;
    const response: AxiosResponse<CryptoResponse> = await axios.get(url, { params });

    if (response.status === HttpStatusCode.Ok && response.data?.currencies) {
      return response.data.currencies;
    }

    return [];
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};
