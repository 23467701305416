import { buildSchema } from '@cointelegraph/schema-of-prosemirror';
import { setEditingLinkAttributes } from './blocks/link';
import { setEditingImageAttributes } from './blocks/imagesDataHelper';
import { addListenersForLinksFocus } from './helpers/linkPreview';

export const schema = buildSchema({
  // @ts-expect-error types
  setEditingLinkAttributes,
  // @ts-expect-error types
  setEditingImageAttributes,
  // @ts-expect-error types
  addListenersForLinksFocus,
});
