<template>
  <div>
    <ModalHolder
      :is-visible="isLoading"
      :is-closable="false"
    >
      <div class="flex justify-center items-center flex-col cursor-progress">
        <div>
          <LoadingIcon class="text-imperium-bg-5 motion-safe:animate-spin-2s" />
        </div>
        <div class="px-9 text-center text-imperium-fg-strong">
          Searching for matching price index links, please wait...
        </div>
      </div>
    </ModalHolder>
    <ModalHolder
      :is-visible="!!result"
      :is-closable="true"
      @close="result = null"
    >
      <template #title> Price index links - summary </template>
      <AutoLinkModalsBody :result="result" />
    </ModalHolder>
  </div>
</template>
<script setup lang="ts">
import ModalHolder from '@/components/ModalHolder.vue';
import LoadingIcon from '@/assets/icons/loader-burst.svg?component';
import { useAutoLinkCryptos } from '@/features/EditorAddCryptoLinks/composables/auto-link-cryptos';
import { computed } from 'vue';
import { isEmpty } from 'lodash';
import AutoLinkModalsBody from '@/features/EditorAddCryptoLinks/components/AutoLinkModalsBody.vue';
const { isLoading, result } = useAutoLinkCryptos();

const emptyResult = computed(() => {
  return isEmpty(result);
});
</script>
