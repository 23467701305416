import { ref } from 'vue';
import { type CryptoCurrency, fetchCryptoSymbols } from '@/features/EditorAddCryptoLinks/queries';

export type CryptoChangedStatistic = Record<string, number>;
const minimumLoadingTime = 1000;

const isLoading = ref<boolean>(false);
const result = ref<CryptoChangedStatistic | null>();
const currencies = ref<CryptoCurrency[]>();
const startTime = ref();

export const useAutoLinkCryptos = () => {
  const startLoading = async () => {
    isLoading.value = true;
    startTime.value = Date.now(); // Record the start time

    currencies.value = await fetchCryptoSymbols();
  };
  const stopLoading = async () => {
    const elapsedTime = Date.now() - startTime.value;
    const remainingTime = minimumLoadingTime - elapsedTime;

    if (remainingTime > 0) {
      await new Promise((resolve) => setTimeout(resolve, remainingTime));
    }

    isLoading.value = false;
  };
  const showResult = (statistic: CryptoChangedStatistic) => {
    result.value = statistic;
  };
  const hideResult = () => {
    result.value = null;
  };

  return {
    currencies,
    isLoading,
    startLoading,
    stopLoading,
    result,
    showResult,
    hideResult,
  };
};
