<template>
  <TableRow
    :size="SIZES.MEDIUM"
    :striped="TABLE_STRIPED.STRIPED"
    :bordered="TABLE_BORDERED.BOTTOM"
  >
    <TableCell
      :size="SIZES.SMALL"
      :align="TABLE_ALIGN.RIGHT"
      :visual-type="TABLE_VISUAL_TYPE.SUBTLE"
      data-testid="user-id"
      :is-sticky="!isLargeDesktop"
      class="cursor-default"
    >
      {{ user.id }}
    </TableCell>

    <TableCell
      data-testid="user-info"
      :size="!isLargeDesktop ? SIZES.LARGE : SIZES.XLARGE"
      :scale="!isLargeDesktop ? TABLE_SCALE.DOUBLE : TABLE_SCALE.TRIPLE"
      :is-sticky="!isLargeDesktop"
      :sticky-offset-size="SIZES.SMALL"
    >
      <UserInfo
        :id="user.id"
        :avatar="user.avatar"
        :name="user.name"
        :surname="user.surname"
      />
    </TableCell>
    <TableCell
      data-testid="user-roles"
      is-full-width
    >
      <Tooltip
        :key="`roles-${user.id}`"
        name="roles"
        class="overflow-hidden"
        button-class="overflow-hidden"
      >
        <template #content>
          <div class="text-sm truncate text-imperium-fg-subtle block line-clamp-1">
            {{ rolesList }}
          </div>
        </template>
        <template #tooltip>
          {{ rolesList }}
        </template>
      </Tooltip>
    </TableCell>
    <TableCell
      data-testid="user-started-date"
      :size="SIZES.LARGE"
      :scale="TABLE_SCALE.DOUBLE"
    >
      <div class="text-sm text-imperium-fg-subtle cursor-default">
        {{ createdAt }}
      </div>
    </TableCell>
    <TableCell
      data-testid="user-status"
      :size="SIZES.MEDIUM"
      :scale="TABLE_SCALE.QUADRUPLE"
    >
      <UserStatus
        :status="user.status"
        :name="`status-${user.id}`"
      />
    </TableCell>
    <TableCell
      data-testid="user-languages"
      is-full-width
    >
      <Tooltip :name="`languages-${user.id}`">
        <template #content>
          <div class="text-sm max-w-[150px] truncate text-imperium-fg-subtle block line-clamp-1">
            {{ languagesList }}
          </div>
        </template>
        <template #tooltip>
          {{ languagesList }}
        </template>
      </Tooltip>
    </TableCell>
    <TableCell
      :size="SIZES.SMALL"
      :scale="TABLE_SCALE.TRIPLE"
      data-testid="user-actions"
    >
      <Dropdown
        :id="`user-row-${user.id}`"
        ref="menuDropdown"
        :placement="props.isLast || props.isPenult ? 'auto' : 'bottom-end'"
      >
        <template #button>
          <Button
            :visual-type="BUTTON_TYPE.GHOST"
            :size="SIZES.LARGE"
            type="button"
          >
            <template #rightIcon>
              <MenuDots class="w-4 h-4" />
            </template>
          </Button>
        </template>
        <template #menu>
          <UserMenu
            :user="props.user"
            @edit="onEdit"
            @restore="onRestore"
            @deactivate="onDeactivate"
            @re-invite="onReInvite"
            @reset-2fa="onReset2FA"
          />
        </template>
      </Dropdown>
    </TableCell>
  </TableRow>
</template>

<script setup lang="ts">
import { computed, ref, inject } from 'vue';
import MenuDots from '@/assets/icons/menu-dots.svg?component';
import UserInfo from '@/features/Users/components/UsersTable/UserRow/UserInfo.vue';
import Dropdown from '@/components/Dropdown.vue';
import UserStatus from '@/features/Users/components/UsersTable/UserRow/UserStatus.vue';
import UserMenu from '@/features/Users/components/UsersTable/UserRow/UserMenu.vue';
import Tooltip from '@/components/Tooltip.vue';
import type { User } from '@/features/Users/types';
import Button from '@/components/Button.vue';
import { TOTAL_LANGUAGES } from '@/constants';
import { TableRow, TableCell } from '@/components/Table';
import {
  BUTTON_TYPE,
  type Language,
  SIZES,
  TABLE_STRIPED,
  TABLE_BORDERED,
  TABLE_VISUAL_TYPE,
  TABLE_ALIGN,
  TABLE_SCALE,
  TABLE_VALIGN,
} from '@/types';
import { type Role } from '@/features/Roles/types';

const props = defineProps<{ user: User; isLast: boolean; isPenult: boolean }>();

const emits = defineEmits<{
  (event: 'edit'): void;
  (event: 'deactivate'): void;
  (event: 'restore'): void;
  (event: 're-invite'): void;
  (event: 'reset-2fa'): void;
}>();

const isTablet = inject<boolean>('isTablet');
const isLargeDesktop = inject<boolean>('isLargeDesktop');

const menuDropdown = ref();

const rolesList = computed(() => props.user.roles.map((value: Role) => value.name).join(', '));
const languagesList = computed(() => {
  if (props.user.languages.length === TOTAL_LANGUAGES) {
    return 'All languages';
  }

  return props.user.languages.map(({ title }: Language) => title).join(', ');
});

const createdAt = computed(() => {
  const date = new Date(props.user.createdAt);
  const month = date.toLocaleString('default', { month: 'short' });
  return `${month} ${date.getDate()}, ${date.getFullYear()}`;
});

const onEdit = () => {
  emits('edit');
  menuDropdown.value.close();
};

const onRestore = () => {
  emits('restore');
  menuDropdown.value.close();
};

const onDeactivate = () => {
  emits('deactivate');
  menuDropdown.value.close();
};

const onReInvite = () => {
  emits('re-invite');
  menuDropdown.value.close();
};

const onReset2FA = () => {
  emits('reset-2fa');
  menuDropdown.value.close();
};
</script>
