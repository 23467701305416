import { ref } from 'vue';
import { defineStore } from 'pinia';
import { useSimpleAction } from '@/composables';
import { UsersService } from '@/features/Users/service';
import { useToast } from '@/composables/useToast';
import type { User } from '@/features/Users/types';

interface UsersState {
  users: User[];
  editedUser: User;
}

export const useUsersPage = defineStore('usersPage', () => {
  const state = ref<UsersState>({
    users: [],
    editedUser: {},
  });

  const toast = useToast();

  const { isLoading: isUsersLoading, action } = useSimpleAction<any>(async ({ paginationStore, filtersStore }) => {
    try {
      const { items, limit, total, page } = await UsersService.users({
        page: paginationStore.state.page,
        itemsPerPage: paginationStore.state.limit,
        searchQuery: filtersStore.state.searchQuery,
        filters: filtersStore.state.filters,
        sort: filtersStore.state.sort,
      });

      state.value.users = items;
      paginationStore.setState({ page, limit, total });
    } catch (err) {
      console.error('error', err);
      throw err;
    }
  });

  const { isLoading: isEditedUserLoading, action: fetchEditingUser } = useSimpleAction(async (id: number) => {
    try {
      state.value.editedUser = await UsersService.fetch(id);
    } catch (err) {
      console.error(err);
      toast.errorTemporary({ id: 'ERROR_FETCH_USER_INFO', message: 'Cannot fetch user info. Please try later' });
      throw err;
    }
  });

  const cleanEditingUser = () => {
    state.value.editedUser = {};
  };

  return {
    isUsersLoading,
    fetchUsersList: action,
    isEditedUserLoading,
    fetchEditingUser,
    cleanEditingUser,
    state,
  };
});
