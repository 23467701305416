<template>
  <ul
    v-if="props.user?.id"
    class="text-base font-semibold text-gray-600"
  >
    <li>
      <div
        data-testid="user-actions-profile-settings-button"
        class="cursor-pointer min-w-full gap-2 inline-flex items-center px-2 py-2 hover:text-gray-800 hover:bg-gray-100 rounded-t-lg"
        :aria-disabled="!inviteUserEdit"
        @click="emits('edit')"
      >
        <ProfileCard class="w-5 h-5" />
        Profile settings
      </div>
    </li>
    <li v-if="isReInviteVisible">
      <div
        :data-testid="`user-actions-re-invite-button`"
        class="cursor-pointer min-w-full gap-2 inline-flex items-center px-2 py-2 hover:text-gray-800 hover:bg-gray-100"
        :class="{
          'rounded-t-lg': isReinvitLastElement,
        }"
        @click="emits('re-invite')"
      >
        <Leading class="w-5 h-5" />
        Re-invite user
      </div>
    </li>
    <li v-if="resetUser2FAPermission && !preventRestore2Fa">
      <div
        :data-testid="`user-actions-reset-2fa-button`"
        class="cursor-pointer min-w-full gap-2 inline-flex items-center px-2 py-2 hover:text-gray-800 hover:bg-gray-100"
        @click="emits('reset-2fa')"
      >
        <ArrowRotateLeft class="w-5 h-5" />
        Reset 2FA
      </div>
    </li>
    <li v-if="props.user.status === UserStatuses.ACTIVE">
      <div
        data-testid="user-actions-deactivate-button"
        class="cursor-pointer min-w-full gap-2 inline-flex items-center px-2 py-2 text-red-600 hover:text-red-500 hover:bg-gray-100 rounded-b-lg"
        @click="emits('deactivate')"
      >
        <Trash class="w-5 h-5" />
        Deactivate
      </div>
    </li>
    <li v-if="props.user.status === UserStatuses.DEACTIVATED">
      <div
        data-testid="`user-actions-deactivate-button"
        class="cursor-pointer min-w-full gap-2 inline-flex items-center px-2 py-2 hover:text-gray-800 hover:bg-gray-100 rounded-b-lg"
        @click="emits('restore')"
      >
        <Leading class="w-5 h-5" />
        Restore
      </div>
    </li>
  </ul>
</template>
<script lang="ts" setup>
import ProfileCard from '@/assets/icons/profile-card.svg?component';
import ArrowRotateLeft from '@/assets/icons/arrow-rotate-left.svg?component';
import Trash from '@/assets/icons/trash.svg?component';
import Leading from '@/assets/icons/leading.svg?component';
import { type User } from '@/features/Users/types';
import { UserStatuses } from '@/types';
import { computed } from 'vue';
import { useUserPermissions } from '@/stores/user.store';

const props = defineProps<{ user: User }>();

const { inviteUserView, inviteUserEdit, resetUser2FA: resetUser2FAPermission } = useUserPermissions();

const isReInviteVisible = computed<boolean>(() => {
  return (props.user.status === UserStatuses.INVITED || props.user.status === UserStatuses.EXPIRED) && inviteUserView;
});

const preventRestore2Fa = computed(
  () => props.user.status === UserStatuses.EXPIRED || props.user.status === UserStatuses.INVITED,
);

const emits = defineEmits<{
  (event: 'edit'): void;
  (event: 'deactivate'): void;
  (event: 're-invite'): void;
  (event: 'reset-2fa'): void;
  (event: 'restore'): void;
}>();

const isReinvitLastElement = computed(() => {
  return (
    !resetUser2FAPermission &&
    preventRestore2Fa &&
    props.user.status !== UserStatuses.ACTIVE &&
    props.user.status !== UserStatuses.DEACTIVATED
  );
});
</script>
