import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "20",
  height: "20",
  fill: "none",
  viewBox: "0 0 20 20"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "currentColor",
      "fill-rule": "evenodd",
      d: "M10 20c5.523 0 10-4.477 10-10S15.523 0 10 0 0 4.477 0 10s4.477 10 10 10m2.311-6.5h-1v-5a.5.5 0 0 0-.5-.5h-2.5a.5.5 0 0 0-.5.5V9a.5.5 0 0 0 .5.5h1v4h-1a.5.5 0 0 0-.5.5v.5a.5.5 0 0 0 .5.5h4a.5.5 0 0 0 .5-.5V14a.5.5 0 0 0-.5-.5M10 6.75a1.25 1.25 0 1 0 0-2.5 1.25 1.25 0 0 0 0 2.5",
      "clip-rule": "evenodd"
    }, null, -1)
  ])))
}
export default { render: render }