import { defineStore } from 'pinia';
import { ref } from 'vue';
import type { Condition } from '@/features/Conditions/types';

interface ConditionsState {
  conditions: Condition[];
}
export const useConditionsStore = defineStore(
  'useConditionsStore',
  () => {
    const state = ref<ConditionsState>({
      conditions: [],
    });

    const setConditions = (conditions: Condition[]) => {
      state.value.conditions = conditions;
    };

    const clearConditions = () => {
      state.value.conditions = [];
    };

    return {
      state,
      clearConditions,
      setConditions,
    };
  },
  { persist: true },
);
