import { defineStore } from 'pinia';
import { ref } from 'vue';
import type { Language } from '@/types';

const DEFAULT_LANGUAGE = 'en';

export const useLanguagesStore = defineStore(
  'useLanguagesStore',
  () => {
    const state = ref<Language[] | null>();

    const setLanguages = (languages: Language[]): void => {
      state.value = languages;
    };

    const clearLanguages = (): void => {
      state.value = null;
    };

    const getDefaultLanguage = (): Language | null => {
      if (!state.value) return null;

      // If user has only one lang, then we use it
      if (state.value.length === 1) return state.value[0];

      return state.value.find(({ code }) => code === DEFAULT_LANGUAGE) || null;
    };

    return {
      state,
      setLanguages,
      clearLanguages,
      getDefaultLanguage,
    };
  },
  { persist: true },
);
