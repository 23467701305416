<template>
  <MediaTable
    is-choosing-media
    :is-limit-visible="false"
    @choose="(value) => emits('choose', value)"
  />
</template>
<script setup lang="ts">
import MediaTable from '@/features/Media/components/MediaTable.vue';

const emits = defineEmits<{
  (event: 'choose', value: Media): void;
}>();
</script>
