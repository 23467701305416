import { extractData, http } from '@/http';
import type {
  ImageSetAttributesRequest,
  ImageSetAttributesResponse,
  ImageUploadRequest,
  ImageUploadResponse,
} from '@/features/Media/types/api';
import type { ImageUsedAsCoverCounter } from '@/features/UploadImage/types';

export const imageSetAttributes = (
  id: number,
  patchBody: ImageSetAttributesRequest,
): Promise<ImageSetAttributesResponse> =>
  http.patch<ImageSetAttributesResponse>(`/gallery-images/${id}`, patchBody, { useAuth: true }).then(extractData);

export const imageUpload = (postBody: ImageUploadRequest): Promise<ImageUploadResponse> =>
  http
    .postForm<any>(`/gallery-images`, postBody, {
      useAuth: true,
    })
    .then(extractData);

export const checkImageUsedAsCover = (imageId: number): Promise<ImageUsedAsCoverCounter> =>
  http.get(`/gallery-images/${imageId}/used-as-cover`, { useAuth: true }).then(extractData);
