<template>
  <div>
    <div
      v-if="false"
      class="flex mt-0 mb-2 gap-4"
    >
      <label class="flex items-center cursor-pointer">
        <input
          id="overview"
          type="radio"
          value="overfiew"
          name="mode"
          class="w-4 h-4 text-imperium-ds-primary-base bg-imperium-bg-sub-base border-imperium-ds-primary-strong focus:ring-imperium-ds-primary-strong focus:ring-2"
          :class="$style.radio"
          checked
        />
        <span class="ms-2 text-sm font-medium text-gray-900"> Overview </span>
      </label>

      <label class="flex items-center cursor-pointer">
        <input
          id="assignments"
          type="radio"
          value="assignments"
          name="mode"
          class="w-4 h-4 text-imperium-ds-primary-base bg-imperium-bg-sub-base border-imperium-ds-primary-strong focus:ring-imperium-ds-primary-strong focus:ring-2"
          :class="$style.radio"
        />
        <span class="ms-2 text-sm font-medium text-gray-900"> My Assignments </span>
      </label>
    </div>

    <div
      v-if="isDashboardFull"
      class="relative"
    >
      <ChiefMessage @edit="reveal" />
    </div>

    <div
      v-if="isDashboardFull"
      class="mt-4"
    >
      <div class="flex flex-col xs:flex-row justify-between gap-x-2 gap-y-2 sm:gap-y-1 flex-wrap xl:flex-nowrap">
        <StatsBadge
          data-testid="dashboard-stats-visits-badge"
          class="grow basis-1/5"
        >
          <template #icon>
            <StatsIcon :theme="THEMES.SECONDARY">
              <EyeThinIcon class="w-5 h-5" />
            </StatsIcon>
          </template>

          {{
            t('dashboard.badges.visits', {
              count: '250K',
            })
          }}

          <template #time>
            {{ t('timeranges.last-days', { count: 7 }) }}
          </template>
        </StatsBadge>

        <StatsBadge
          data-testid="dashboard-stats-published-badge"
          class="grow basis-1/5"
        >
          <template #icon>
            <StatsIcon :theme="THEMES.SUCCESS">
              <FileSuccessIcon class="w-5 h-5" />
            </StatsIcon>
          </template>

          {{
            t('dashboard.badges.published', {
              count: '4',
            })
          }}

          <template #time>
            {{ t('timeranges.last-hours', { count: 24 }) }}
          </template>
        </StatsBadge>

        <StatsBadge
          data-testid="dashboard-stats-editing-badge"
          class="grow basis-1/5"
        >
          <template #icon>
            <StatsIcon :theme="THEMES.INFO">
              <FileSearchIcon class="w-5 h-5" />
            </StatsIcon>
          </template>

          {{
            t('dashboard.badges.editing', {
              count: '15',
            })
          }}

          <template #time>
            {{ t('timeranges.last-hours', { count: 24 }) }}
          </template>
        </StatsBadge>

        <div class="hidden sm:block xl:hidden w-full" />

        <StatsBadge
          data-testid="dashboard-stats-scheduled-badge"
          class="grow basis-1/5"
        >
          <template #icon>
            <StatsIcon :theme="THEMES.WARNING">
              <ClockArrowIcon class="w-5 h-5" />
            </StatsIcon>
          </template>

          {{
            t('dashboard.badges.scheduled', {
              count: '7',
            })
          }}

          <template #time>
            {{ t('timeranges.last-hours', { count: 24 }) }}
          </template>
        </StatsBadge>

        <StatsBadge
          data-testid="dashboard-stats-embargo-badge"
          class="grow basis-1/5"
        >
          <template #icon>
            <StatsIcon :theme="THEMES.DANGER">
              <DeniedIcon class="w-5 h-5" />
            </StatsIcon>
          </template>

          {{
            t('dashboard.badges.scheduled', {
              count: '1',
            })
          }}

          <template #time>
            {{ t('timeranges.last-hours', { count: 24 }) }}
          </template>
        </StatsBadge>
      </div>
    </div>

    <div class="mt-1 grid grid-flow-row 2xl:grid-flow-col auto-cols-fr 2xl:auto-cols-[1fr,_1fr] gap-4">
      <div class="flex flex-col gap-4">
        <StatsTablePublished
          :values="publishedArticles"
          :is-loading="isPublishedArticlesLoading"
          @redirect="redirectToArticle"
        />

        <StatsTableEmbargo
          :is-loading="isEmbargoArticlesLoading"
          :values="embargoArticles"
          @redirect="redirectToArticle"
        />
      </div>

      <div class="flex flex-col gap-4">
        <StatsTableScheduled
          :is-loading="isScheduledArticlesLoading"
          :values="scheduledArticles"
          @redirect="redirectToArticle"
        />

        <StatsTableEditing
          :values="editingArticles"
          :is-loading="isEditingArticlesLoading"
          @redirect="redirectToArticle"
        />

        <StatsTableActivity
          v-if="isDashboardFull"
          :values="ACTIVITY_TABLE"
        />

        <StatsTableAuthorsActivity
          v-if="isDashboardFull"
          :values="AUTHORS_TABLE"
        />
      </div>
    </div>

    <Teleport to="#modals">
      <CoworkersMessageModal
        :is-visible="isRevealed"
        @close="cancel"
        @cancel="cancel"
        @confirm="confirm(true)"
      />
    </Teleport>
  </div>
</template>

<script lang="ts" setup>
import { useI18n } from 'vue-i18n';

import { useModal } from '@/composables/useModal';

import StatsBadge from '@/features/Dashboard/components/StatsBadge.vue';
import StatsIcon from '@/features/Dashboard/components/StatsIcon.vue';
import ChiefMessage from '@/features/Dashboard/components/ChiefMessage.vue';
import CoworkersMessageModal from '@/features/Dashboard/components/CoworkersMessageModal.vue';

import StatsTablePublished from '@/features/Dashboard/components/StatsTablePublished.vue';
import StatsTableEditing from '@/features/Dashboard/components/StatsTableEditing.vue';
import StatsTableEmbargo from '@/features/Dashboard/components/StatsTableEmbargo.vue';
import StatsTableActivity from '@/features/Dashboard/components/StatsTableActivity.vue';
import StatsTableAuthorsActivity from '@/features/Dashboard/components/StatsTableAuthorsActivity.vue';
import StatsTableScheduled from '@/features/Dashboard/components/StatsTableScheduled.vue';

import EyeThinIcon from '@/assets/icons/eye-thin.svg?component';
import FileSuccessIcon from '@/assets/icons/file-success.svg?component';
import FileSearchIcon from '@/assets/icons/file-search.svg?component';
import ClockArrowIcon from '@/assets/icons/clock-arrow.svg?component';
import DeniedIcon from '@/assets/icons/denied-circle.svg?component';

import { DocumentStatus, SortStatus, THEMES } from '@/types';

import { ACTIVITY_TABLE, AUTHORS_TABLE } from './mocks';
import { computed, inject } from 'vue';
import { useFilteringArticles } from '@/features/Dashboard/composables/useFilteringArticles';
import { useRouter } from 'vue-router';
import { getIds, useArticlesUpdatesListener } from '@/composables';
import type PointerEvent from 'happy-dom/lib/event/events/PointerEvent';
import { DEFAULT_LANGUAGE_ID } from '@/constants/queries';

const isDashboardFull = inject('isDashboardFull');
const { t } = useI18n();
const { reveal, cancel, confirm, isRevealed } = useModal();
const router = useRouter();

const redirectToArticle = (articleId: number, event: PointerEvent) => {
  if (event.metaKey || event.ctrlKey) {
    const routeData = router.resolve({ name: 'article', params: { id: articleId } });
    window.open(routeData.href, '_blank');
  } else {
    router.push({
      name: 'article',
      params: {
        id: articleId,
      },
    });
  }
};

const scheduledFilterDateFrom = computed(() => {
  return new Date(Date.now() - 86400 * 1000).toISOString();
});

const defaultArticlesParams = {
  languageId: DEFAULT_LANGUAGE_ID,
  'order[id]': SortStatus.DESC,
  itemsPerPage: 5,
};
const { data: publishedArticles, isLoading: isPublishedArticlesLoading } = useFilteringArticles({
  'filter[status]': [DocumentStatus.PUBLISHED],
  'filter[published][from]': new Date(Date.now() - 86400 * 1000).toISOString(),
  'filter[published][to]': new Date().toISOString(),
  ...defaultArticlesParams,
});

const { data: editingArticles, isLoading: isEditingArticlesLoading } = useFilteringArticles({
  'filter[status]': [DocumentStatus.READY_TO_REVIEW],
  ...defaultArticlesParams,
});

const { data: embargoArticles, isLoading: isEmbargoArticlesLoading } = useFilteringArticles({
  'filter[isEmbargoed]': true,
  ...defaultArticlesParams,
});

const { data: scheduledArticles, isLoading: isScheduledArticlesLoading } = useFilteringArticles({
  'filter[published][from]': scheduledFilterDateFrom,
  'filter[status]': [DocumentStatus.PUBLISHED],
  ...defaultArticlesParams,
});

const allArticleIds = computed(() => {
  return getIds(
    ...(publishedArticles.value?.items || []),
    ...(editingArticles.value?.items || []),
    ...(embargoArticles.value?.items || []),
    ...(scheduledArticles.value?.items || []),
  );
});

useArticlesUpdatesListener({ articleIds: allArticleIds });
</script>

<style lang="scss" module>
.radio {
  &:checked {
    background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='black' xmlns='http://www.w3.org/2000/svg'%3e%3ccircle cx='8' cy='8' r='3'/%3e%3c/svg%3e");
  }
}
</style>
