<template>
  <div class="flex relative">
    <Button
      v-bind="$attrs"
      :rounded="isDropdownShown ? BUTTON_ROUNDED.LEFT_LARGE : BUTTON_ROUNDED.LARGE"
      @click="emits('click')"
    >
      <slot />
    </Button>
    <Dropdown
      v-if="isDropdownShown"
      ref="dropdownRef"
      :id="props.id"
      width="100%"
      placement="bottom-end"
      class="flex"
    >
      <template #button>
        <Button
          v-bind="$attrs"
          :rounded="BUTTON_ROUNDED.RIGHT_LARGE"
          class="h-full border-l border-imperium-border-clear"
          @click="emits('click-dropdown')"
        >
          <ArrowDownIcon class="w-5 h-5 text-imperium-fg-base" />
        </Button>
      </template>
      <template #menu>
        <slot name="menu" />
      </template>
    </Dropdown>
  </div>
</template>

<script setup lang="ts">
import { ref, watch } from 'vue';
import { uniqueId } from 'lodash';
import { BUTTON_ROUNDED } from '@/types';
import Button from '@/components/Button.vue';
import Dropdown from '@/components/Dropdown.vue';
import ArrowDownIcon from '@/assets/icons/arrow-down.svg?component';

// TODO: Consider adding this button to storybook

const emits = defineEmits<{
  (event: 'click'): void;
  (event: 'click-dropdown'): void;
}>();

const props = withDefaults(
  defineProps<{
    id?: string;
    isDropdownShown?: boolean;
    isDropdownOpened?: boolean;
  }>(),
  {
    id: uniqueId(),
    isDropdownShown: false,
    isDropdownOpened: false,
  },
);

const dropdownRef = ref<InstanceType<typeof Dropdown> | null>(null);

watch(
  () => props.isDropdownOpened,
  (newValue: boolean) => {
    if (!newValue) {
      dropdownRef.value?.close();
    }
  },
);
</script>
