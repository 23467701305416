import 'vanillajs-datepicker/css/datepicker.css';
import '@/assets/toast.scss';
import '@/assets/main.scss';
import '@/assets/prosemirror.scss';
import 'eventsource/example/eventsource-polyfill';
import 'flowbite';

import { createApp } from 'vue';
import { createPinia } from 'pinia';
import { createSentryPiniaPlugin } from '@sentry/vue';

import piniaPluginPersistedstate from 'pinia-plugin-persistedstate';
import { VueQueryPlugin } from '@tanstack/vue-query';
import Toast from 'vue-toastification';

import App from './App.vue';

import { i18n, setI18nLanguage } from '@/i18n';
import { router } from '@/router';
import { initSentry } from '@/plugins/sentry';
import { sentryDsn } from './config';
import { gtmId } from './config';

import { initAuthErrorsHandling } from '@/features/Auth/errors';
import { toastConfig } from './plugins/toast';

import { createGtm } from '@gtm-support/vue-gtm';

const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);
pinia.use(createSentryPiniaPlugin());
const app = createApp(App);

app.use(pinia);
app.use(i18n);
app.use(VueQueryPlugin);
app.use(Toast, toastConfig);

if (sentryDsn) {
  initSentry(app, router);
}

// XXX: For now only one language, but lang switcher can be added further
setI18nLanguage(i18n, 'en');

app.use(router);

router.isReady().then(() => {
  initAuthErrorsHandling(router);

  app.mount('#app');
});

if (gtmId && gtmId !== 'GTM-000000') {
  const gtm = createGtm({
    id: gtmId,
    vueRouter: router,
    trackViewEventProperty: 'page-load',
    defer: false,
    debug: false,
    loadScript: true,
    trackOnNextTick: false,
  });
  app.use(gtm);
} else {
  console.warn('GTM is disabled or using a fake ID in development.');

  // Mock GTM behavior in development
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push = function (event) {
    console.log('GTM Event (Mocked):', event);
    return this.length; // Return the new length of the array
  };
}
