import { computed } from 'vue';
import { useFetchUsers } from '@/features/Users/queries';
import { type UsersFetchPayload } from '@/features/Users/types';
import { mapUsersToSelectItem } from '@/features/Users/utils';
import { ROLES } from '@/types';
import { useRolesStore } from '@/features/Roles/stores/roles.store';

export const useUsers = () => {
  const rolesStore = useRolesStore();

  const roles = computed(() => rolesStore.state);

  const getWriterRoleId =
    computed(() => roles?.value?.items?.find((role) => role.slug === ROLES.ROLE_WRITER).id) ?? null;
  const getSeniorWriterRoleId =
    computed(() => roles?.value?.items?.find((role) => role.slug === ROLES.ROLE_SENIOR_WRITER).id) ?? null;
  const getEditorRoleId =
    computed(() => roles?.value?.items?.find((role) => role.slug === ROLES.ROLE_EDITOR).id) ?? null;
  const getCopyEditorRoleId =
    computed(() => roles?.value?.items?.find((role) => role.slug === ROLES.ROLE_COPY_EDITOR).id) ?? null;
  const getArtEditorRoleId =
    computed(() => roles?.value?.items?.find((role) => role.slug === ROLES.ROLE_ART_EDITOR).id) ?? null;
  const getEditorChiefRoleId =
    computed(() => roles?.value?.items?.find((role) => role.slug === ROLES.ROLE_EDITOR_IN_CHIEF).id) ?? null;
  const getSuperAdminRoleId =
    computed(() => roles?.value?.items?.find((role) => role.slug === ROLES.ROLE_SUPER_ADMIN).id) ?? null;

  const fetchUserParams = computed<UsersFetchPayload>(() => ({
    page: 1,
    itemsPerPage: 1000,
    filters: {
      roles: [
        getCopyEditorRoleId.value,
        getWriterRoleId.value,
        getEditorRoleId.value,
        getSeniorWriterRoleId.value,
        getArtEditorRoleId.value,
        getEditorChiefRoleId.value,
        getSuperAdminRoleId.value,
      ],
    },
  }));
  const { data: result } = useFetchUsers(fetchUserParams);

  const users = computed(() => {
    return mapUsersToSelectItem(result.value?.items) || [];
  });

  return {
    users,
  };
};
