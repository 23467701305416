<template>
  <div class="flex space-x-4 w-fit">
    <div
      v-for="column in columns"
      :key="column.name"
      class="overflow-hidden art-editor-dashboard__wrapper"
    >
      <ArtEditorColumn
        :is-loading="isLoading[column.name]"
        :title="column.title"
        :total-count="dashboardStore.state[column.name]?.total"
        :values="dashboardStore.state[column.name].items"
        @load-more="loadMoreItems(column.name)"
      />
    </div>
  </div>
</template>
<script lang="ts" setup>
import ArtEditorColumn from '@/features/Dashboard/components/ArtEditorColumn.vue';
import { ArtEditorDashboardTable } from '@/features/Dashboard/types';
import { useDashboardStore } from '@/features/Dashboard/stores/dashboardStore';
import { onMounted, ref } from 'vue';
import { fetchArticles } from '@/features/Articles/api';
import { useToast } from '@/composables/useToast';
import { PromiseValues, SortStatus } from '@/types';

const ART_DASHBOARD_ITEMS_PER_PAGE = 50;

const dashboardStore = useDashboardStore();
const toast = useToast();

const isLoading = ref<Record<ArtEditorDashboardTable, boolean>>({
  [ArtEditorDashboardTable['10MIN']]: false,
  [ArtEditorDashboardTable['30MIN']]: false,
  [ArtEditorDashboardTable['24HOURS']]: false,
  [ArtEditorDashboardTable['PENDING_APPROVAL']]: false,
  [ArtEditorDashboardTable['PUBLISHED']]: false,
  [ArtEditorDashboardTable['ALL_ARTICLES']]: false,
});

const columns = [
  {
    title: '5 - 10 min',
    name: ArtEditorDashboardTable['10MIN'],
  },
  {
    title: '30 min',
    name: ArtEditorDashboardTable['30MIN'],
  },
  {
    title: '24 hours',
    name: ArtEditorDashboardTable['24HOURS'],
  },
  {
    title: 'Pending Approval',
    name: ArtEditorDashboardTable['PENDING_APPROVAL'],
  },
  {
    title: 'Published',
    name: ArtEditorDashboardTable['PUBLISHED'],
  },
  {
    title: 'All articles',
    name: ArtEditorDashboardTable['ALL_ARTICLES'],
  },
];

const loadMoreItems = async (columnName: ArtEditorDashboardTable) => {
  const newPage = dashboardStore.state[columnName].loadedPages + 1;
  const payload = {
    page: newPage,
    itemsPerPage: ART_DASHBOARD_ITEMS_PER_PAGE,
    pagination: true,
    languageId: 1,
    ...dashboardStore.state[columnName].filters,
    'order[id]': SortStatus.DESC,
  };

  isLoading.value[columnName] = true;
  try {
    const { items, total } = await fetchArticles(payload);

    dashboardStore.addDashboardArtEditorValues({ name: columnName, items, total });
  } catch (e) {
    toast.errorTemporary({ id: 'ERROR_FETCH_ART_DASHBOARD_INFO', message: 'Cannot fetch articles. Please try later' });
  } finally {
    isLoading.value[columnName] = false;
  }
};

onMounted(async () => {
  const payload = {
    page: 1,
    languageId: 1,
    itemsPerPage: ART_DASHBOARD_ITEMS_PER_PAGE,
    pagination: true,
    'order[id]': SortStatus.DESC,
  };

  Object.keys(ArtEditorDashboardTable).map((key) => {
    isLoading.value[ArtEditorDashboardTable[key]] = true;
  });
  const promisesPayloads = Object.keys(ArtEditorDashboardTable).map((key) => {
    const artEditorDashboardTableKey = ArtEditorDashboardTable[key];
    return {
      filters: dashboardStore.state[artEditorDashboardTableKey].filters,
      name: artEditorDashboardTableKey,
    };
  });

  try {
    const results = await Promise.allSettled(
      promisesPayloads.map((item) => fetchArticles({ ...payload, ...item.filters })),
    );

    results.forEach((result, index) => {
      const artEditorDashboardTableKey: ArtEditorDashboardTable = promisesPayloads[index].name;
      if (result.status === PromiseValues.FULFILLED) {
        dashboardStore.setDashboardArtEditorValues({
          name: artEditorDashboardTableKey,
          items: result.value.items,
          total: result.value.total,
        });
      } else {
        console.error(`Failed to fetch data for ${artEditorDashboardTableKey}:`, result.reason);
      }
    });
  } catch (error) {
    toast.errorTemporary({ id: 'ERROR_FETCH_ART_DASHBOARD_INFO', message: 'Cannot fetch articles. Please try later' });
  } finally {
    Object.keys(ArtEditorDashboardTable).map((key: ArtEditorDashboardTable) => {
      isLoading.value[ArtEditorDashboardTable[key]] = false;
    });
  }
});
</script>
<style lang="scss" scoped>
.art-editor-dashboard {
  &__wrapper {
    max-height: calc(100vh - 57px);
  }
}
</style>
