import type { NavigationGuard } from 'vue-router';
import { isAuthenticatedByStore } from '@/services/auth';

export const authGuard: NavigationGuard = async (to, from, next) => {
  const redirectedFrom = !isAuthenticatedByStore().value && to.redirectedFrom ? to.redirectedFrom.fullPath : null;

  const requiresGuest = to.matched.some((x) => x.meta.requiresGuest);

  if (requiresGuest && isAuthenticatedByStore().value) {
    next({ path: '/' });
    return;
  }

  if (!isAuthenticatedByStore().value && redirectedFrom) {
    sessionStorage.setItem('prevPath', redirectedFrom);
  }

  if (!to.meta.auth) {
    next();
    return;
  }

  if (!isAuthenticatedByStore().value) {
    next({ name: 'Login' });
    return;
  }

  if (isAuthenticatedByStore().value && sessionStorage.getItem('prevPath')) {
    next({ path: sessionStorage.getItem('prevPath') });
    sessionStorage.clear();
    return;
  } else if (isAuthenticatedByStore().value) {
    next();
    return;
  }

  next({ name: 'Login' });
  return;
};
