<template>
  <div>
    <Button
      :size="SIZES.MEDIUM"
      :is-full-width="props.isFullWidth"
      :is-disabled="!inviteUserEdit && !inviteUserCondition"
      data-testid="invite-user-button"
      @click="onInviteUserButtonClick"
    >
      <template #leftIcon>
        <Plus class="h-5 w-5" />
      </template>
      Invite user
    </Button>
    <Teleport to="#modals">
      <UserInvitationModal
        :is-visible="isRevealed"
        :roles-list="filteredRoles"
        :languages-list="languages || []"
        @close="cancel"
        @invite="onSuccessInvite"
      />
    </Teleport>
  </div>
</template>

<script lang="ts" setup>
import { SIZES, UserRoles } from '@/types';

import { useModal } from '@/composables/useModal';

import Button from '@/components/Button.vue';
import UserInvitationModal from '@/features/Users/components/UserInvitation/UserInvitationModal.vue';
import Plus from '@/assets/icons/plus.svg?component';
import { useUserFilters } from '@/features/Users/stores/filters.store';
import { usePagination } from '@/features/Users/stores/pagination.store';
import { useUsersPage } from '@/features/Users/stores/users.store';
import { computed } from 'vue';
import { useUserPermissions } from '@/stores/user.store';
import { useLanguagesStore } from '@/stores/languages.store';
import { useRolesStore } from '@/features/Roles/stores/roles.store';
import { useUserConditions } from '@/composables/useUserConditions.ts';

const props = withDefaults(
  defineProps<{
    isFullWidth: boolean;
  }>(),
  {
    isFullWidth: false,
  },
);

const { inviteUserEdit } = useUserPermissions();
const { inviteUserCondition } = useUserConditions();

const filtersStore = useUserFilters();
const paginationStore = usePagination();
const usersStore = useUsersPage();
const languagesStore = useLanguagesStore();
const rolesStore = useRolesStore();

const { reveal, cancel, isRevealed } = useModal();

const { inviteSuperAdminEdit } = useUserPermissions();
const { inviteSuperAdminCondition } = useUserConditions();

const roles = computed(() => rolesStore.state);

const filteredRoles = computed(() => {
  if (!inviteSuperAdminEdit && !inviteSuperAdminCondition) {
    return roles.value?.filter((role) => role.slug !== UserRoles.SUPER_ADMIN) ?? [];
  } else {
    return roles.value ?? [];
  }
});

const languages = computed(() => languagesStore.state);

const onInviteUserButtonClick = () => reveal();

const onSuccessInvite = () => {
  usersStore.fetchUsersList({ filtersStore, paginationStore });
  cancel();
};
</script>
