<template>
  <div
    ref="targetElement"
    :data-testid="`toast-${props.id}`"
    :class="toastClass({ theme: props.theme, isFullWidth: props.isFullWidth })"
    class="z-110 border border-black border-opacity-10"
    role="alert"
    style="z-index: 100"
  >
    <div class="flex">
      <div
        v-if="props.isImageVisible"
        :class="toastIcon({ theme: props.theme })"
      >
        <Component
          :is="icon"
          v-if="icon"
          class="w-5 h-5"
        />
      </div>
      <div class="ms-4">
        <span
          v-if="props.title"
          class="text-imperium-fg-strong text-sm font-semibold overflow-hidden text-ellipsis whitespace-pre-wrap break-words"
        >
          {{ props.title }}
        </span>
        <div
          v-if="props.message"
          class="text-sm font-thin text-imperium-fg-base"
        >
          {{ props.message }}
        </div>
        <div
          v-if="isContent"
          class="mt-3 text-imperium-fg-base"
        >
          <Button
            v-if="onClick && buttonText"
            :size="SIZES.SMALL"
            data-testid="button-cta"
            class="w-[214px]"
            is-full-width
            @click="onClick"
          >
            {{ buttonText }}
          </Button>
          <slot />
        </div>
      </div>
      <button
        v-if="props.isClosable"
        ref="triggerElement"
        :data-testid="`toast-close-button-${props.id}`"
        aria-label="Close"
        class="ms-auto p-2 -mx-0 -my-0 h-8 justify-center items-center flex-shrink-0 rounded-l hover:bg-imperium-bg-3"
        type="button"
        @click="close"
      >
        <span class="sr-only">Close</span>
        <Close class="h-4 w-4 text-imperium-fg-base" />
      </button>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { ref, computed, useSlots, type FunctionalComponent, onMounted } from 'vue';
import { tv } from 'tailwind-variants';
import type { DismissInterface, DismissOptions, InstanceOptions } from 'flowbite';
import { Dismiss } from 'flowbite';

import CloseCircle from '@/assets/icons/close-circle.svg?component';
import CheckCircle from '@/assets/icons/check-circle.svg?component';
import ExclamationCircle from '@/assets/icons/exclamation-circle.svg?component';
import InfoCircle from '@/assets/icons/info-circle.svg?component';
import Close from '@/assets/icons/close.svg?component';
import Button from '@/components/Button.vue';

import { SIZES, THEMES } from '@/types';

type ThemeType = THEMES.WARNING | THEMES.DANGER | THEMES.SUCCESS | THEMES.INFO;

const icons = {
  [THEMES.INFO]: InfoCircle,
  [THEMES.WARNING]: ExclamationCircle,
  [THEMES.DANGER]: CloseCircle,
  [THEMES.SUCCESS]: CheckCircle,
};

export interface ToastProps {
  id: string;
  message?: string;
  title?: string;
  theme?: ThemeType;
  isImageVisible?: boolean;
  isClosable?: boolean;
  isFullWidth?: boolean;
  buttonText?: string;
  onClick?: null | (() => void);
}

const props = withDefaults(defineProps<ToastProps>(), {
  title: '',
  theme: THEMES.SUCCESS,
  isImageVisible: true,
  isClosable: true,
  isFullWidth: false,
  buttonText: '',
  onClick: null,
});

const slots = useSlots();

const emits = defineEmits<{
  (event: 'close-toast'): void;
}>();

const icon = computed<FunctionalComponent>(() => {
  return icons[props.theme];
});

const isContent = computed(() => !!slots.default);

const toastClass = tv({
  base: 'w-full rounded-lg p-4',
  variants: {
    theme: {
      [THEMES.INFO]: 'bg-imperium-bg-1',
      [THEMES.WARNING]: 'bg-imperium-ds-primary-trace',
      [THEMES.DANGER]: 'bg-imperium-ds-red-trace',
      [THEMES.SUCCESS]: 'bg-imperium-ds-green-trace',
    },
    isFullWidth: {
      false: 'xs:max-w-xs',
    },
  },
});

const toastIcon = tv({
  base: 'p-1.5 rounded rounded-md h-8',
  variants: {
    theme: {
      [THEMES.INFO]: 'bg-imperium-bg-3 text-imperium-fg-base',
      [THEMES.SUCCESS]: 'bg-imperium-ds-green-base text-imperium-ds-base-white',
      [THEMES.DANGER]: 'bg-imperium-ds-red-base text-imperium-ds-base-white',
      [THEMES.WARNING]: 'bg-imperium-ds-primary-base text-imperium-ds-base-black',
    },
  },
});

const targetElement = ref<HTMLElement | null>(null);
const triggerElement = ref<HTMLElement | null>(null);

const options: DismissOptions = {
  transition: 'transition-opacity',
  duration: 10000,
  timing: 'ease-out',
};

const instanceOptions: InstanceOptions = {
  id: `target-element-${props.id}`,
  override: true,
};

let dismiss: DismissInterface | null;

onMounted(() => {
  dismiss = new Dismiss(targetElement.value, triggerElement.value, options, instanceOptions);
});

const close = () => {
  dismiss?.hide();
  emits('close-toast');
};
</script>
