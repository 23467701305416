<template>
  <div>
    <FormInput
      :name="`${props.name}`"
      :model-value="inputValue"
      placeholder="Not selected"
      is-readonly
      :size="size"
      @click="onSelectDate"
    >
      <template #suffix>
        <CalendarIcon
          v-if="!inputValue"
          class="text-imperium-fg-muted"
        />
        <Button
          v-else
          :visual-type="BUTTON_TYPE.INVISIBLE"
          @click="onClearRange"
        >
          <CloseIcon class="h-4 w-4 text-imperium-fg-muted"></CloseIcon>
        </Button>
      </template>
    </FormInput>
    <Teleport to="#modals">
      <ArticlesFilterDataPickerModal
        :model-value="rangeValue"
        :is-visible="isRevealed"
        @cancel="cancel"
        @update:model-value="onSetRange"
      />
    </Teleport>
  </div>
</template>
<script setup lang="ts">
import { computed, ref, watchEffect } from 'vue';
import { getFormattedDate } from '@/utils';

import { useModal } from '@/composables/useModal';
import ArticlesFilterDataPickerModal from '@/features/Articles/components/modals/ArticlesFilterDataPickerModal.vue';

import FormInput from '@/components/FormInput.vue';
import type { RangeValues } from '@/components/FormDatepicker/FormDatePickerRange.vue';

import { BUTTON_TYPE, SIZES } from '@/types';

import CalendarIcon from '@/assets/icons/calendar.svg?component';
import CloseIcon from '@/assets/icons/close.svg?component';

const { reveal, cancel, isRevealed } = useModal();

const props = withDefaults(
  defineProps<{
    name: string;
    modelValue?: RangeValues | null;
    size?: SIZES;
  }>(),
  {
    size: SIZES.SMALL,
  },
);

const rangeValue = ref<RangeValues | null>(null);

const emits = defineEmits<{
  (event: 'update:modelValue', value: RangeValues | null): void;
}>();

watchEffect(() => {
  rangeValue.value = props.modelValue;
});

const inputValue = computed(() => {
  let result = '';

  if (rangeValue.value) {
    if (rangeValue.value.start) {
      result += getFormattedDate(rangeValue.value.start);
    }

    if (rangeValue.value.start && rangeValue.value.end) {
      result += ' - ';
    }

    if (rangeValue.value.end) {
      result += getFormattedDate(rangeValue.value.end);
    }
  }

  return result;
});

const onSetRange = (values: RangeValues | null) => {
  emits('update:modelValue', values);
  rangeValue.value = values;
  cancel();
};

const onClearRange = () => {
  rangeValue.value = null;
  emits('update:modelValue', null);
};

const onSelectDate = () => reveal();
</script>
