import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "64",
  height: "64",
  fill: "none",
  viewBox: "0 0 64 64"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "currentColor",
      d: "M29.334 7.467c0-1.178.955-2.133 2.133-2.133h1.067c1.178 0 2.133.955 2.133 2.133v9.067a2.133 2.133 0 0 1-2.133 2.133h-1.067a2.133 2.133 0 0 1-2.133-2.134zm-21.867 27.2a2.133 2.133 0 0 1-2.133-2.134v-1.066c0-1.178.955-2.133 2.133-2.133h9.067c1.178 0 2.133.955 2.133 2.133v1.066a2.133 2.133 0 0 1-2.133 2.134zm49.067 0a2.133 2.133 0 0 0 2.133-2.134v-1.066a2.133 2.133 0 0 0-2.133-2.133h-9.067a2.133 2.133 0 0 0-2.133 2.133v1.066c0 1.179.955 2.134 2.133 2.134zM34.667 56.534a2.133 2.133 0 0 1-2.133 2.133h-1.067a2.133 2.133 0 0 1-2.133-2.134v-9.066c0-1.178.955-2.133 2.133-2.133h1.067c1.178 0 2.133.955 2.133 2.133zm12.796-43.767a2.133 2.133 0 0 1 3.017 0l.754.754a2.133 2.133 0 0 1 0 3.017l-6.411 6.411a2.133 2.133 0 0 1-3.017 0l-.754-.754a2.133 2.133 0 0 1 0-3.017zm-34.696 3.771a2.133 2.133 0 0 1 0-3.017l.755-.754a2.133 2.133 0 0 1 3.017 0l6.41 6.411a2.133 2.133 0 0 1 0 3.017l-.754.754a2.133 2.133 0 0 1-3.017 0zm34.696 34.696a2.133 2.133 0 0 0 3.017 0l.754-.755a2.133 2.133 0 0 0 0-3.017l-6.411-6.41a2.133 2.133 0 0 0-3.017 0l-.754.754a2.133 2.133 0 0 0 0 3.017zm-30.924 0a2.133 2.133 0 0 1-3.017 0l-.755-.755a2.133 2.133 0 0 1 0-3.017l6.411-6.41a2.133 2.133 0 0 1 3.017 0l.755.754a2.133 2.133 0 0 1 0 3.017z"
    }, null, -1)
  ])))
}
export default { render: render }