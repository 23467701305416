<template>
  <ModalHolder
    :is-visible="props.isVisible"
    :size="SIZES.MEDIUM"
    data-testid="insert-image-modal"
    @close="emits('close')"
  >
    <template #title>
      <div class="px-2 py-1">Insert image</div>
    </template>
    <div class="flex flex-col px-2 pb-2">
      <div v-if="imageLoadingState === ImageLoadingState.CHOOSE">
        <NavTabs
          :tabs="tabs"
          @choose="onChooseUploadView"
        />
        <div class="pt-4">
          <UploadImageFile
            v-if="uploadingViewType === ImageTabState.UPLOAD"
            @upload="(file: File) => uploadImageAction({ file })"
          />
          <UploadImageGallery
            v-if="uploadingViewType === ImageTabState.GALLERY"
            @choose="onInsertImage"
          />
          <UploadImageUrl
            v-if="uploadingViewType === ImageTabState.LINK"
            @upload="(url: string) => uploadImageAction({ url })"
          />
        </div>
      </div>
      <div v-if="imageLoadingState === ImageLoadingState.LOADING">
        <div class="h-[156px] flex flex-col items-center justify-center cursor-pointer">
          <LoaderIcon class="h-10 w-10" />
        </div>
      </div>
      <div v-if="imageLoadingState === ImageLoadingState.SET_ATTRIBUTES && mediaId">
        <UploadImageSetAttributes
          :media-id="mediaId"
          @close="() => onInsertImage(media)"
        />
      </div>
    </div>
  </ModalHolder>
</template>
<script lang="ts" setup>
import { SIZES } from '@/types';
import LoaderIcon from '@/assets/icons/spinner.svg?component';
import ModalHolder from '@/components/ModalHolder.vue';
import { ImageLoadingState, ImageTabState, type NavTab } from '@/features/UploadImage/types';
import UploadImageUrl from '@/features/UploadImage/components/UploadImageUrl.vue';
import NavTabs from '@/components/NavTabs.vue';
import UploadImageSetAttributes from '@/features/UploadImage/components/UploadImageSetAttributes.vue';
import UploadImageFile from '@/features/UploadImage/components/UploadImageFile.vue';
import { ref } from 'vue';
import { useSimpleAction, useToast } from '@/composables';
import { UploadImageService } from '@/features/UploadImage/service/uploadImage';
import UploadImageGallery from '@/features/UploadImage/components/UploadImageGallery.vue';
import type { ImageModalArgs, Media } from '@/features/Media/types';
import { useArticleStore } from '@/features/ArticleLayout/stores/article.store';

const props = withDefaults(
  defineProps<{
    isVisible?: boolean;
  }>(),
  {
    isVisible: false,
  },
);

const emits = defineEmits<{
  (event: 'close'): void;
  (event: 'insert', value: ImageModalArgs): void;
}>();

const tabs = ref<NavTab[]>([
  {
    id: ImageTabState.UPLOAD,
    title: 'New image',
  },
  {
    id: ImageTabState.GALLERY,
    title: 'Image gallery',
  },
  {
    id: ImageTabState.LINK,
    title: 'Insert link',
  },
]);

const uploadingViewType = ref<string>();
const imageLoadingState = ref<ImageLoadingState>(ImageLoadingState.CHOOSE);
const mediaId = ref<number | null>(null);
const media = ref<Media | null>(null);

const toast = useToast();

const { action: uploadImageAction } = useSimpleAction(async ({ url, file }: { url?: string; file?: File }) => {
  try {
    imageLoadingState.value = ImageLoadingState.LOADING;
    const postData: { url?: string; file?: File } = {};
    if (url) postData.url = url;
    if (file) postData.file = file;

    const result = await UploadImageService.imageUpload(postData);

    mediaId.value = result.id;
    media.value = result;

    imageLoadingState.value = ImageLoadingState.SET_ATTRIBUTES;
  } catch (err: any) {
    toast.errorTemporary({
      id: 'ERROR_MEDIA_URL',
      message: err.data?.errorMessage || 'Something went wrong. Please try later',
    });
    imageLoadingState.value = ImageLoadingState.CHOOSE;
    throw err;
  }
});

const onClose = () => {
  emits('close');
  imageLoadingState.value = ImageLoadingState.CHOOSE;
  mediaId.value = null;
  media.value = null;
};

const getImageAlt = () => {
  const { state } = useArticleStore();
  const tags: string[] = state.tags.map(({ label }) => label);
  return tags.join(', ') ?? '';
};

const onInsertImage = (mediaData: Media) => {
  if (mediaData?.id) {
    const alt = getImageAlt();
    emits('insert', { ...mediaData, alt });
  }
  onClose();
};

const onChooseUploadView = (id: string) => {
  uploadingViewType.value = id;
};
</script>
