/**
 * @see https://jira.cointelegraph.com/browse/CMS-1183
 */
import { Node as ProseMirrorNode, Schema } from 'prosemirror-model';
import { EditorState, Transaction } from 'prosemirror-state';
import { type CryptoCurrency } from '@/features/EditorAddCryptoLinks/queries';
import { hasMark } from '@/features/CollaborativeEditor/utils/commands';
import { type CryptoChangedStatistic } from '@/features/EditorAddCryptoLinks/composables/auto-link-cryptos';

const BITCOIN_SLUG = 'BTC';
const ETHERUIUM_SLUG = 'ETH';

const shortLink = new Set([BITCOIN_SLUG, ETHERUIUM_SLUG]);

const findCryptoBySymbol = (cryptoList: CryptoCurrency[], targetSymbol: string) => {
  const cleanedSymbol = targetSymbol.replace(/[()]/g, '').trim().toUpperCase();

  const result = cryptoList.find(({ symbol }) => symbol.toUpperCase() === cleanedSymbol);

  if (!result) {
    throw new Error('Cant find symbol: ' + targetSymbol);
  }

  return result;
};

type CryptoSymbolReplaceArgs = {
  schema: Schema;
  tr: Transaction;
  node: ProseMirrorNode;
  pos: number;
  token: string;
  url: string;
};
const findCryptoSymbolAndReplace = ({ schema, tr, node, pos, token, url }: CryptoSymbolReplaceArgs): number => {
  const validBefore = /[(]/; // Space or opening parenthesis
  const validAfter = /[)]/; // Closing parenthesis, punctuation, or space
  let cursorPos = 0;
  const textContent: string = node.text!;
  const tokenLength = token.length;
  let changesCount = 0;

  // Search through the entire text content
  while (cursorPos < textContent.length) {
    // Find the next occurrence of the token
    const foundPos = textContent.indexOf(token, cursorPos);

    // If the token is not found, exit the loop
    if (foundPos === -1) break;

    // Check for valid characters before and after the token
    const beforeChar = textContent[foundPos - 1] || ''; // Character before the token
    const afterChar = textContent[foundPos + tokenLength] || ''; // Character after the token

    const isValidBefore = validBefore.test(beforeChar) || beforeChar === '';
    const isValidAfter = validAfter.test(afterChar) || afterChar === '';

    // If the token is valid based on the surrounding characters
    if (isValidBefore && isValidAfter) {
      const startPos = foundPos;
      const endPos = foundPos + tokenLength;

      // Create the link mark
      const linkMark = schema.marks.a.create({ href: url });

      // Apply the link mark using `tr.addMark`
      tr.addMark(pos + startPos, pos + endPos, linkMark);

      // Update the change count for this token
      changesCount++;
    }

    // Move the cursor position forward to continue the search
    cursorPos = foundPos + tokenLength;
  }

  return changesCount;
};

const createUrl = (cryptoList: CryptoCurrency[], targetSymbol: string): string | undefined => {
  // Find crypto info for the cleaned token
  const cryptoInfo = findCryptoBySymbol(cryptoList, targetSymbol);
  if (!cryptoInfo || !cryptoInfo.slug) return; // Skip if no slug is found

  const slug = cryptoInfo.slug;
  const url = shortLink.has(targetSymbol) ? `/${slug}-price` : `/${slug}-price-index`;

  return url;
};

export function processCryptoSymbols(cryptoList: CryptoCurrency[]) {
  return (state: EditorState, dispatch?: (tr: Transaction) => void): CryptoChangedStatistic => {
    const { schema, doc } = state;
    const cryptoSymbols = cryptoList.map(({ symbol }) => symbol);

    const tr = state.tr;
    const changesCount: CryptoChangedStatistic = {};

    // Iterate over the document's text nodes and process each one
    doc.descendants((node: ProseMirrorNode, pos: number) => {
      if (!node.isText) return;
      // Already marked
      if (hasMark(node, schema.marks.a.name)) return;

      let currentPos: number = pos;
      const textContent: string = node.text!;

      cryptoSymbols.forEach((token: string): void => {
        // Skip if the token is not a cryptocurrency symbol
        if (!textContent.includes(token)) return;

        const url = createUrl(cryptoList, token);
        if (!url) throw new Error('Cant make url');

        const changedCount = findCryptoSymbolAndReplace({ schema, tr, node, pos, token, url });
        if (changedCount) {
          changesCount[token] = (changesCount[token] || 0) + changedCount;
        }

        // Move the current position forward by the token length + space
        currentPos += token.length + 1; // +1 accounts for the space after the token
      });
    });

    if (tr.docChanged && dispatch) {
      dispatch(tr);
    }

    return changesCount;
  };
}
