import { extractData, http } from '@/http';
import type { Domain } from '@/types/domain.ts';

export const fetchDomain = (params): Promise<Domain> =>
  http
    .get<Domain>(`/website/domain`, {
      params,
      useAuth: true,
    })
    .then(extractData);
