import type { Language, LanguagesFetchResponse } from '@/types/languages';
import { extractData, http } from '@/http';

export const languages = (params): Promise<Language[]> =>
  http
    .get<LanguagesFetchResponse>('/languages', {
      params,
      useAuth: true,
    })
    .then(extractData);
