import * as Sentry from '@sentry/vue';

export const getTwitterId = (url: string) => {
  if (!url) {
    return null;
  }
  const regex = /(twitter|x)\.com\/.*status(?:es)?\/([\d]+)/gi;
  const result = regex.exec(url) || [];
  const embedId = result.pop();
  if (!embedId) {
    return null;
  }
  return embedId;
};

export const getInstagramId = (url: string) => {
  if (!url) {
    return null;
  }

  const segments = url.split('/');
  const index = segments.findIndex((segment) => segment === 'p' || segment === 'reel' || segment === 'reels');

  const embedId = segments[index + 1];

  if (!embedId) {
    return null;
  }
  return embedId;
};

export type YoutubeParams = {
  embedId: string;
  startTime?: string;
};

export const getYoutubeParams = (url: string): YoutubeParams | null => {
  if (!url) {
    return null;
  }

  // Match the YouTube video ID and optional `t` parameter for start time
  const regex = /youtu(?:be\.com\/|\.be\/)(?:[-\w]+\?v=|embed\/|v\/)?([-\w]{11})(?:.*[?&]t=(\d+))?/i;
  const match = regex.exec(url);

  if (!match || !match[1]) {
    return null;
  }

  // Destructure embedId and startTime (if it exists)
  const embedId = match[1];
  const startTime = match[2] || undefined;

  return { embedId, startTime };
};

export const getYoutubeEmbedUrl = ({ embedId, startTime }: YoutubeParams): string | null => {
  if (!embedId) {
    return null;
  }

  let resultURL = `https://www.youtube.com/embed/${embedId}`;

  // Append start time if it's available
  if (startTime) {
    resultURL += `?start=${startTime}`;
  }

  return resultURL;
};

export const getVimeoId = (url: string) => {
  if (!url) {
    return null;
  }
  const regex = /vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/(?:[^/]*)\/videos\/|)(\d+)(?:|\/\?)/gi;
  const [, embedId] = regex.exec(url) || [];
  if (!embedId) {
    return null;
  }
  return embedId;
};

export type BuzzsprotParams = {
  podcastId: string;
  episodeId: string | null;
};

export const getBuzzsproutParams = (url: string): BuzzsprotParams | null => {
  /**
   * @example url https://www.buzzsprout.com/2274877/episodes/16308504-no-guarantee-bitcoin-21m-supply-cap-is-truly-fixed-says-blackrock-disclaimer?client_source=small_player&iframe=true
   * @example url https://www.buzzsprout.com/2072690?client_source=large_player&iframe=true
   * @example url https://www.buzzsprout.com/2072690/12318862?client_source=large_player&iframe=true
   */

  try {
    const parsedUrl = new URL(url);
    const pathSegments = parsedUrl.pathname.split('/').filter(Boolean);

    // The podcast ID is always the first segment, the episode ID (if present) is the second
    const podcastId = pathSegments[0];
    const episodeId = pathSegments[1] === 'episodes' ? pathSegments[2].split('-')[0] : pathSegments[1] || null;

    if (
      parsedUrl.hostname.endsWith('buzzsprout.com') &&
      Number.isSafeInteger(Number(podcastId)) &&
      (!episodeId || Number.isSafeInteger(Number(episodeId)))
    ) {
      return { podcastId, episodeId };
    }

    return null;
  } catch (error) {
    Sentry.captureException(error);
    return null;
  }
};

export const getBuzzsproutEmbedUrl = ({ podcastId, episodeId }: BuzzsprotParams) => {
  const domain = 'https://www.buzzsprout.com/';
  let baseUrl = `${domain}${podcastId}`;

  if (episodeId) {
    baseUrl += `/${episodeId}`;
  }

  const url = new URL(baseUrl);

  // Parameters as an object
  const params: Record<string, string> = {
    client_source: episodeId ? 'small_player' : 'large_player',
    iframe: 'true',
  };

  // Append each parameter to the URL
  Object.entries(params).forEach(([key, value]) => url.searchParams.append(key, value));

  return url.toString();
};
