import { DOMParser as ProseMirrorDOMParser } from 'prosemirror-model';
import { schema } from '@/features/CollaborativeEditor/schema'; // TODO: pass schema as param
import * as Sentry from '@sentry/vue';

export const htmlToProseMirrorDoc = (htmlString: string) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, 'text/html');
  const content = doc.body;

  // if (content.childNodes.length === 0) {
  //   Sentry.captureMessage('Invalid HTML string provided');
  //   throw new Error('Invalid HTML string provided');
  // }

  // Ensure each figure has a figcaption
  content.querySelectorAll('figure').forEach((figure) => {
    if (!figure.querySelector('figcaption')) {
      const figcaption = document.createElement('figcaption');
      figcaption.innerText = ' ';
      figure.appendChild(figcaption);
    }
  });

  // Return a ProseMirror document parsed from the content
  const parsedDoc = ProseMirrorDOMParser.fromSchema(schema).parse(content);

  if (!parsedDoc) {
    Sentry.captureMessage('Failed to parse HTML to ProseMirror document');
    throw new Error('Failed to parse HTML to ProseMirror document');
  }

  return parsedDoc;
};
