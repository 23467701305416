<template>
  <AuthPageWrapper
    v-show="!isCheckingTokenLoading"
    data-testid="auth-create-passwd"
  >
    <template #title> Reset password </template>

    <div class="mb-8 text-lg text-imperium-fg-muted">
      <p class="mb-2">
        Reset your password for the account
        <b class="text-imperium-fg-muted"> {{ user?.email }} </b>.
      </p>
      <p>
        Your new password must be at least 8 characters long and include a capital and special character to make it more
        secure
      </p>
    </div>

    <form
      class="flex flex-col grow"
      @submit="onSubmit"
    >
      <FormPasswordInput
        v-model="password"
        class="mb-4"
        :is-errored="isPasswordErrored"
        :attrs="passwordAttrs"
        name="password"
      >
        <template #label> Password </template>

        <template #icon>
          <LockIcon class="absolute z-10 top-2/4 -mt-3 text-imperium-fg-muted" />
        </template>

        <template #error>
          {{ errors.password }}
        </template>
      </FormPasswordInput>

      <FormPasswordInput
        v-model="confirm"
        class="mb-4"
        :is-errored="isConfirmErrored"
        :attrs="confirmAttrs"
        name="confirm"
      >
        <template #label> Confirm password </template>

        <template #icon>
          <LockIcon class="absolute z-10 top-2/4 -mt-3 text-imperium-fg-muted" />
        </template>

        <template #error>
          {{ errors.confirm }}
        </template>
      </FormPasswordInput>

      <div class="mt-auto">
        <Button
          type="submit"
          data-testid="button-create"
          :is-loading="isLoading"
          is-full-width
        >
          Create a new password
        </Button>
      </div>
    </form>
  </AuthPageWrapper>
</template>

<script setup lang="ts">
import zod from 'zod';
import { computed, ref, onMounted } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { toTypedSchema } from '@vee-validate/zod';

import Button from '@/components/Button.vue';
import FormPasswordInput from '@/components/FormPasswordInput.vue';
import LockIcon from '@/assets/icons/lock.svg?component';
import { useSimpleAction } from '@/composables';
import type { ValidateTokenPayload } from '@/features/Auth/types';

import AuthPageWrapper from '../components/AuthPageWrapper.vue';
import { useFormData } from '@/composables/useFormData';
import { useToast } from '@/composables/useToast';
import { AuthService } from '../service';
import { type AuthCreatePasswordPayload } from '../types';
import { passwordRegExp } from '@/features/Auth/constants/rules';
import type { User } from '@/features/Users/types';

const router = useRouter();
const route = useRoute();
const toast = useToast();

const user = ref<User | undefined>();

const { defineField, errors, meta, handleSubmit, setFieldError } = useFormData({
  data: {
    password: '',
    confirm: '',
  },
  validator: toTypedSchema(
    zod
      .object({
        password: zod
          .string({
            required_error: 'Create a new password',
          })
          .min(1, { message: 'Create a new password' })
          .regex(passwordRegExp, 'Password must be 8 characters long with 1 capital and 1 special character'),
        confirm: zod
          .string({
            required_error: 'Confirm a new password',
          })
          .min(1, { message: 'Confirm a new password' }),
      })
      .superRefine(({ password, confirm }, ctx) => {
        if (confirm !== password) {
          ctx.addIssue({
            path: ['confirm'],
            code: 'custom',
            message: "Passwords don't match",
          });
        }
      }),
  ),
});

const [password, passwordAttrs] = defineField('password');
const [confirm, confirmAttrs] = defineField('confirm');

const isPasswordErrored = computed(() => meta.value.touched && !!errors.value.password);
const isConfirmErrored = computed(() => meta.value.touched && !!errors.value.confirm);

const { isLoading: isCheckingTokenLoading, action: checkingTokenAction } = useSimpleAction(
  async (payload: ValidateTokenPayload) => {
    try {
      if (payload.token) {
        const data = await AuthService.validateToken(payload);

        user.value = data.user;
      } else {
        throw new Error('No token in url');
      }
    } catch (e: any) {
      toast.errorTemporary({
        id: 'ERROR_TOKEN_VALIDATION',
        title: 'Your token is expired or already used!',
        message: 'Please, fill this form one more time',
      });

      router.push({
        name: 'ForgotPassword',
      });
    }
  },
);

const onSubmit = handleSubmit((values: AuthCreatePasswordPayload) => {
  return action({
    ...values,
    resetToken: route.params.token,
  });
});

const { isLoading, action } = useSimpleAction(async (payload: AuthCreatePasswordPayload) => {
  try {
    await AuthService.createPassword(payload);

    router.replace({ name: 'Login' });
  } catch (e: any) {
    setFieldError('password', 'Something goes wrong');
  }
});

onMounted(() => {
  const token = route.params.token;

  checkingTokenAction({ token });
});
</script>
