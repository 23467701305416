<template>
  <aside
    class="fixed top-0 left-0 z-30 w-[234px] h-full pt-[64px] pb-4 transition-transform border-r rounded-ee-2xl border-gray-200 md:translate-x-0 bg-imperium-bg-4"
    :class="{
      'translate-x-0': props.isOpened,
      '-translate-x-full': !props.isOpened,
    }"
  >
    <nav
      class="h-full overflow-y-auto mt-2"
      :class="$style.sidebarMenu"
    >
      <ul
        role="list"
        class="font-semibold text-md flex flex-col h-full"
      >
        <li
          v-for="{ to, meta, name, children } in routes"
          :key="`menu-item-${name}`"
          :class="meta?.wrapperClass"
          class="group"
        >
          <SidebarLink
            v-if="!children"
            :to="to"
            :meta="meta"
            :name="name"
            :label="t(`sidebar.${name}`)"
          />
          <SidebarDropdown
            v-if="children"
            :name="name"
            :label="t(`sidebar.${name}`)"
            :meta="meta"
            :children="children"
          />
        </li>

        <li
          v-for="route in routesBottom"
          :key="route.to"
          :class="route.meta?.wrapperClass"
          class="group"
        >
          <button
            class="flex items-center p-2 rounded-lg hover:bg-gray-100 group w-full text-imperium-fg-base hover:bg-imperium-bg-3/50"
            :data-testid="`menu-item-${route.name}`"
            @click="onLinkClick(!!route.children)"
          >
            <component
              :is="route.meta.icon"
              v-if="route.meta?.icon"
              class="w-5 h-5"
            />

            <span class="ms-2 whitespace-nowrap">
              {{ t(`sidebar.${route.name}`) }}
            </span>
          </button>

          <ul
            v-if="route.children"
            class="group-hover:flex hidden py-2 px-3 font-semibold bottom-22 bg-white rounded-lg flex-col absolute border border-imperium-border-clear"
          >
            <li
              v-for="childLink in route.children"
              :key="childLink.to"
              :class="childLink.meta?.wrapperClass"
            >
              <a
                :href="childLink.to"
                target="_blank"
                :data-testid="`menu-item-nested-${childLink.name}`"
                class="flex items-center p-2 rounded-lg hover:bg-gray-100 group w-full"
                @click="emits('link-click')"
              >
                <component
                  :is="childLink.meta.icon"
                  v-if="childLink.meta?.icon"
                  class="w-5 h-5"
                />
                <span class="ms-2 whitespace-nowrap">
                  {{ t(`sidebar.${childLink.name}`) }}
                </span>
              </a>
            </li>
          </ul>
        </li>

        <li>
          <button
            type="button"
            class="flex items-center p-2 rounded-lg hover:bg-gray-100 group w-full text-imperium-fg-base"
            data-testid="menu-item-logout"
            @click="onLogout"
          >
            <Logout class="w-5 h-5" />
            <span class="ms-2 whitespace-nowrap">
              {{ t('sidebar.logout') }}
            </span>
          </button>
        </li>
      </ul>
    </nav>
  </aside>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n';

import Logout from '@/assets/icons/logout.svg?component';
import { AuthService } from '@/features/Auth/service';
import SidebarLink from '@/features/Sidebar/ui/SidebarLink.vue';
import SidebarDropdown from '@/features/Sidebar/ui/SidebarDropdown.vue';
import { useSidebarRoutes } from '@/features/Sidebar/model/useSidebarRoutes';

const props = withDefaults(
  defineProps<{
    isOpened?: boolean;
  }>(),
  {
    isOpened: false,
  },
);

const emits = defineEmits<{
  (event: 'link-click'): void;
}>();

const onLinkClick = (childLinks: boolean) => {
  if (childLinks) return;

  emits('link-click');
};

const { routes, routesBottom } = useSidebarRoutes();
const { t } = useI18n();

const onLogout = () => {
  AuthService.logout();
};
</script>
<style lang="scss" module>
.sidebarMenu {
  :global(ul li a.router-link-exact-active) {
    @apply text-imperium-fg-strong bg-imperium-bg-3;
  }
  :global(ul li a:not(.router-link-exact-active)) {
    @apply text-imperium-fg-base hover:bg-imperium-bg-3/50;
  }
}
</style>
