import { fetchDomain } from '@/api/domain.ts';
import { useWebsiteDomainStore } from '@/stores/domain.store.ts';

let linksElements: HTMLElement[] = [];

const isSelectionInsideElement = (element: HTMLElement) => {
  const selection = window.getSelection();
  if (selection?.rangeCount === 0) return false;
  const range = selection?.getRangeAt(0);
  return element.contains(range?.commonAncestorContainer || null);
};

export const onShowLinkToast = ({ href, title }: { href: string; title: string }) => {
  if (!href) {
    return;
  }
  const domainStore = useWebsiteDomainStore();
  const domain = domainStore?.state ?? 'cointelegraph.com';

  const linkPreviewElement = document.getElementById('collaborative-editor-link-preview');
  const previewTitleElement = document.getElementById('collaborative-editor-link-preview-text');
  const previewLinkElement = document.getElementById('collaborative-editor-link-preview-link');

  if (linkPreviewElement && linkPreviewElement.classList.contains('hidden')) {
    linkPreviewElement.classList.remove('hidden');
  }

  if (previewTitleElement && previewLinkElement) {
    if (href.startsWith('/') && title.startsWith('/')) {
      previewTitleElement.innerHTML = (title ? `https://${domain}${title}` : href) || 'No title';
      previewLinkElement.setAttribute('href', `https://${domain}${title}`);
    } else {
      previewTitleElement.innerHTML = (title ? title : href) || 'No title';
      previewLinkElement.setAttribute('href', href as string);
    }
  }
};

export const onHideLinkToast = () => {
  const linkPreviewElement = document.getElementById('collaborative-editor-link-preview');
  if (linkPreviewElement && !linkPreviewElement.classList.contains('hidden')) {
    linkPreviewElement.classList.add('hidden');
  }
};

const handleSelectionChange = () => {
  const anyLinkInFocus = linksElements.some(isSelectionInsideElement);

  if (!anyLinkInFocus) {
    onHideLinkToast();
  }
};

const handleCursor = (linkEl: HTMLElement) => {
  const href = linkEl?.getAttribute('href') || '';
  const title = linkEl?.getAttribute('title') || '';

  onShowLinkToast({ href, title });
};

export const addListenerForLinksBlur = () => {
  document.addEventListener('selectionchange', handleSelectionChange);
};

export const removeListenerForLinksBlur = () => {
  document.removeEventListener('selectionchange', handleSelectionChange);
};

export const addListenersForLinksFocus = (linkElement: HTMLElement) => {
  linkElement.addEventListener('mouseup', handleCursor.bind(this, linkElement));
  linkElement.addEventListener('keyup', handleCursor.bind(this, linkElement));

  linksElements.push(linkElement);
};

export const removeListenersForLinksFocus = (innerText: string) => {
  const linkElement = linksElements.find((item) => item.innerText.includes(innerText));

  if (linkElement) {
    linkElement.removeEventListener('mouseup', handleCursor.bind(this, linkElement));
    linkElement.removeEventListener('keyup', handleCursor.bind(this, linkElement));

    linksElements = linksElements.filter((item: HTMLElement) => item !== linkElement);
  }
};
