import Sponsored from '@/assets/icons/editor/disclaimer/sponsored.svg?raw';
import Interview from '@/assets/icons/editor/disclaimer/interview.svg?raw';
import Contribution from '@/assets/icons/editor/disclaimer/contribution.svg?raw';
import Update from '@/assets/icons/editor/disclaimer/update.svg?raw';
import Developing from '@/assets/icons/editor/disclaimer/developing.svg?raw';
import Consulting from '@/assets/icons/editor/disclaimer/consulting.svg?raw';
import Views from '@/assets/icons/editor/disclaimer/views.svg?raw';
import Investment from '@/assets/icons/editor/disclaimer/investment.svg?raw';

import type { NodeType } from 'prosemirror-model';
import type { BlocksResult, InstallableBlock } from '@/features/CollaborativeEditor/types';

import { wrapItem } from '@/features/CollaborativeEditor/plugins/menu';
import { checkIfNodeIn, disableMenuItem } from '@/features/CollaborativeEditor/utils/state';
import { useToast } from '@/composables';

const getIconFrom = (rawSvg: string): Element => {
  return rawSvg;
};

const FORMS = [
  {
    type: 'sponsored',
    key: 'Sponsored',
    label: 'Sponsored',
    description:
      'Cointelegraph does not endorse the content of this article nor any product mentioned herein. Readers should do their own research before taking any action related to any product or company mentioned and carry full responsibility for their decisions.',
    icon: getIconFrom(Sponsored),
  },
  {
    type: 'interview',
    key: 'Interview',
    label: 'Interview',
    description: 'This interview has been edited and condensed.',
    icon: getIconFrom(Interview),
  },
  {
    type: 'contribution',
    key: 'Contribution',
    label: 'Contribution',
    description: 'Additional reporting by [Name].',
    icon: getIconFrom(Contribution),
  },
  {
    type: 'update',
    key: 'Update',
    label: 'Update',
    description:
      'Update (date and time in UTC): This article has been updated to [insert the new info being presented.]',
    icon: getIconFrom(Update),
  },
  {
    type: 'developing',
    key: 'Developing',
    label: 'Developing',
    description: 'This is a developing story, and further information will be added as it becomes available.',
    icon: getIconFrom(Developing),
  },
  {
    type: 'consulting',
    key: 'Consulting',
    label: 'Consulting',
    description:
      'The opinions expressed in this article are for general informational purposes only and are not intended to provide specific advice or recommendations for any individual or on any specific security or investment product.',
    icon: getIconFrom(Consulting),
  },
  {
    type: 'vc_consulting',
    key: 'VCConsulting',
    label: 'VC Consulting',
    description:
      'This article pulls from Cointelegraph Research Terminal’s expansive Venture Capital Database. This article is for information purposes only and represents neither investment advice, investment analysis, nor an invitation to buy or sell financial instruments. Specifically, it is not a substitute for individual investment or other advice.',
    icon: getIconFrom(Consulting),
  },
  {
    type: 'views',
    key: 'Views',
    label: 'Views, thoughts, opinions',
    description:
      'This article is for general information purposes and is not intended to be and should not be taken as legal or investment advice. The views, thoughts, and opinions expressed here are the author’s alone and do not necessarily reflect or represent the views and opinions of Cointelegraph.',
    icon: getIconFrom(Views),
  },
  {
    type: 'investment',
    key: 'Investment',
    label: 'Investment advice',
    description:
      'This article does not contain investment advice or recommendations. Every investment and trading move involves risk, and readers should conduct their own research when making a decision.',
    icon: getIconFrom(Investment),
  },
];

const render = (icon: Element, label: string, description: string): HTMLElement => {
  const template = `<div>
    <div class="disclaimer-form-item">
      <div class="disclaimer-form-item__icon">
        ${icon}
      </div>

      <div>
        <div>
          ${label}
        </div>

        <div class="disclaimer-description">${description}</div>
      </div>
    </div>
  </div>`;

  return new DOMParser().parseFromString(template, 'text/html').body.firstElementChild as HTMLElement;
};

const toast = useToast();
export const buildDisclaimer = (node: NodeType): InstallableBlock[] => {
  return FORMS.map((formData) => ({
    key: `disclaimer${formData.key}` as keyof BlocksResult,
    item: wrapItem(node, {
      run(state, _, view) {
        const { dispatch } = view;
        const { schema, selection } = state;
        const { from } = selection;

        const disclaimerNode = schema.nodes.disclaimer.create(null, schema.text(formData.description));

        const transaction = state.tr.insert(from, disclaimerNode);

        // Prevent from pasting disclaimer into img figcaption
        if (!disableMenuItem(state.selection.$head!.path, state.selection?.node?.type?.name)) {
          toast.errorTemporary({
            id: 'ERROR_DUPLICATED_WIDGET',
            message: '"Disclaimer" cannot be inserted in this spot. Move your cursor to an empty space.',
          });
          return;
        }
        dispatch(transaction);
      },
      render: () => {
        return render(formData.icon, formData.label, formData.description);
      },
    }),
  }));
};
