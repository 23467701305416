import {
  login,
  resetPassword,
  createPassword,
  fetchUser,
  userInvitationConfirm,
  validateToken,
  twoFACheck,
  twoFASet,
  logout,
} from '../api/auth';
import type {
  AuthLoginPayload,
  AuthResetPasswordPayload,
  AuthCreatePasswordPayload,
  UserInvitationConfirmPayload,
  UserInvitationConfirmResponse,
  ValidateTokenPayload,
  ValidateTokenResponse,
  TwoFACheckResponse,
  TwoFASetRequest,
} from '../types';
import { removeLocalStorageItem } from '@/services/localStorage';
import { DASHBOARD_STATE_NAME } from '@/features/Dashboard/types';
import { useUserPermissions, useUserStore } from '@/stores/user.store.ts';
import { UserService } from '@/services/user.ts';
import { LanguagesService } from '@/services/languages.ts';
import { useLanguagesStore } from '@/stores/languages.store.ts';
import { useConditionsStore } from '@/features/Roles/stores/conditions.store.ts';
import { fetchConditions } from '@/features/Conditions/api.ts';
import { useRolesStore } from '@/features/Roles/stores/roles.store.ts';
import { fetchRoles } from '@/features/Roles/api.ts';
import { isAuthenticatedByStore } from '@/services/auth.ts';
import { SortStatus } from '@/types';

const setGlobalUserData = async () => {
  const languagesStore = useLanguagesStore();
  const userPermissions = useUserPermissions();
  const userState = useUserStore();
  const conditionsStore = useConditionsStore();
  const rolesStore = useRolesStore();

  const languagesPayload = {
    'order[weight]': SortStatus.ASC,
    'filter[active]': true,
  };

  const user = await UserService.fetchUser();
  const languages = await LanguagesService.fetchLanguages(languagesPayload);
  const conditions = await fetchConditions();
  const roles = await fetchRoles();

  languagesStore.setLanguages(languages);
  userPermissions.setUserPermissions(user.permissions);
  userPermissions.setUserId(user.id);
  userState.setUserState(user);
  conditionsStore.setConditions(conditions);
  rolesStore.setRoles(roles.items);
};

export const AuthService = {
  login: async (payload: AuthLoginPayload): Promise<void> => {
    try {
      await login(payload);
      await setGlobalUserData();
    } catch (e) {
      console.log(e);
      throw e;
    }
  },
  twoFACheck: (): Promise<TwoFACheckResponse> => twoFACheck(),
  twoFASet: async (payload: TwoFASetRequest): Promise<void> => {
    try {
      await twoFASet(payload);
      await setGlobalUserData();
    } catch (e) {
      console.log(e);
      throw e;
    }
  },
  resetPassword: (payload: AuthResetPasswordPayload) => resetPassword(payload),
  createPassword: (payload: AuthCreatePasswordPayload) => createPassword(payload),
  validateToken: (payload: ValidateTokenPayload): Promise<ValidateTokenResponse> => validateToken(payload),
  userInvitationConfirm: (payload: UserInvitationConfirmPayload): Promise<UserInvitationConfirmResponse> =>
    userInvitationConfirm(payload),
  fetchUser,
  logout: async (isNeedGoToLogin: boolean = true) => {
    try {
      await logout();
      const userStore = useUserStore();
      const userPermissionsStore = useUserPermissions();
      const conditionsStore = useConditionsStore();
      const rolesStore = useRolesStore();

      removeLocalStorageItem(DASHBOARD_STATE_NAME);
      userPermissionsStore.clearUserPermissions();
      conditionsStore.clearConditions();
      rolesStore.clearRoles();

      userStore.clearUserState();

      if (isNeedGoToLogin && !isAuthenticatedByStore().value) {
        window.location.href = '/auth/login';
      }
    } catch (e) {
      console.log(e);
      throw e;
    }
  },
};
