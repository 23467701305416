<template>
  <aside
    ref="scrollableArea"
    class="bg-imperium-bg-sub-base flex rounded-2xl lg:pr-0 right-0 top-0 z-[60] lg:z-10 fixed lg:relative shadow-[0px_1px_3px_rgba(0,_0,_0,_0.1),_0px_1px_2px_-1px_rgba(0,_0,_0,_0.1)] transition-[right,margin-right]"
    :class="{
      'mr-0': props.isOpened,
      '-mr-[626px] -right-[626px] lg:mr-0 lg:right-0': !isMobile && !props.isOpened,
      '-mr-[453px] -right-[453px] lg:mr-0 lg:right-0': isMobile && !props.isOpened,

      // Scrolling
      'overflow-y-scroll lg:overflow-y-visible max-h-svh lg:max-h-[unset] overscroll-none scrollbar-thin': true,
    }"
  >
    <section
      class="relative h-full max-w-[372px] lg:w-full p-4 rounded-2xl z-[110] rounded-tr-none rounded-br-none shadow-[0px_1px_3px_rgba(0,_0,_0,_0.1),_0px_1px_2px_-1px_rgba(0,_0,_0,_0.1)]"
    >
      <section :data-anchor="METADATA_SECTIONS.INFO">
        <header class="flex items-center mb-4">
          <Button
            :visual-type="BUTTON_TYPE.GHOST"
            :size="SIZES.SMALL"
            class="flex lg:hidden mr-2"
            @click="onToggleSidebar"
          >
            <template #leftIcon>
              <SwipeIcon class="w-6 h-6 text-imperium-fg-muted" />
            </template>
          </Button>

          <h2 class="font-semibold text-lg text-imperium-fg-strong">Metadata</h2>
          <Tooltip
            :key="`tooltip-info`"
            width="unset"
            name="copy-info"
            placement="bottom"
            class="ml-auto"
          >
            <template #content>
              <Button
                :visual-type="BUTTON_TYPE.GHOST"
                :size="SIZES.SMALL"
                @click="copyToClipboard"
              >
                <CopyIcon />
              </Button>
            </template>
            <template #tooltip>
              <div class="text-sm whitespace-nowrap">Copy text</div>
            </template>
          </Tooltip>

          <ArticleStatus
            class="ml-1"
            :status="props.status"
            :published-at="props.publish"
          />
        </header>

        <div class="flex flex-col gap-2 mb-4">
          <div class="flex gap-2">
            <article class="flex flex-col gap-1 border input-rounded-default w-1/2 py-2 px-2">
              <h3 class="text-imperium-fg-base text-xs font-semibold">Words</h3>

              <span class="text-imperium-fg-strong">{{ articleStore.state.words }}</span>
            </article>

            <article class="flex flex-col gap-1 border input-rounded-default w-1/2 py-2 px-2">
              <h3 class="text-imperium-fg-base text-xs font-semibold">Characters</h3>

              <span class="text-imperium-fg-strong">{{ articleStore.state.characters }}</span>
            </article>
          </div>

          <div class="flex gap-2">
            <article class="flex flex-col gap-1 border input-rounded-default w-full py-2 px-2">
              <h3 class="text-imperium-fg-base text-xs font-semibold">Approved by</h3>

              <span class="text-imperium-fg-strong">{{ articleStore.state.approvedBy }}</span>
            </article>

            <article class="flex flex-col gap-1 border input-rounded-default w-full py-2 px-2">
              <h3 class="text-imperium-fg-base text-xs font-semibold">Cover set by</h3>

              <span class="text-imperium-fg-strong">{{ articleStore.state.coverSetBy }}</span>
            </article>
          </div>
        </div>

        <div v-if="documentMetadataSlugView">
          <Tooltip
            :key="`tooltip-slug`"
            width="unset"
            name="slug-info"
            placement="auto"
            :tooltip-visible="!documentMetadataSlugEdit && !documentMetadataSlugCondition"
            button-class="w-full text-left"
          >
            <template #content>
              <ArticleUrlInput
                :is-disabled="(!documentMetadataSlugEdit && !documentMetadataSlugCondition) || props.hasPost"
                name="articleSlug"
                :has-post="props.hasPost"
                :article-url="props.articleUrl || ''"
                :article-title="articleStore.state?.title || ''"
                :article-url-attrs="props.articleUrlAttrs"
                :is-readonly="props.hasPost"
                @update:article-url="(value) => emits('update-field', 'slug', value)"
              />
            </template>

            <template #tooltip>
              <div class="text-sm whitespace-nowrap">No access to edit for your role</div>
            </template>
          </Tooltip>
        </div>

        <div
          class="mb-6"
          :data-anchor="METADATA_SECTIONS.COVER"
        >
          <h3 class="font-semibold text-base text-imperium-fg-base mb-2">Cover</h3>
          <FormTextarea
            :model-value="props.assignment"
            name="assignment"
            class="mb-2"
            placeholder="Enter assignment"
            :is-errored="props.isAssignmentErrored"
            is-required
            :attrs="props.assignmentAttrs"
            :max="MAX_ASSIGNMENT_LENGTH"
            :size="SIZES.SMALL"
            @update:model-value="(value) => emits('update-field', 'assignment', value)"
          >
            <template #label>
              <span class="text-xs">Assignment</span>
            </template>
            <template #error> {{ errors.assignment }} </template>
          </FormTextarea>

          <Tooltip
            :key="`tooltip-breaking`"
            width="unset"
            name="breaking-info"
            placement="auto"
            :tooltip-visible="!documentMetadataBreakingNewsEdit && !documentMetadataBreakingNewsConditions"
            button-class="w-full text-left"
          >
            <template #content>
              <FormCheckbox
                v-if="documentMetadataBreakingNewsView"
                :model-value="props.isBreakingNews"
                :is-disabled="!documentMetadataBreakingNewsEdit && !documentMetadataBreakingNewsConditions"
                name="breaking-news"
                :size="SIZES.SMALL"
                :attrs="props.isBreakingNewsAttrs"
                class="mb-2 text-sm leading-[22px]"
                @update:model-value="(value) => emits('update-field', 'isBreakingNews', value)"
              >
                {{ t('article.breaking-news') }}
              </FormCheckbox>
            </template>
            <template #tooltip>
              <div class="text-sm whitespace-nowrap">No access to edit for your role</div>
            </template>
          </Tooltip>

          <DeadlineFormSelect
            :model-value="props.coverDeadline"
            :is-breaking-news="props.isBreakingNews"
            @update:model-value="(value) => emits('update-field', 'coverDeadline', value)"
          />
        </div>
      </section>

      <MetadataSectionTeam
        :writer="props.writer"
        :editor="props.editor"
        :copy-editor="props.copyEditor"
        class="mb-6"
        :data-anchor="METADATA_SECTIONS.TEAM"
        :writer-attrs="props.writerAttrs"
        :editor-attrs="props.editorAttrs"
        :copy-editor-attrs="props.copyEditorAttrs"
        @update:copy-editor="(value) => emits('update-field', 'copyEditorId', value)"
        @update:writer="(value) => emits('update-field', 'writerId', value)"
        @update:editor="(value) => emits('update-field', 'editorId', value)"
      />

      <MetadataSectionSchedule
        :deadline="props.deadline"
        :publish="props.publish"
        :embargo="props.embargo"
        class="mb-6"
        :data-anchor="METADATA_SECTIONS.SCHEDULE"
        :deadline-attrs="props.deadlineAttrs"
        :publish-attrs="props.publishAttrs"
        :embargo-attrs="props.embargoAttrs"
        :writer="props.writer"
        @update:embargo="(value) => emits('update-field', 'embargoUntil', value)"
        @update:publish="(value) => emits('update-field', 'publishedAt', value)"
      />

      <MetadataSectionTaxonomy
        :category="props.category"
        :label="props.label"
        :super-tags="props.superTags"
        :tags="props.tags"
        class="mb-6"
        :data-anchor="METADATA_SECTIONS.TAXONOMY"
        :category-attrs="props.categoryAttrs"
        :is-category-errored="props.isCategoryErrored"
        :label-attrs="props.labelAttrs"
        :is-label-errored="props.isLabelErrored"
        :super-tags-attrs="props.superTagsAttrs"
        :is-super-tags-errored="props.isSuperTagsErrored"
        :errors="errors"
        :tags-attrs="props.tagsAttrs"
        :is-tags-errored="props.isTagsErrored"
        @update:category="(value) => emits('update-field', 'categoryId', value)"
        @update:label="(value) => emits('update-field', 'badgeId', value)"
        @update:super-tags="(value) => emits('update-field', 'superTags', value)"
        @update:tags="(value) => emits('update-field', 'tags', value)"
      />

      <MetadataSectionSocialNetworks
        :twitter-post="props.twitterPost"
        :twitter-exclude-rss="props.twitterExcludeRss"
        :twitter-exclude-channels="props.twitterExcludeChannels"
        class="mb-6"
        :errors="errors"
        :data-anchor="METADATA_SECTIONS.SOCIALS"
        :twitter-post-attrs="props.twitterPostAttrs"
        :twitter-exclude-rss-attrs="props.twitterExcludeRssAttrs"
        :twitter-exclude-channels-attrs="props.twitterExcludeChannelsAttrs"
        :is-twitter-post-errored="props.isTwitterPostErrored"
        @update:twitter-exclude-channels="(value) => emits('update-field', 'isExcludedFromTelegram', value)"
        @update:twitter-exclude-rss="(value) => emits('update-field', 'twitterExcludeRss', value)"
        @update:twitter-post="(value) => emits('update-field', 'twitterPost', value)"
      />

      <MetadataSectionSeo
        :description="props.description"
        class="mb-6"
        :data-anchor="METADATA_SECTIONS.SEO"
        :errors="errors"
        :description-attrs="props.descriptionAttrs"
        :is-meta-description-errored="props.isMetaDescriptionErrored"
        @update:description="(value) => emits('update-field', 'seoMetaDescription', value)"
      />

      <MetadataSectionArticleSettings
        :writer="props.writer"
        :is-show-in-markets="props.isShowInMarkets"
        :is-exclude-from-rss="props.isExcludeFromRss"
        :is-hide-from-hot="props.isHideFromHot"
        :is-hide-from-main-page="props.isHideFromMainPage"
        :is-promo="props.isPromo"
        :data-anchor="METADATA_SECTIONS.SETTINGS"
        :is-show-in-markets-attrs="props.isShowInMarketsAttrs"
        :is-exclude-from-rss-attrs="props.isExcludeFromRssAttrs"
        :is-hide-from-hot-attrs="props.isHideFromHotAttrs"
        :is-hide-from-main-page-attrs="props.isHideFromMainPageAttrs"
        :is-promo-attrs="props.isPromoAttrs"
        :is-original-content="props.isOriginalContent"
        :can-be-republished="props.canBeRepublished"
        @update:is-breaking-news="(value) => emits('update-field', 'isBreakingNews', value)"
        @update:is-exclude-from-rss="(value) => emits('update-field', 'excludeFromAllRss', value)"
        @update:is-hide-from-hot="(value) => emits('update-field', 'hideFromHotStories', value)"
        @update:is-hide-from-main-page="(value) => emits('update-field', 'hideFromMainPage', value)"
        @update:is-promo="(value) => emits('update-field', 'isPromoPost', value)"
        @update:is-original-content="(value) => emits('update-field', 'isOriginalContent', value)"
        @update:canBeRepublished="(value) => emits('update-field', 'canBeRepublished', value)"
        @update:is-show-in-markets="(value) => emits('update-field', 'isShowingInMarkets', value)"
      />
    </section>

    <MetadataNavigation
      v-if="scrollableArea"
      ref="navigationRef"
      :area="scrollableArea"
    />

    <div id="metadata-tooltips" />
  </aside>
</template>

<script lang="ts" setup>
import { inject, ref } from 'vue';
import FormTextarea from '@/components/FormTextarea.vue';
import FormCheckbox from '@/components/FormCheckbox.vue';
import Button from '@/components/Button.vue';
import SwipeIcon from '@/assets/icons/swipe.svg?component';
import CopyIcon from '@/assets/icons/copy.svg?component';

import { BUTTON_TYPE, SIZES, DocumentStatus } from '@/types';

import MetadataNavigation from './MetadataNavigation.vue';
import ArticleStatus from './components/ArticleStatus.vue';
import ArticleUrlInput from './components/ArticleUrlInput.vue';
import MetadataSectionTeam from './components/MetadataSectionTeam.vue';
import MetadataSectionSchedule from './components/MetadataSectionSchedule.vue';
import MetadataSectionTaxonomy from './components/MetadataSectionTaxonomy.vue';
import MetadataSectionSocialNetworks from './components/MetadataSectionSocialNetworks.vue';
import MetadataSectionSeo from './components/MetadataSectionSeo.vue';
import MetadataSectionArticleSettings from './components/MetadataSectionArticleSettings.vue';

import { MAX_ASSIGNMENT_LENGTH, METADATA_SECTIONS } from './constants';
import { useUserPermissions } from '@/stores/user.store';
import type { SearchMultidropdownItem } from '@/components/FormSearchMultiDropdown.vue';
import { useI18n } from 'vue-i18n';
import DeadlineFormSelect from '@/features/ArticleLayout/components/DeadlineFormSelect.vue';
import { useArticleStore } from '@/features/ArticleLayout/stores/article.store';
import { useCopyToClipboard } from '@/helpers/copy-to-clipboard/useCopyToClipboard';
import Tooltip from '@/components/Tooltip.vue';
import { useUserConditions } from '@/composables/useUserConditions.ts';

const props = withDefaults(
  defineProps<{
    isOpened: boolean;

    status: DocumentStatus | undefined;
    articleUrl: string;
    assignment: string;
    needsCover: boolean;
    writer: string;
    editor: string;
    copyEditor: string;
    deadline: string;
    publish: string;
    coverDeadline: string;
    embargo: string;
    category: string;
    label: number;
    superTags: number[];
    tags: SearchMultidropdownItem[];
    twitterPost: string;
    twitterExcludeRss: boolean;
    twitterExcludeChannels: boolean;
    description: string;
    errors: Record<string, unknown>;
    languageId: number;

    hasPost: boolean;

    isBreakingNews: boolean;
    isShowInMarkets: boolean;
    isExcludeFromRss: boolean;
    isHideFromHot: boolean;
    isHideFromMainPage: boolean;
    isPromo: boolean;
    isOriginalContent: boolean;
    canBeRepublished: boolean;

    articleUrlAttrs: Record<string, unknown>;
    assignmentAttrs: Record<string, unknown>;
    needsCoverAttrs: Record<string, unknown>;
    writerAttrs: Record<string, unknown>;
    editorAttrs: Record<string, unknown>;
    copyEditorAttrs: Record<string, unknown>;
    deadlineAttrs: Record<string, unknown>;
    publishAttrs: Record<string, unknown>;
    coverDeadlineAttrs: Record<string, unknown>;
    embargoAttrs: Record<string, unknown>;
    categoryAttrs: Record<string, unknown>;
    labelAttrs: Record<string, unknown>;
    superTagsAttrs: Record<string, unknown>;
    tagsAttrs: Record<string, unknown>;
    twitterPostAttrs: Record<string, unknown>;
    twitterExcludeRssAttrs: Record<string, unknown>;
    twitterExcludeChannelsAttrs: Record<string, unknown>;
    descriptionAttrs: Record<string, unknown>;
    isBreakingNewsAttrs: Record<string, unknown>;
    isShowInMarketsAttrs: Record<string, unknown>;
    isExcludeFromRssAttrs: Record<string, unknown>;
    isHideFromHotAttrs: Record<string, unknown>;
    isHideFromMainPageAttrs: Record<string, unknown>;
    isPromoAttrs: Record<string, unknown>;

    isAssignmentErrored: boolean;
    isCategoryErrored: boolean;
    isLabelErrored: boolean;
    isSuperTagsErrored: boolean;
    isTagsErrored: boolean;
    isTwitterPostErrored: boolean;
    isMetaDescriptionErrored: boolean;
  }>(),
  {
    isOpened: false,
  },
);

const { t } = useI18n();
const clipboardService = useCopyToClipboard();

const emits = defineEmits<{
  (event: 'update-field', field: string, value: string | string[] | boolean | Date): void;

  (event: 'open'): void;
  (event: 'hide'): void;
}>();

const articleStore = useArticleStore();

const {
  documentMetadataSlugView,
  documentMetadataSlugEdit,
  documentMetadataBreakingNewsEdit,
  documentMetadataBreakingNewsView,
} = useUserPermissions();

const { documentMetadataSlugCondition, documentMetadataBreakingNewsConditions } = useUserConditions();

const isMobile = inject<boolean>('isMobile');

const scrollableArea = ref<HTMLElement | null>(null);
const navigationRef = ref<HTMLElement | null>(null);

const onToggleSidebar = () => {
  if (props.isOpened) {
    emits('hide');
    return;
  }

  emits('open');
};

const copyToClipboard = async () => {
  const title = document.createElement('h1');
  title.textContent = articleStore.state.title;
  const dek = document.createElement('p');
  dek.textContent = articleStore.state.dek;
  const text = `${title.outerHTML} ${dek.outerHTML} ${articleStore.state.fulltext}`;
  await clipboardService(text);
};

defineExpose({
  scrollToField: (field: string) => {
    if (!navigationRef.value) {
      return;
    }

    navigationRef.value.scrollToField(field);
  },
});
</script>

<style lang="scss" scoped>
[data-anchor] {
  scroll-margin: 80px;
}
</style>
