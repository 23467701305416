<template>
  <div class="flex flex-col">
    <div v-if="media">
      <div
        v-if="props.isEditing && media"
        class="flex flex-col xs:flex-row xs:items-center xs:justify-between text-sm text-imperium-fg-base mt-4"
      >
        <div>
          Uploaded by <span class="font-semibold text-imperium-fg-strong">{{ media.uploadedBy.name }}</span>
          {{ uploadedDate }}
        </div>
        <div class="mt-1 xs:mt-0 flex gap-1 min-w-max">
          Last usage
          <LastUsage :last-usage="media.lastUsage" />
        </div>
      </div>
      <div
        class="w-full mt-4 h-max max-h-[400px] overflow-hidden max-w-[80vw] xs:min-w-[350px] min-h-[200px] flex items-center justify-center"
      >
        <div
          v-if="isImageLoading || !props.mediaId || !media?.url"
          class="flex items-center justify-center w-full min-h-[200px]"
        >
          <LoaderIcon class="h-10 w-10" />
        </div>
        <div v-else>
          <img
            :src="media.url"
            alt=""
          />
        </div>
      </div>
      <div
        v-if="isEditing"
        class="mt-4 text-sm text-imperium-fg-base"
      >
        Used as a cover
        <span class="font-semibold text-imperium-fg-strong">
          {{ media.usedAsCoverCounter ? media.usedAsCoverCounter : 0 }} times
        </span>
      </div>
      <form
        class="flex flex-col mt-4"
        @submit="onSubmit"
      >
        <FormInput
          v-model="name"
          :attrs="nameAttrs"
          :is-errored="isNameErrored"
          class="w-full"
          :is-disabled="!editMedia"
          is-required
          name="imageName"
          placeholder="Image name"
          type="text"
        >
          <template #label>
            <span class="font-semibold">Image name</span>
          </template>
          <template #error>
            {{ errors.name }}
          </template>
        </FormInput>
        <Button
          :is-loading="isLoading"
          :size="SIZES.MEDIUM"
          :disabled="!editMedia && !editMediaCondition"
          class="mt-4"
          is-full-width
          type="submit"
        >
          Save
        </Button>
      </form>
    </div>
    <div
      v-else
      class="w-full mt-4 h-max min-w-[468px] min-h-[200px]"
    >
      <div class="flex items-center justify-center w-full min-h-[200px]">
        <LoaderIcon class="h-10 w-10" />
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { SIZES } from '@/types';
import Button from '@/components/Button.vue';
import LoaderIcon from '@/assets/icons/spinner.svg?component';
import FormInput from '@/components/FormInput.vue';
import { useToast } from '@/composables/useToast';
import { useFormData } from '@/composables/useFormData';
import { useSimpleAction } from '@/composables';
import zod from 'zod';
import { MediaService } from '@/features/Media/service/media';
import { computed, ref, watchEffect } from 'vue';
import { toTypedSchema } from '@vee-validate/zod';
import { UploadImageService } from '@/features/UploadImage/service/uploadImage';
import type { Media } from '@/features/Media/types';
import LastUsage from '@/components/LastUsage.vue';
import { dateBeautifyPeriodFormCurrentDate } from '@/utils/date';
import { useMediaList } from '@/features/Media/stores/media.store';
import { useUserPermissions } from '@/stores/user.store';
import { useUserConditions } from '@/composables/useUserConditions.ts';

const MAX_IMAGE_NAME_LENGTH = 500;

const props = withDefaults(
  defineProps<{
    isEditing: boolean;
    mediaId: number;
  }>(),
  {
    isEditing: false,
  },
);

const emits = defineEmits<{
  (event: 'close'): void;
}>();

const { editMedia } = useUserPermissions();
const { editMediaCondition } = useUserConditions();

const toast = useToast();
const media = ref<Media>();
const mediaStore = useMediaList();

const { defineField, errors, handleSubmit, values, setValues } = useFormData({
  data: {
    name: '',
  },
  validator: toTypedSchema(
    zod.object({
      name: zod
        .string({ message: 'Image name should be filled' })
        .min(3, 'Image name should be filled')
        .max(MAX_IMAGE_NAME_LENGTH, 'Image name could be less'),
    }),
  ),
});

const [name, nameAttrs] = defineField('name');

const isNameErrored = computed(() => !!errors.value.name);

const { isLoading: isImageLoading, action: imageAction } = useSimpleAction(async () => {
  try {
    if (props.mediaId) {
      const data = await MediaService.image(props.mediaId);
      media.value = data;
      setValues({
        name: data.title,
      });
    }
  } catch (err: any) {
    toast.errorTemporary({ id: 'ERROR_IMAGE_DOWNLOAD', message: 'Something went wrong. Please try again later' });
    throw err;
  }
});

const { isLoading, action } = useSimpleAction(async () => {
  try {
    if (!props.mediaId) {
      throw new Error();
    }
    await UploadImageService.imageSetAttributes(props.mediaId, { title: values.name });
    await mediaStore.fetchMediaList();
    toast.success({
      id: 'SUCCESS_IMAGE_UPLOAD',
      message: props.isEditing ? 'Image was successfully edited' : 'Image was successfully uploaded',
    });
    emits('close');
  } catch (err: any) {
    toast.errorTemporary({ id: 'ERROR_IMAGE_UPLOAD', message: 'Something went wrong. Please try again later' });
    throw err;
  }
});

const uploadedDate = computed(() => {
  if (media.value?.createdAt) {
    return dateBeautifyPeriodFormCurrentDate(media.value?.createdAt);
  }
});

watchEffect(() => {
  if (props.mediaId) {
    imageAction();
  } else {
    media.value = undefined;
  }
});

const onSubmit = (event: Event) => {
  event.preventDefault();
  handleSubmit(() => action())();
};
</script>
