<template>
  <TableRow
    :size="SIZES.MEDIUM"
    :striped="TABLE_STRIPED.STRIPED"
    :bordered="TABLE_BORDERED.BOTTOM"
  >
    <TableCell
      :size="SIZES.SMALL"
      :align="TABLE_ALIGN.RIGHT"
      :visual-type="TABLE_VISUAL_TYPE.SUBTLE"
      :vertical-align="TABLE_VALIGN.MIDDLE"
      data-testid="calc-id"
      :is-sticky="!isLargeDesktop"
      class="cursor-pointer"
      @click="onEditConverter"
    >
      {{ converter.id }}
    </TableCell>

    <TableCell
      data-testid="calc-crypto-symbol"
      :size="!isLargeDesktop ? SIZES.LARGE : SIZES.XLARGE"
      :scale="!isLargeDesktop ? TABLE_SCALE.DOUBLE : TABLE_SCALE.TRIPLE"
      :is-sticky="!isLargeDesktop"
      :sticky-offset-size="SIZES.SMALL"
      :vertical-align="TABLE_VALIGN.MIDDLE"
    >
      <div class="flex gap-2 items-center font-semibold text-sm">
        <Button
          :visual-type="BUTTON_TYPE.INVISIBLE"
          @click="onEditConverter"
        >
          <div class="flex gap-2 items-center font-semibold text-sm">
            <img
              v-if="cryptoIcon"
              :src="cryptoIcon"
              :alt="converter.cryptoSymbol"
              class="w-6 h-6"
            />
            {{ converter.cryptoSymbol ?? '—' }}
          </div>
        </Button>
      </div>
    </TableCell>
    <TableCell
      data-testid="calc-fiat-symbol"
      :size="!isLargeDesktop ? SIZES.LARGE : SIZES.XLARGE"
      :scale="!isLargeDesktop ? TABLE_SCALE.DOUBLE : TABLE_SCALE.TRIPLE"
      :vertical-align="TABLE_VALIGN.MIDDLE"
      :is-sticky="!isLargeDesktop"
      :sticky-offset-size="SIZES.SMALL"
    >
      <div class="flex gap-2 items-center font-semibold text-sm">
        <Button
          :visual-type="BUTTON_TYPE.INVISIBLE"
          @click="onEditConverter"
        >
          <div class="flex gap-2 items-center font-semibold text-sm">
            <img
              v-if="fiatIcon"
              :src="fiatIcon"
              :alt="converter.fiatSymbol"
              class="w-6 h-6"
            />
            {{ converter.fiatSymbol ?? '—' }}
          </div>
        </Button>
      </div>
    </TableCell>
    <TableCell
      data-testid="calc-status"
      :size="SIZES.LARGE"
      :scale="TABLE_SCALE.DOUBLE"
      :vertical-align="TABLE_VALIGN.MIDDLE"
    >
      <div class="text-sm text-imperium-fg-subtle cursor-default">
        <Badge
          :id="`calc-status-${statusText}`"
          :label="statusText"
          :size="SIZES.XSMALL"
          :theme="setBadgeTheme(converter.status)"
          :rounded="BADGE_ROUNDED.FULL"
          :is-hoverable="false"
          :is-closable="false"
          is-readonly
          @click="onEditConverter"
        />
      </div>
    </TableCell>
    <TableCell
      data-testid="calc-is-faq-published"
      :size="SIZES.SMALL"
      :align="TABLE_ALIGN.RIGHT"
      :visual-type="TABLE_VISUAL_TYPE.SUBTLE"
      :is-sticky="!isLargeDesktop"
      :vertical-align="TABLE_VALIGN.MIDDLE"
      class="cursor-default"
    >
      <RoundedOkIcon
        v-if="converter.isFaqPublished"
        class="text-imperium-ds-green-base"
      />
      <RoundedMinusIcon
        v-else
        class="text-imperium-fg-base"
      />
    </TableCell>
    <TableCell
      data-testid="calc-is-about-published"
      :size="SIZES.SMALL"
      :align="TABLE_ALIGN.RIGHT"
      :visual-type="TABLE_VISUAL_TYPE.SUBTLE"
      :is-sticky="!isLargeDesktop"
      :vertical-align="TABLE_VALIGN.MIDDLE"
      class="cursor-default"
    >
      <RoundedOkIcon
        v-if="converter.isAboutPublished"
        class="text-imperium-ds-green-base"
      />
      <RoundedMinusIcon
        v-else
        class="text-imperium-fg-base"
      />
    </TableCell>
    <TableCell
      data-testid="calc-website-link"
      is-full-width
      :vertical-align="TABLE_VALIGN.MIDDLE"
    >
      <a
        v-if="converter.status === ConverterStatus.PUBLISHED"
        :href="converter.websiteLink"
        target="_blank"
        class="flex gap-1 font-semibold text-imperium-ds-secondary-base items-center cursor-pointer"
        @click="() => console.log(123)"
      >
        Link
        <OpenLinkIcon class="w-4 h-4"></OpenLinkIcon>
      </a>
    </TableCell>
  </TableRow>
</template>

<script setup lang="ts">
import { computed, inject } from 'vue';
import { TableCell, TableRow } from '@/components/Table';
import RoundedOkIcon from '@/assets/icons/rounded-ok.svg?component';
import RoundedMinusIcon from '@/assets/icons/rounded-minus.svg?component';
import OpenLinkIcon from '@/assets/icons/open-link.svg?component';
import {
  BADGE_ROUNDED,
  BADGE_THEMES,
  BUTTON_TYPE,
  SIZES,
  TABLE_ALIGN,
  TABLE_BORDERED,
  TABLE_SCALE,
  TABLE_STRIPED,
  TABLE_VALIGN,
  TABLE_VISUAL_TYPE,
} from '@/types';
import type { ConverterTableItem } from '@/features/Converter/types';
import { ConverterStatus } from '@/features/Converter/types';
import Badge from '@/components/Badge.vue';
import { useI18n } from 'vue-i18n';
import { useRateIcon } from '@/features/Converter/queries/rateIcon.query';
import { useRouter } from 'vue-router';
import Button from '@/components/Button.vue';

const FLAG_TO_BADGE_COLOR_MAP = {
  1: BADGE_THEMES.GREEN,
  2: BADGE_THEMES.RED,
  0: BADGE_THEMES.DEFAULT,
};

const props = defineProps<{ converter: ConverterTableItem }>();

const router = useRouter();
const { t } = useI18n();

const isLargeDesktop = inject<boolean>('isLargeDesktop');

const statusText = computed(() => {
  return t(`converters.${ConverterStatus[props.converter.status].toLowerCase()}`);
});

const { data: cryptoIcon } = useRateIcon(props.converter.cryptoSymbol);

const { data: fiatIcon } = useRateIcon(props.converter.fiatSymbol);

const setBadgeTheme = (status: number) => {
  return FLAG_TO_BADGE_COLOR_MAP[status];
};

const onEditConverter = () => {
  router.push({
    name: 'converter-edit',
    params: {
      id: props.converter.id,
    },
  });
};
</script>
