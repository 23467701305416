import { ref } from 'vue';
import { useErrorHandling } from '@/composables/useErrorHandling';
import { AUTH_ERROR_CODES } from '@/features/Auth/types';
import { ERROR_CODE_TO_MESSAGE } from '@/features/Auth/constants';
import { useToast } from '@/composables';
import { useResetTwoFa } from '@/features/Users/queries';

type ModalState = {
  userId: number;
  userName: string;
};

export const useReset2faModal = () => {
  const isShowConfirmReset2fa = ref<boolean>(false);
  const toast = useToast();
  const modalUser = ref<ModalState>();

  const handleSuccess = () => {
    toast.success({ id: 'SUCCESS_RESET_2FA', message: `2FA reset for ${modalUser.value?.userName} was successful.` });
    isShowConfirmReset2fa.value = false;
  };

  const { handleError } = useErrorHandling({
    list: AUTH_ERROR_CODES,
    messages: ERROR_CODE_TO_MESSAGE,
    callback: (message, code) => {
      try {
        if (code) {
          toast.errorTemporary({ id: code, message });
          return;
        }

        toast.errorTemporary({ id: 'ERROR_RESET_2FA', message: 'Something went wrong, please try again later' });
      } catch (e) {
        console.warn(e);
      } finally {
        isShowConfirmReset2fa.value = false;
      }
    },
  });

  const { isPending: isResetting2FA, reset2FAMutate } = useResetTwoFa(handleSuccess, handleError);

  const onReset2FAOpenModal = ({ userId, userName }: ModalState) => {
    isShowConfirmReset2fa.value = true;
    modalUser.value = {
      userId,
      userName,
    };
  };

  const onCloseResetModal = () => {
    isShowConfirmReset2fa.value = false;
  };

  const onConfirmModal = () => {
    if (!modalUser.value?.userId) return;

    reset2FAMutate(modalUser.value.userId);
  };

  return { isResetting2FA, isShowConfirmReset2fa, modalUser, onReset2FAOpenModal, onCloseResetModal, onConfirmModal };
};
