<template>
  <ModalHolder
    data-testid="unpublish-article-modal"
    :is-visible="props.isVisible"
    :size="SIZES.SMALL"
    :type="MODAL_TYPES.POPUP"
    @close="emits('close')"
  >
    <div class="flex flex-col justify-center items-center">
      <ExclamationShapesIcon class="w-20 h-20 text-imperium-bg-5 mb-4" />

      <div class="text-imperium-fg-base text-base font-normal text-center">
        <template v-if="isArticleScheduled"> Do you want to unpublish this article? </template>

        <template v-else>
          This article has been already published.
          <br />
          Are you sure you want to unpublish this article?
        </template>
      </div>
    </div>

    <div class="flex items-center gap-4 justify-center pt-5">
      <Button
        :size="SIZES.MEDIUM"
        :visual-type="BUTTON_TYPE.TERTIARY"
        data-testid="button-cancel"
        @click="emits('close')"
      >
        Cancel
      </Button>

      <Button
        :size="SIZES.MEDIUM"
        :visual-type="BUTTON_TYPE.PRIMARY"
        :color="BUTTON_COLOR.RED"
        :is-loading="false"
        data-testid="button-cta"
        @click="emits('confirm')"
      >
        Yes, unpublish this article
      </Button>
    </div>
  </ModalHolder>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import ExclamationShapesIcon from '@/assets/icons/exclamation-shapes.svg?component';

import Button from '@/components/Button.vue';

import ModalHolder from '@/components/ModalHolder.vue';

import { BUTTON_TYPE, SIZES, BUTTON_COLOR, MODAL_TYPES } from '@/types';
import { useCurrentDocument } from '../../composables';

const props = withDefaults(
  defineProps<{
    isVisible?: boolean;
    isScheduled: boolean;
  }>(),
  {
    isVisible: false,
  },
);

const emits = defineEmits<{
  (event: 'confirm'): void;
  (event: 'close'): void;
}>();

const { data: articleData } = useCurrentDocument();

const isArticleScheduled = computed<boolean>(() => {
  const currentDate = new Date();
  const publishDate = articleData.value?.publishedAt ? new Date(articleData.value?.publishedAt) : currentDate;

  return publishDate.getTime() > currentDate.getTime();
});
</script>
