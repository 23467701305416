<template>
  <div>
    <div class="flex flex-row gap-2 my-4 justify-between sm:justify-end items-center">
      <Button
        class="basis-1/5 w-full sm:basis-0 sm:w-auto"
        :color="BUTTON_COLOR.DEFAULT"
        :size="SIZES.MEDIUM"
        :visual-type="BUTTON_TYPE.TERTIARY"
        @click="onCancel"
      >
        Cancel
      </Button>
      <Button
        class="flex justify-center basis-4/5 w-full sm:basis-0 sm:w-auto"
        :size="SIZES.MEDIUM"
        :visual-type="BUTTON_TYPE.PRIMARY"
        :is-loading="isPending"
        :is-disabled="isPending"
        @click="onSubmit"
      >
        <template v-if="isEditing"> Save changes </template>
        <template v-else> Create a new category </template>
      </Button>
    </div>
    <section class="bg-imperium-bg-sub-base p-4 sm:rounded-2xl">
      <TitleHelper
        title="General settings"
        badge-number="1"
        description="Make this category eassily indentifiable"
      />
      <div class="flex flex-col sm:flex-row mt-4 full-width gap-4">
        <div class="flex flex-col sm:basis-1/2">
          <FormInput
            v-model="title"
            :attrs="titleAttrs"
            :is-errored="!!errors[CATEGORY_FIELD_NAME]"
            is-required
          >
            <template #label> Category name </template>
            <template #error>{{ errors[CATEGORY_FIELD_NAME] }}</template>
          </FormInput>
          <FormInput
            v-model="url"
            :attrs="urlAttrs"
            class="mt-4"
            :is-errored="!!errors.url"
            is-required
          >
            <template #label> URL </template>
            <template #help>
              <span class="text-imperium-fg-muted text-xs">{{ exampleUrl }}</span>
            </template>
            <template #error>{{ errors.url }}</template>
          </FormInput>
          <FormCheckbox
            v-model="removeCategory"
            name="remove_cateogory"
            :attrs="removeCategoryAttrs"
            :size="SIZES.SMALL"
            class="mt-4"
            :is-errored="!!errors[CATEGORY_FIELD_REMOVE_CATEGORY]"
          >
            Remove "/category/" from the URL
          </FormCheckbox>
          <FormCheckbox
            v-model="showOnWebsite"
            name="show_on_website"
            :attrs="showOnWebsiteAttrs"
            :is-errored="!!errors[CATEGORY_FIELD_SHOW_ON_WEBSITE]"
            :size="SIZES.SMALL"
            class="mt-4"
          >
            Display the category on the website
          </FormCheckbox>
          <FormCheckbox
            v-model="related"
            name="show_on_website"
            :attrs="relatedAttrs"
            :is-errored="!!errors[CATEGORY_FIELD_SHOW_IN_METADATA]"
            :size="SIZES.SMALL"
            class="mt-4"
          >
            Show the category in the Metadata when selecting a category for an article
          </FormCheckbox>
        </div>

        <div class="flex flex-col sm:basis-1/2">
          <FormTextarea
            v-model="description"
            :attrs="descriptionAttrs"
            :is-errored="!!errors[CATEGORY_FIELD_DESCRIPTION]"
            name="description"
            min-height="280px"
            is-required
          >
            <template #label> Category description </template>
            <template #error>{{ errors[CATEGORY_FIELD_DESCRIPTION] }}</template>
          </FormTextarea>
        </div>
      </div>
      <TitleHelper
        class="mt-8"
        title="SEO"
        badge-number="2"
        description="Improve search engine rankings and attract more targeted traffic."
      />
      <div class="flex flex-col sm:flex-row mt-4 full-width gap-4">
        <div class="flex flex-col sm:basis-1/2">
          <FormInput
            v-model="metaTitle"
            :attrs="metaTitleAttrs"
            :is-errored="!!errors[CATEGORY_FIELD_META_TITLE]"
            is-required
          >
            <template #label> SEO-title </template>
            <template #help>
              <span class="text-imperium-fg-muted text-xs"
                >A brief entry that represents the page's content and affects its search ranking.</span
              >
            </template>
            <template #error>{{ errors[CATEGORY_FIELD_META_TITLE] }}</template>
          </FormInput>
        </div>

        <div class="flex flex-col sm:basis-1/2">
          <FormTextarea
            v-model="metaDescription"
            :attrs="metaDescriptionAttrs"
            :is-errored="!!errors[CATEGORY_FIELD_META_DESCRIPTION]"
            name="meta_description"
            min-height="320px"
            is-required
          >
            <template #label> SEO description </template>
            <template #error>{{ errors[CATEGORY_FIELD_META_DESCRIPTION] }}</template>
          </FormTextarea>
        </div>
      </div>
      <TitleHelper
        class="mt-8"
        title="List of labels"
        badge-number="3"
        description="Assign a list of labels for this category."
      />
      <div class="flex flex-col sm:flex-row mt-4 full-width gap-4">
        <div class="flex flex-col sm:basis-1/2">
          <LabelsMultiDropDown
            v-model="labels"
            :attrs="labelsAttrs"
            :is-errored="!!errors[CATEGORY_FIELD_BADGES]"
            :is-disabled="false"
            name="badges_category"
          >
            <template #label> Labels </template>
            <template #help>
              <span class="text-imperium-fg-muted text-xs">
                Only the assigned labels will be available within this category. Others won't be visible.
              </span>
            </template>
          </LabelsMultiDropDown>
        </div>
      </div>
    </section>
    <UnsavedChangesModal
      :is-visible="isCloseModalVisible"
      @close="isCloseModalVisible = false"
      @continue="onContinueModal"
      @save="onSaveModal"
    />
  </div>
</template>
<script setup lang="ts">
import TitleHelper from '@/components/TitleHelper.vue';
import FormInput from '@/components/FormInput.vue';
import FormTextarea from '@/components/FormTextarea.vue';
import FormCheckbox from '@/components/FormCheckbox.vue';
import { BUTTON_COLOR, BUTTON_TYPE, SIZES } from '@/types';
import type { CategoryTranslate, CategoryTranslateForm } from '@/features/Categories/types';
import { useFormData, useToast } from '@/composables';
import { categoriesFormSchema } from '@/features/Categories/validators';
import { useBreadcrumbStore } from '@/features/Breadcrumbs/store';
import {
  CATEGORY_FIELD_BADGES,
  CATEGORY_FIELD_DESCRIPTION,
  CATEGORY_FIELD_ID,
  CATEGORY_FIELD_META_DESCRIPTION,
  CATEGORY_FIELD_META_TITLE,
  CATEGORY_FIELD_NAME,
  CATEGORY_FIELD_REMOVE_CATEGORY,
  CATEGORY_FIELD_SHOW_IN_METADATA,
  CATEGORY_FIELD_SHOW_ON_WEBSITE,
  CATEGORY_FIELD_URL,
} from '@/features/Categories/constants';
import { computed, onMounted, ref } from 'vue';
import Button from '@/components/Button.vue';
import { useUpdateCategory } from '@/features/Categories/queries/useMutateCategory';
import { useRouter } from 'vue-router';
import LabelsMultiDropDown from '@/features/Labels/components/LabelsForm/LabelsMultiDropDown.vue';
import { transformFormToCategoryPayload } from '@/features/Categories/services/form-transformer';
import UnsavedChangesModal from '@/features/Users/components/UserInfo/UnsavedChangesModal.vue';
import { useCategoriesInvalidation } from '@/features/Categories/queries/useFetchCategories';

const isCloseModalVisible = ref(false);
interface Props {
  data: CategoryTranslate | null;
}

const props = withDefaults(defineProps<Props>(), {
  data: null,
});

const { createBreadcrumbsWatcher } = useBreadcrumbStore();
const toast = useToast();
const router = useRouter();
const { invalidateCategories } = useCategoriesInvalidation();
const { defineField, resetForm, handleSubmit, meta, errors } = useFormData({
  data: props.data,
  validator: categoriesFormSchema,
});

const isEditing = computed(() => !!props.data);

const { mutate, isPending } = useUpdateCategory({
  onSuccess: (category) => {
    invalidateCategories();

    if (!isEditing.value) {
      toast.success({
        id: 'CATEGORY_CREATED_SUCCESS',
        message: 'Category was created.',
      });

      router.push({
        name: 'categories-edit',
        params: {
          id: category[CATEGORY_FIELD_ID],
        },
      });
      return;
    }
    toast.success({
      id: 'CATEGORY_DATA_SAVE_SUCCESS',
      message: 'Data was saved',
    });
  },
  onError: ({ data }) => {
    const { errorMessage, errorCode } = data;

    toast.errorTemporary({
      id: errorCode,
      message: errorMessage,
    });
  },
});

const [title, titleAttrs] = defineField(CATEGORY_FIELD_NAME);
const [url, urlAttrs] = defineField(CATEGORY_FIELD_URL);
const [description, descriptionAttrs] = defineField(CATEGORY_FIELD_DESCRIPTION);
const [metaTitle, metaTitleAttrs] = defineField(CATEGORY_FIELD_META_TITLE);
const [labels, labelsAttrs] = defineField(CATEGORY_FIELD_BADGES);
const [metaDescription, metaDescriptionAttrs] = defineField(CATEGORY_FIELD_META_DESCRIPTION);
const [removeCategory, removeCategoryAttrs] = defineField(CATEGORY_FIELD_REMOVE_CATEGORY);
const [showOnWebsite, showOnWebsiteAttrs] = defineField(CATEGORY_FIELD_SHOW_ON_WEBSITE);
const [related, relatedAttrs] = defineField(CATEGORY_FIELD_SHOW_IN_METADATA);

const exampleUrl = computed(() => {
  let baseUrl = 'https://cointelegraph.com/';

  if (!removeCategory.value) {
    baseUrl += 'category/';
  }

  if (url.value) {
    baseUrl += url.value;
  }
  return baseUrl;
});

const onSubmit = handleSubmit(
  (values: CategoryTranslateForm) => {
    mutate({ category: transformFormToCategoryPayload(values), categoryId: props?.data?.[CATEGORY_FIELD_ID] || null });
  },
  () => {
    toast.errorTemporary({
      id: 'ERROR_VALIDATION',
      message: 'All mandatory fields must be filled in to move the article to another status.',
    });
  },
);

const goToCategories = () => {
  router.push({ name: 'categories' });
};

const resetFormData = () => {
  resetForm({ values: props.data ?? {} });
};

const onCancel = () => {
  if (meta.value.dirty) {
    isCloseModalVisible.value = true;
  } else {
    router.push({ name: 'categories' });
  }
};

const onContinueModal = () => {
  goToCategories();
};

const onSaveModal = () => {
  onSubmit();
  isCloseModalVisible.value = false;
};

onMounted(async () => {
  if (props.data) {
    resetFormData();
  }
});

createBreadcrumbsWatcher(title, 'edit-category');
</script>
