<template>
  <AuthPageWrapper data-testid="auth-invite-user">
    <template #title> Welcome to Editorial </template>

    <div class="mb-8 text-lg text-imperium-fg-muted">
      <p class="mb-2">You have been invited to the Editorial space.</p>
      <p class="mb-2">
        Create a password for the account
        <b
          v-if="props.email"
          class="text-imperium-fg-muted"
          >{{ props.email }}</b
        >.
      </p>
      <p>
        Your password must be at least 8 characters long and include at least one capital letter and one special
        character to enhance security.
      </p>
    </div>

    <form
      class="flex flex-col grow"
      @submit="onSubmit"
    >
      <FormPasswordInput
        v-model="password"
        class="mb-4"
        :is-errored="isPasswordErrored"
        :attrs="passwordAttrs"
        name="password"
      >
        <template #label> Password </template>

        <template #icon>
          <LockIcon class="absolute z-10 top-2/4 -mt-3 text-imperium-fg-muted" />
        </template>

        <template #error>
          {{ errors.password }}
        </template>
      </FormPasswordInput>

      <FormPasswordInput
        v-model="confirm"
        class="mb-4"
        :is-errored="isConfirmErrored"
        :attrs="confirmAttrs"
        name="confirm"
      >
        <template #label> Confirm password </template>

        <template #icon>
          <LockIcon class="absolute z-10 top-2/4 -mt-3 text-imperium-fg-muted" />
        </template>

        <template #error>
          {{ errors.confirm }}
        </template>
      </FormPasswordInput>

      <div class="mt-auto">
        <Button
          type="submit"
          data-testid="button-create"
          :is-loading="isLoading"
          is-full-width
        >
          Create a password
        </Button>
      </div>
    </form>
  </AuthPageWrapper>
</template>
<script setup lang="ts">
import AuthPageWrapper from '@/features/Auth/components/AuthPageWrapper.vue';
import Button from '@/components/Button.vue';
import FormPasswordInput from '@/components/FormPasswordInput.vue';
import { useRoute, useRouter } from 'vue-router';
import { useFormData } from '@/composables/useFormData';
import { toTypedSchema } from '@vee-validate/zod';
import zod from 'zod';
import { passwordRegExp } from '@/features/Auth/constants/rules';
import LockIcon from '@/assets/icons/lock.svg?component';
import { computed } from 'vue';
import { useSimpleAction } from '@/composables';
import { AUTH_ERROR_CODES, type AuthLoginPayload } from '@/features/Auth/types';
import { AuthService } from '@/features/Auth/service';
import { type GenericError } from '@/types/exceptions';
import { useTwoFactorAuthStore } from '@/features/Auth/stores/twoFactorAuthStore';
import { useErrorHandling } from '@/composables/useErrorHandling';
import { ERROR_CODE_TO_MESSAGE } from '@/features/Auth/constants';

const props = defineProps<{
  email: string;
}>();

const router = useRouter();
const twoFAStore = useTwoFactorAuthStore();

const route = useRoute();
const token = route.params.token;

const { defineField, errors, meta, handleSubmit, setFieldError } = useFormData({
  data: {
    password: '',
    confirm: '',
  },
  validator: toTypedSchema(
    zod
      .object({
        password: zod
          .string({
            required_error: 'Create a new password',
          })
          .min(1, { message: 'Create a new password' })
          .regex(passwordRegExp, 'Password must be 8 characters long with 1 capital and 1 special character'),
        confirm: zod
          .string({
            required_error: 'Confirm a new password',
          })
          .min(1, { message: 'Confirm a new password' }),
      })
      .superRefine(({ password, confirm }, ctx) => {
        if (confirm !== password) {
          ctx.addIssue({
            path: ['confirm'],
            code: 'custom',
            message: "Passwords don't match",
          });
        }
      }),
  ),
});

const [password, passwordAttrs] = defineField('password');
const [confirm, confirmAttrs] = defineField('confirm');

const isPasswordErrored = computed(() => meta.value.touched && !!errors.value.password);
const isConfirmErrored = computed(() => meta.value.touched && !!errors.value.confirm);

const onSubmit = handleSubmit((values) =>
  action({
    password: values.password,
    token,
  }),
);

const { handleError } = useErrorHandling({
  list: AUTH_ERROR_CODES,
  messages: ERROR_CODE_TO_MESSAGE,
  callback: async (message, code) => {
    if (code === AUTH_ERROR_CODES.ACCESS_DENIED) {
      router.replace({
        name: 'UserTemporarilyBlocked',
      });
      return;
    }

    if (code === AUTH_ERROR_CODES['2FA_REQUIRED']) {
      const twoFAData = await AuthService.twoFACheck();

      if (!twoFAData?.qrCode || !twoFAData?.backupCodes) {
        router.replace({ path: '/auth/2fa-code' });
      } else {
        twoFAStore.setState({
          codes: twoFAData.backupCodes,
          qr: twoFAData.qrCode,
        });

        router.replace({ path: '/auth/2fa' });
      }
    }

    setFieldError('password', message);
  },
});

const { isLoading, action } = useSimpleAction(async (payload: AuthLoginPayload) => {
  try {
    await AuthService.userInvitationConfirm(payload);
    router.replace({ path: '/' });
  } catch (e: unknown) {
    handleError(e as GenericError);
  }
});
</script>
