<template>
  <Dropdown :id="`sort-dropdown-${props.name}`">
    <template #button>
      <Button
        :size="SIZES.MEDIUM"
        :visual-type="BUTTON_TYPE.TERTIARY"
        leading-label="Sort"
      >
        <template #leftIcon>
          <LongArrowUp
            v-if="currentSortType === SortStatus.ASC"
            class="w-5 h-5 text-imperium-fg-base"
          />
          <LongArrowDown
            v-if="currentSortType === SortStatus.DESC"
            class="w-5 h-5 text-imperium-fg-base"
          />
        </template>

        <template #rightIcon>
          <ArrowDown class="w-5 h-5 text-imperium-fg-strong" />
        </template>

        {{ currentSort.label }}
      </Button>
    </template>

    <template #menu>
      <div class="flex py-3 gap-1 flex-col">
        <div class="px-3">
          <Button
            :visual-type="BUTTON_TYPE.TERTIARY"
            :color="BUTTON_COLOR.RED"
            :size="SIZES.SMALL"
            class="mb-0.5"
            is-full-width
            @click="onCurrentSortReset"
          >
            Reset to default
          </Button>
        </div>

        <div class="text-sm px-3 text-imperium-fg-muted font-semibold">Sort by</div>
        <div
          v-for="value of props.fields"
          :key="value.value"
          class="py-1 px-3"
        >
          <RadioButton
            :model-value="currentSort.value"
            :value="value.value"
            @update:model-value="onCurrentSortUpdate"
          >
            <template #label>{{ value.label }}</template>
          </RadioButton>
        </div>

        <div class="text-sm text-imperium-fg-muted font-semibold py-1 px-3">Sort direction</div>

        <div
          v-for="value of sortTypeList"
          :key="value.value"
          class="py-1 px-3"
        >
          <RadioButton
            :model-value="currentSortType"
            :label="value.label"
            :value="value.value"
            @update:model-value="onCurrentSortDirectionUpdate"
          >
            <template #label>
              <div class="flex">
                <span class="mr-2">{{ value.label }}</span>

                <LongArrowUp
                  v-if="value.value === SortStatus.ASC"
                  class="w-5 h-5 text-imperium-fg-base"
                />
                <LongArrowDown
                  v-if="value.value === SortStatus.DESC"
                  class="w-5 h-5 text-imperium-fg-base"
                />
              </div>
            </template>
          </RadioButton>
        </div>
      </div>
    </template>
  </Dropdown>
</template>

<script lang="ts" setup>
import { ref, computed } from 'vue';
import { type Store } from 'pinia';

import Dropdown from '@/components/Dropdown.vue';
import Button from '@/components/Button.vue';

import { BUTTON_TYPE, BUTTON_COLOR, SIZES, SortStatus } from '@/types';

import ArrowDown from '@/assets/icons/arrow-down.svg?component';
import LongArrowDown from '@/assets/icons/long-arrow-down.svg?component';
import LongArrowUp from '@/assets/icons/long-arrow-up.svg?component';
import RadioButton from '@/components/RadioButton.vue';

const sortTypeList = [
  {
    value: SortStatus.ASC,
    label: 'Ascending',
  },
  {
    value: SortStatus.DESC,
    label: 'Descending',
  },
];

export interface SortField {
  value: string;
  label: string;
}

const props = defineProps<{
  name: string;
  fields: SortField[];
  filterStore: Store;
}>();

const emits = defineEmits<{
  (event: 'update', value: SortField, direction: SortStatus): void;
  (event: 'reset'): void;
}>();

const currentSort = computed<SortField>(() => {
  const sortFromStore = Object.keys(props.filterStore.state.sort || {});

  if (sortFromStore && sortFromStore[0]) {
    return {
      value: sortFromStore[0],
      label: props.fields.find((element) => element.value === sortFromStore[0])?.label || '',
    } as SortField;
  }

  return props.fields[0];
});
const currentSortType = computed<SortStatus>(() => {
  const sortFromStore = Object.values(props.filterStore.state.sort);

  return sortFromStore && sortFromStore[0] ? sortFromStore[0] : SortStatus.DESC;
});

const onCurrentSortUpdate = (newValue: string | number) => {
  const newSortField = props.fields.find((element) => element.value === newValue);

  if (newSortField) {
    emits('update', newSortField, currentSortType.value);
  }
};

const onCurrentSortReset = () => {
  emits('reset');
};

const onCurrentSortDirectionUpdate = (newValue: string | number) => {
  const newSortField = props.fields.find((element) => element.value === currentSort.value.value);

  if (!newSortField) {
    return;
  }

  emits('update', newSortField, newValue as SortStatus);
};
</script>
