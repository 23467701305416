// Prevent from pasting multiple br (one br ProseMirror-trailingBreak from editor, second from text)
import { Fragment, Node, Schema, Slice } from 'prosemirror-model';

export const transformPasted = (schema: Schema) => {
  function recurse(item) {
    if (item instanceof Fragment) {
      const nodes = [];
      for (let i = 0; i < item.childCount; i++) {
        nodes.push(recurse(item.child(i)));
      }
      return Fragment.fromArray(nodes);
    } else if (item instanceof Node) {
      const fragment = item.content.size > 0 ? recurse(item.content) : Fragment.empty;
      let node;

      if (item.type.isBlock && item.content.size === 1 && item.content.child(0).type === schema.nodes.hard_break) {
        node = item.copy();
      } else {
        node = item.copy(fragment);
      }

      return node;
    }

    return item;
  }

  return function (slice: Slice) {
    const fragment = recurse(slice.content);
    return new Slice(fragment, slice.openStart, slice.openEnd);
  };
};
