<template>
  <section>
    <h3 class="font-semibold text-base text-imperium-fg-base mb-2">Team</h3>

    <Tooltip
      :key="`tooltip-writer`"
      width="unset"
      name="writer-info"
      placement="auto"
      :tooltip-visible="!documentMetadataWriterSelectEdit && !documentMetadataWriterSelectCondition"
      button-class="w-full text-left"
    >
      <template #content>
        <FormSelect
          v-if="documentMetadataWriterSelectView"
          name="writer"
          class="mb-2"
          :is-disabled="!documentMetadataWriterSelectEdit && !documentMetadataWriterSelectCondition"
          placeholder="Unassigned"
          :model-value="props.writer"
          :attrs="props.writerAttrs"
          :values="writers"
          :size="SIZES.SMALL"
          :variant="VARIANTS.MUTED"
          can-be-cleared
          @update:model-value="(value) => emits('update:writer', value)"
        >
          <template #label>
            <span class="text-xs">Writer</span>
          </template>
        </FormSelect>
      </template>
      <template #tooltip>
        <div class="text-sm whitespace-nowrap">No access to edit for your role</div>
      </template>
    </Tooltip>

    <Tooltip
      :key="`tooltip-editor`"
      width="unset"
      name="editor-info"
      placement="auto"
      :tooltip-visible="!documentMetadataEditorSelectEdit && !documentMetadataEditorSelectCondition"
      button-class="w-full text-left"
    >
      <template #content>
        <FormSelect
          v-if="documentMetadataEditorSelectView"
          name="editor"
          class="mb-2"
          :is-disabled="!documentMetadataEditorSelectEdit && !documentMetadataEditorSelectCondition"
          placeholder="Unassigned"
          :model-value="props.editor"
          :attrs="props.editorAttrs"
          :size="SIZES.SMALL"
          :values="editors"
          :variant="VARIANTS.MUTED"
          can-be-cleared
          @update:model-value="(value) => emits('update:editor', value)"
        >
          <template #label>
            <span class="text-xs">Editor</span>
          </template>
        </FormSelect>
      </template>
      <template #tooltip>
        <div class="text-sm whitespace-nowrap">No access to edit for your role</div>
      </template>
    </Tooltip>

    <Tooltip
      :key="`tooltip-copy-editor`"
      width="unset"
      name="copy-editor-info"
      placement="auto"
      :tooltip-visible="!documentMetadataCopyEditorSelectEdit && !documentMetadataCopyEditorSelectCondition"
      button-class="w-full text-left"
    >
      <template #content>
        <FormSelect
          v-if="documentMetadataCopyEditorSelectView"
          name="copyEditor"
          :is-disabled="!documentMetadataCopyEditorSelectEdit && !documentMetadataCopyEditorSelectCondition"
          placeholder="Unassigned"
          :model-value="props.copyEditor"
          :attrs="props.copyEditorAttrs"
          :values="copyeditors"
          :size="SIZES.SMALL"
          :variant="VARIANTS.MUTED"
          can-be-cleared
          @update:model-value="(value) => emits('update:copyEditor', value)"
        >
          <template #label>
            <span class="text-xs">Copy Editor</span>
          </template>
        </FormSelect>
      </template>
      <template #tooltip>
        <div class="text-sm whitespace-nowrap">No access to edit for your role</div>
      </template>
    </Tooltip>
  </section>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import FormSelect from '@/components/FormSelect.vue';
import { SIZES, VARIANTS } from '@/types';
import { useFetchUsers } from '@/features/Users/queries';
import { type UsersFetchPayload } from '@/features/Users/types';
import { mapUsersToSelectItemByRole } from '@/features/Users/utils';
import { type SelectItem } from '@/components/FormSelect.vue';
import { ROLES } from '@/types';
import { useUserPermissions } from '@/stores/user.store';
import { useRolesStore } from '@/features/Roles/stores/roles.store';
import { useUserConditions } from '@/composables/useUserConditions.ts';
import Tooltip from '@/components/Tooltip.vue';

const props = defineProps<{
  writer: number;
  writerAttrs: Record<string, unknown>;
  editor: number;
  editorAttrs: Record<string, unknown>;
  copyEditor: number;
  copyEditorAttrs: Record<string, unknown>;
}>();

const emits = defineEmits<{
  (event: 'update:writer', value: number): void;
  (event: 'update:editor', value: number): void;
  (event: 'update:copyEditor', value: number): void;
}>();

const rolesStore = useRolesStore();
const roles = computed(() => rolesStore.state);

const getWriterRoleId = computed(() => roles?.value?.find((role) => role.slug === ROLES.ROLE_WRITER)?.id) ?? null;
const getSeniorWriterRoleId =
  computed(() => roles?.value?.find((role) => role.slug === ROLES.ROLE_SENIOR_WRITER)?.id) ?? null;
const getEditorRoleId = computed(() => roles?.value?.find((role) => role.slug === ROLES.ROLE_EDITOR)?.id) ?? null;
const getCopyEditorRoleId =
  computed(() => roles?.value?.find((role) => role.slug === ROLES.ROLE_COPY_EDITOR)?.id) ?? null;
const getEditorInChiefRoleId =
  computed(() => roles?.value?.find((role) => role.slug === ROLES.ROLE_EDITOR_IN_CHIEF)?.id) ?? null;

const {
  documentMetadataEditorSelectView,
  documentMetadataEditorSelectEdit,
  documentMetadataWriterSelectView,
  documentMetadataWriterSelectEdit,
  documentMetadataCopyEditorSelectView,
  documentMetadataCopyEditorSelectEdit,
} = useUserPermissions();

const {
  documentMetadataWriterSelectCondition,
  documentMetadataEditorSelectCondition,
  documentMetadataCopyEditorSelectCondition,
} = useUserConditions();

const fetchUserParams = computed<UsersFetchPayload>(() => ({
  page: 1,
  itemsPerPage: 1000,
  filters: {
    roles: [
      getCopyEditorRoleId.value,
      getWriterRoleId.value,
      getEditorRoleId.value,
      getSeniorWriterRoleId.value,
      getEditorInChiefRoleId.value,
    ],
  },
}));
const { data: users } = useFetchUsers(fetchUserParams);

const writers = computed<SelectItem[]>(() => {
  if (!roles.value?.length || !users.value?.items?.length) {
    return [];
  }

  return [
    ...mapUsersToSelectItemByRole(users.value.items, ROLES.ROLE_WRITER),
    ...mapUsersToSelectItemByRole(users.value.items, ROLES.ROLE_SENIOR_WRITER),
    ...mapUsersToSelectItemByRole(users.value.items, ROLES.ROLE_EDITOR),
    ...mapUsersToSelectItemByRole(users.value.items, ROLES.ROLE_EDITOR_IN_CHIEF),
  ];
});

const editors = computed<SelectItem[]>(() => {
  if (!roles.value?.length || !users.value?.items?.length) {
    return [];
  }

  return [
    ...mapUsersToSelectItemByRole(users.value.items, ROLES.ROLE_EDITOR),
    ...mapUsersToSelectItemByRole(users.value.items, ROLES.ROLE_EDITOR_IN_CHIEF),
  ];
});

const copyeditors = computed<SelectItem[]>(() => {
  if (!roles.value?.length || !users.value?.items?.length) {
    return [];
  }

  return mapUsersToSelectItemByRole(users.value.items, ROLES.ROLE_COPY_EDITOR);
});
</script>
