<template>
  <div>
    <Button
      v-if="uploadMediaView"
      :size="SIZES.MEDIUM"
      :is-full-width="isFullWidth"
      :is-disabled="!uploadMediaEdit && !uploadMediaCondition"
      @click="isUploadingFileModalOpen = true"
    >
      <template #leftIcon>
        <PlacementUtils class="h-5 w-5" />
      </template>
      Upload media
    </Button>
    <UploadImageModal
      :is-visible="isUploadingFileModalOpen"
      @close="onClose"
    />
  </div>
</template>
<script lang="ts" setup>
import { ref } from 'vue';
import PlacementUtils from '@/assets/icons/placement-utils.svg?component';
import { SIZES } from '@/types';
import Button from '@/components/Button.vue';
import UploadImageModal from '@/features/UploadImage/modals/UploadImageModal.vue';
import { useUserPermissions } from '@/stores/user.store';
import { useUserConditions } from '@/composables/useUserConditions.ts';

const props = withDefaults(
  defineProps<{
    isFullWidth: boolean;
  }>(),
  {
    isFullWidth: false,
  },
);

const { uploadMediaView, uploadMediaEdit } = useUserPermissions();
const { uploadMediaCondition } = useUserConditions();

const isUploadingFileModalOpen = ref<boolean>(false);

const onClose = () => {
  isUploadingFileModalOpen.value = false;
};
</script>
