import type { Faq, FaqList } from '@/features/Converter/types/faq';
import { defineStore } from 'pinia';
import { ref, nextTick } from 'vue';
import { useToast } from '@/composables';
import { FaqService } from '@/features/Converter/services/faq';
import type { ArticleTagsFetchRequest } from '@/features/ArticleLayout/types';
import type { ConverterFAQPutRequest } from '@/features/Converter/types/api';
import { debounce } from 'lodash';

export const useFAQStore = defineStore('useFAQStore', () => {
  const state = ref<FaqList>([]);

  const toast = useToast();

  const getLastFAQPriority = (): number => {
    return state.value.sort((a: Faq, b: Faq) => a?.priority - b?.priority)[state.value.length - 1]?.priority ?? 0;
  };

  const fetchFaq = async (id: number) => {
    try {
      const faqPayload: ArticleTagsFetchRequest = {
        'filter[itemId]': id,
        'filter[discriminator]': 'faqConverter',
        pagination: false,
      };
      const { items } = await FaqService.fetchFaqs(faqPayload);

      state.value = items;
    } catch (e) {
      toast.errorTemporary({
        id: 'ERROR_LIST_FAQ_LOADED',
        message: 'Requested FAQ list cannot be loaded',
      });
    }
  };

  const createFaq = async (converterId: number) => {
    const newItemPriority = getLastFAQPriority() + 1;
    try {
      const createNewFaqPayload = {
        discriminator: 'faqConverter',
        itemId: converterId,
        priority: newItemPriority,
      };
      const { id } = await FaqService.createFaq(createNewFaqPayload);

      if (id) {
        state.value.push({
          itemType: 'faqConverter',
          itemId: null,
          id,
          request: '',
          response: '',
          isPublished: false,
          priority: newItemPriority,
        });
      }
    } catch (e) {
      console.log(e);
      toast.errorTemporary({
        id: 'ERROR_CREATE_FAQ',
        message: 'FAQ cant be added',
      });
    }
  };

  const deleteFaq = async (faqIds: number[]) => {
    try {
      await FaqService.deleteFaq(faqIds);
      state.value = state.value.filter((faq: Faq) => !faqIds.includes(faq.id));
    } catch (e) {
      toast.errorTemporary({
        id: 'ERROR_DELETE_FAQ',
        message: 'FAQ cant be deleted',
      });
    }
  };

  const deleteFaqItemFromState = (id: number) => {
    state.value = state.value.filter((faq: Faq) => faq.id !== id);
  };

  const updateFaqsPriority = async (data: ConverterFAQPutRequest[]) => {
    try {
      await FaqService.putFaq(data);
    } catch (e) {
      toast.errorTemporary({
        id: 'ERROR_CHANGE_FAQ_PRIORITY',
        message: 'Unable to change FAQ priority',
      });
    }
  };

  const debouncedPatchFAQ = debounce(async (value, field, id) => {
    try {
      const data = await FaqService.patchFaq({
        id,
        data: {
          [field]: value,
        },
      });
    } catch (e) {
      const index = state.value.map((faq) => faq.id).indexOf(id);
      state.value[index].published = false;
      toast.errorTemporary({
        id: 'INVALID_PATCH_FAQ',
        message: e.data.errorMessage,
      });
    }
  }, 500);

  const onFieldUpdate = (val: string | boolean, field: string, id: number) => {
    nextTick(() => {
      debouncedPatchFAQ(val, field, id);
    });
  };

  return {
    state,
    fetchFaq,
    createFaq,
    deleteFaq,
    deleteFaqItemFromState,
    updateFaqsPriority,
    onFieldUpdate,
  };
});
