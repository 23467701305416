<template>
  <Teleport to="#slide-modals">
    <div
      :class="{
        'after:opacity-100 after:pointer-events-auto': props.isOpened && props.isOverlay,
      }"
      class="after:transition-opacity after:overflow-hidden after:h-full after:content-[\' \'] after:block after:fixed after:z-[100] after:top-0 after:left-0 after:right-0 after:bottom-0 after:bg-imperium-bg-overlay after:opacity-0 after:pointer-events-none"
      @click.self="emits('close')"
    >
      <aside
        :class="{
          [modalClass({ size: props.size, isOpened: props.isOpened })]: true,
          [$style['scrollbar-hidden']]: true,
        }"
        :style="{ 'z-index': '110' }"
      >
        <div
          v-if="isTitle"
          class="px-4 pb-3 pt-5 flex relative items-center justify-between border-b border-imperium-border-weak rounded-t-2xl"
        >
          <h3 class="font-semibold text-imperium-fg-strong text-lg">
            <slot name="title" />
          </h3>
        </div>

        <Button
          v-if="isClosable"
          :size="SIZES.XSMALL"
          :visual-type="BUTTON_TYPE.GHOST"
          class="top-4 right-4"
          data-testid="modal-close"
          position="absolute"
          type="button"
          @click="emits('close')"
        >
          <template #leftIcon>
            <CloseIcon class="w-6 h-6 text-gray-400" />
          </template>
        </Button>

        <div class="p-4 h-full">
          <slot />
        </div>
      </aside>
    </div>
  </Teleport>
</template>
<script lang="ts" setup>
import { computed, useSlots } from 'vue';
import { BUTTON_TYPE, SIZES } from '@/types';
import { tv } from 'tailwind-variants';
import Button from '@/components/Button.vue';
import CloseIcon from '@/assets/icons/close.svg?component';

const props = withDefaults(
  defineProps<{
    id: string;
    isOpened: boolean;
    isClosable?: boolean;
    isOverlay?: boolean;
    size?: SIZES;
  }>(),
  {
    isClosable: true,
    isOpened: false,
    isOverlay: true,
    size: SIZES.MEDIUM,
  },
);

const slots = useSlots();

const isTitle = computed<boolean>(() => !!slots.title);

const emits = defineEmits<{
  (event: 'close'): void;
}>();

const modalClass = tv({
  base: 'fixed top-0 right-0 z-40 h-full transition-transform bg-imperium-ds-base-white shadow-lg w-full overflow-auto',
  variants: {
    size: {
      [SIZES.LARGE]: 'sm:w-[500px]',
      [SIZES.MEDIUM]: 'sm:w-[448px]',
      [SIZES.SMALL]: 'sm:w-[300px]',
    },
    isOpened: {
      true: 'translate-x-0',
      false: 'translate-x-full',
    },
  },
});
</script>
<style module lang="scss">
.scrollbar-hidden {
  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.scrollbar-hidden::-webkit-scrollbar {
  display: none;
}
</style>
