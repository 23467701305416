<template>
  <div>
    <h1>Hello Tester!</h1>
    <Button @click="sendSentryError">Send sentry error</Button>
  </div>
</template>
<script setup>
import * as Sentry from '@sentry/vue';
import Button from '@/components/Button.vue';

import { useToast } from '@/composables';

const toast = useToast();
const sendSentryError = () => {
  Sentry.captureException('Test exception');

  toast.errorTemporary({
    id: 'TEST_SENTRY_MESSAGE_ERROR',
    message: 'Test exception was sent',
  });
};
</script>
