import { truncate } from 'lodash';

type Params = {
  value: string;
  maxLength: number | null;
};

const sanitizeAndTruncate = ({ value, maxLength = null }: Params): string => {
  if (maxLength) {
    return truncate(value, { length: maxLength, omission: undefined });
  }

  return value;
};

export default sanitizeAndTruncate;
