import { defineStore } from 'pinia';
import { ref } from 'vue';
import type { Role } from '@/features/Roles/types';

export const useRolesStore = defineStore(
  'useRolesStore',
  () => {
    const state = ref<Role[]>([]);

    const setRoles = (roles: Role[]): void => {
      state.value = roles;
    };

    const clearRoles = (): void => {
      state.value = null;
    };

    return {
      state,
      setRoles,
      clearRoles,
    };
  },
  { persist: true },
);
