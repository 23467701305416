<template>
  <ModalHolder
    data-testid="unpublish-article-modal"
    :is-visible="props.isVisible"
    :size="SIZES.SMALL"
    :type="MODAL_TYPES.POPUP"
    @close="emits('close')"
  >
    <div class="flex flex-col justify-center items-center mb-10">
      <ExclamationShapesIcon class="w-20 h-20 text-imperium-bg-5 mb-4" />

      <div class="text-imperium-fg-base text-base font-normal text-center">
        <template v-if="props.isPublished"> Do you want to update an already published article? </template>

        <template v-else> Do you want to update this article? </template>
      </div>
    </div>

    <div class="flex items-center justify-center">
      <FormCheckbox
        v-model="isUpdatePublicationDate"
        name="cover"
        class="mb-4"
      >
        And update publication date
      </FormCheckbox>
    </div>

    <Toast
      v-if="props.canBeRepublished"
      id="update-modal-republish"
      message="If the document is published, has Republished Article True, and the user updates the date then the views & shares must reset on the website."
      :theme="THEMES.DANGER"
      :is-closable="false"
      is-full-width
    />

    <div class="flex items-center gap-4 justify-center mt-2 pt-5">
      <Button
        :size="SIZES.MEDIUM"
        :visual-type="BUTTON_TYPE.TERTIARY"
        data-testid="button-cancel"
        @click="emits('close')"
      >
        Cancel
      </Button>

      <Button
        :size="SIZES.MEDIUM"
        :visual-type="BUTTON_TYPE.PRIMARY"
        :is-loading="false"
        data-testid="button-cta"
        @click="emits('update', isUpdatePublicationDate)"
      >
        <template v-if="props.isPublished"> Yes, update published article </template>

        <template v-else> Yes, update this article </template>
      </Button>
    </div>
  </ModalHolder>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import ExclamationShapesIcon from '@/assets/icons/exclamation-shapes.svg?component';

import Button from '@/components/Button.vue';
import FormCheckbox from '@/components/FormCheckbox.vue';

import ModalHolder from '@/components/ModalHolder.vue';

import { BUTTON_TYPE, SIZES, MODAL_TYPES, THEMES } from '@/types';
import Toast from '@/components/Toast.vue';

const props = withDefaults(
  defineProps<{
    isVisible?: boolean;
    isPublished: boolean;
    canBeRepublished?: boolean;
  }>(),
  {
    isVisible: false,
  },
);

const emits = defineEmits<{
  (event: 'update', isUpdatePublicationDate: boolean): void;
  (event: 'close'): void;
}>();

const isUpdatePublicationDate = ref<boolean>(false);
</script>
