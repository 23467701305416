<template>
  <section
    class="min-h-full bg-imperium-bg-3"
    :class="{
      'grid grid-rows-[minmax(min-content,_max-content),_minmax(500px,_1fr)] md:grid-rows-[minmax(500px,_1fr)]':
        isMainLayoutVisible,
      '!grid-rows-1 pb-4 md:pb-0': isArtEditorDashboard,
    }"
  >
    <Header
      v-if="isMainLayoutVisible && isAuthenticatedByStore()"
      ref="headerRef"
      :is-sidebar-opened="isSidebarOpened"
      @sidebarToggle="onToggleSidebar"
    />

    <div
      class="grid grid-flow-col auto-cols-[minmax(0,_2fr)]"
      :class="{ 'pt-[57px] md:pl-[234px]': isMainLayoutVisible && !is404page }"
    >
      <Sidebar
        v-if="isMainLayoutVisible && isAuthenticatedByStore()"
        ref="sidebarRef"
        :is-opened="isSidebarOpened"
        @linkClick="onLinkClick"
      />

      <div>
        <BreadcrumbsApp
          v-if="isMainLayoutVisible && !isDesktop && !is404page"
          :key="$route.fullPath"
        />

        <main
          ref="main"
          :style="[isDashboardPage ? { overflow: 'overlay' } : '', { 'min-height': 'calc(100vh - 57px)' }]"
          :class="{ 'flex flex-col': is404page, 'px-4 sm:px-4': isMainLayoutVisible }"
        >
          <RouterView />
        </main>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import { ref, provide, computed } from 'vue';
import { RouterView, useRoute } from 'vue-router';
import { useMediaQuery, onClickOutside, useElementSize } from '@vueuse/core';

import { useLayoutAppearance } from '@/composables/useLayoutAppearance';
import Sidebar from '@/features/Sidebar/ui/Sidebar.vue';
import Header from '@/components/Header.vue';
import { useNotFoundPage } from '@/composables';
import { useDashboardStore } from '@/features/Dashboard/stores/dashboardStore';
import { DashboardTab } from '@/features/Dashboard/types';
import { isAuthenticatedByStore } from '@/services/auth';
import BreadcrumbsApp from '@/features/Breadcrumbs/components/BreadcrumbsApp.vue';

const { isMainLayoutVisible } = useLayoutAppearance();

const isMobile = useMediaQuery('(max-width: 768px)');
const isTablet = useMediaQuery('(max-width: 991px)');
const isDesktop = useMediaQuery('(min-width: 992px)');
const isLargeDesktop = useMediaQuery('(min-width: 1024px)');
const isExtraLargeDesktop = useMediaQuery('(min-width: 1399px)');
const isExtraWideDesktop = useMediaQuery('(min-width: 1600px)');

const is404page = useNotFoundPage().isNotFoundPage;

const isSidebarOpened = ref<boolean>(false);
const sidebarRef = ref<HTMLElement | null>(null);
const headerRef = ref<HTMLElement | null>(null);
const main = ref<HTMLElement | null>(null);

const mainTagSizes = useElementSize(main);
const route = useRoute();

onClickOutside(
  sidebarRef,
  () => {
    if (isDesktop.value || !isSidebarOpened.value) {
      return;
    }

    isSidebarOpened.value = false;
  },
  {
    ignore: [headerRef],
  },
);

const isDashboardPage = computed(() => route.name === 'dashboard');

const dashboardStore = useDashboardStore();

const isArtEditorDashboard = computed<boolean>(() => dashboardStore.state.dashboardType === DashboardTab.COVERS);

const onToggleSidebar = () => (isSidebarOpened.value = !isSidebarOpened.value);
const onLinkClick = () => isTablet.value && onToggleSidebar();

provide('isMobile', isMobile);
provide('isTablet', isTablet);
provide('isDesktop', isDesktop);
provide('isLargeDesktop', isLargeDesktop);
provide('isExtraLargeDesktop', isExtraLargeDesktop);
provide('isExtraWideDesktop', isExtraWideDesktop);
provide('isUserFiltersVisible', false);
provide('isDashboardFull', false);
provide('isArticlesFiltersFull', false);

provide('mainTagHeight', mainTagSizes.height);
</script>
