import * as Sentry from '@sentry/vue';
import { type EditorState } from 'prosemirror-state';
import { MenuItem } from '@/features/CollaborativeEditor/plugins/menu';
import AttachTrendLineUpIcon from '@/assets/icons/attach-trendline-up.svg?raw';
import type { InstallableBlock } from '@/features/CollaborativeEditor/types';
import { disableMenuItem } from '@/features/CollaborativeEditor/utils/state';
import { useAutoLinkCryptos } from '@/features/EditorAddCryptoLinks/composables/auto-link-cryptos';
import { processCryptoSymbols } from '@/features/EditorAddCryptoLinks/prosemirror-commands/auto-crypto-links';
import { type CryptoChangedStatistic } from '@/features/EditorAddCryptoLinks/composables/auto-link-cryptos';

export const buildAutoLinkCryptos = (): InstallableBlock => {
  const item = new MenuItem({
    render: () => {
      const label = 'Auto price links';

      const template = `
<div id="auto-price-links">${AttachTrendLineUpIcon}
  <div>${label}</div>
  <span class="text-sm text-imperium-fg-default font-medium me-2 px-2.5 py-0.5 rounded-full border border-imperium-bg-3">Beta</span>
</div>
`;
      return new DOMParser().parseFromString(template, 'text/html').body.firstElementChild as HTMLElement;
    },
    enable(state: EditorState) {
      // @ts-expect-error Vue: Property path does not exist on type ResolvedPos
      return disableMenuItem(state.selection.$head!.path, state.selection?.node?.type?.name);
    },
    async run(state, dispatch) {
      const { startLoading, stopLoading, showResult, currencies } = useAutoLinkCryptos();
      await startLoading();

      if (!currencies.value) {
        throw new Error('Cant find currency value');
      }
      try {
        const command = processCryptoSymbols(currencies.value);
        const changed: CryptoChangedStatistic = command(state, dispatch);

        await stopLoading();

        if (changed) {
          showResult(changed);
        }
      } catch (e) {
        Sentry.captureException(e);
      } finally {
        await stopLoading();
      }
    },
  });

  return {
    key: 'setCryptoCoinsLinks',
    item,
  };
};
