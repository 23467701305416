import { checkImageUsedAsCover, imageSetAttributes, imageUpload } from '@/features/UploadImage/api';
import type {
  ImageUploadResponse,
  ImageSetAttributesRequest,
  ImageSetAttributesResponse,
  ImageUsedAsCoverCounter,
  ImageUsedAsCoverPayload,
} from '@/features/UploadImage/types';

export const UploadImageService = {
  imageUpload: (payload: { file?: File; url?: string }): Promise<ImageUploadResponse> => {
    const formData = new FormData();
    if (payload.file) {
      formData.append('file', payload.file, payload.file?.name);
    }

    if (payload.url) {
      formData.append('url', payload.url);
    }

    return imageUpload(formData);
  },
  imageSetAttributes: (id: number, payload: ImageSetAttributesRequest): Promise<ImageSetAttributesResponse> =>
    imageSetAttributes(id, payload),
  checkImageUsedAsCover: (imageId: number): Promise<ImageUsedAsCoverCounter> => checkImageUsedAsCover(imageId),
};
